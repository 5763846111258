import { Container, Divider, Modal, Stack, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { NGEDSubstationAsset } from "../data/Substation/interfaces/NGEDSubstationAsset";
import { StaticDataGroups } from "../enums/StaticDataGroups";
import { SubstationStaticDataSelectionChips } from "../Shared/SubstationStaticDataSelectionChips";
import { SubstationCompareGrid } from "./SubstationCompareGrid";

interface SubstationCompareProps {
	substations: NGEDSubstationAsset[];
}
const modalBoxStyle = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	width: "90%",
	maxHeight: "70%",
	padding: "20px",
	overflow: "auto",
};

export function SubstationCompare(props: SubstationCompareProps) {
	const [showComparePopup, setShowComparPopup] = useState<boolean>(true);
	const [staticDataGroup, setStaticGroup] = useState<StaticDataGroups>(
		StaticDataGroups.GeneralDetails
	);

	useEffect(() => {
		setShowComparPopup(true);
		setStaticGroup(StaticDataGroups.GeneralDetails);
	}, [props.substations]);

	return (
		<Modal
			open={showComparePopup}
			onClose={() => {
				setShowComparPopup(false);
			}}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Container sx={modalBoxStyle}>
				<Stack alignItems="flex-start">
					<Paper
						variant="secondary"
						sx={{ height: "100%", width: "100%" }}
					>
						<Stack
							spacing={4}
							margin={2}
							paddingLeft={1}
							alignItems="center"
							divider={<Divider />}
						>
							<SubstationStaticDataSelectionChips
								onChipChange={(group: StaticDataGroups) => {
									setStaticGroup(group);
								}}
								selectedGroup={staticDataGroup}
							></SubstationStaticDataSelectionChips>
						</Stack>
					</Paper>

					<Stack marginTop={1} alignItems="center">
						<SubstationCompareGrid
							selectedGroup={staticDataGroup}
							substations={props.substations}
						/>
					</Stack>
				</Stack>
			</Container>
		</Modal>
	);
}
