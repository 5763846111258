import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { NgedPrimarySubstationDataUrl } from "../../../configuration";
import usePrimarySubstationWithStatistics from "../../data/Substation/hooks/usePrimarySubstationWithStatistics";
import { ExtendedSubstationHierarchy } from "../../data/Substation/interfaces/ExtendedSubstationHierarchy";
import PrimarySubstationDetailCard from "./PrimarySubstationDetailCard";

interface Props {
	substation: ExtendedSubstationHierarchy;
}

export function PrimarySubstationTab(props: Props) {
	const { t } = useTranslation();
	const primarySubstationWithStats = usePrimarySubstationWithStatistics(
		props.substation.primaryNumber
	);

	if (primarySubstationWithStats.data)
		return (
			<Grid container spacing={3} padding={2}>
				<Grid item xs={12} sm={12} md={8}>
					<PrimarySubstationDetailCard
						primarySubstation={primarySubstationWithStats.data}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<Box display="flex" justifyContent="flex-end">
						<Button
							href={NgedPrimarySubstationDataUrl}
							target="_blank"
						>
							{t("common.goToDataSource")}
						</Button>
					</Box>
				</Grid>
			</Grid>
		);
	else return null;
}
