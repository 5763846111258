import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SeriesOptionsType } from "highcharts";
import { DateTime } from "luxon";
import { round } from "lodash";
import { useHighchartsTheme } from "../charts/useHighchartsTheme";
import { convertUtcToLocalDateAndTime } from "../../utils/DateConversions";
import { CategorisedData } from "../../data/Data/interfaces/Data";
import {
	TransformerAsset,
	FeederAsset,
	BusbarAsset,
	GroupedBusbarAsset,
} from "../../data/Substation/interfaces/SubstationHierarchyReport";
import {
	DataPointType,
	DataPointTypeUnits,
	DataPointTypeValueMultiplier,
	LowerVoltageStatutoryLimit,
	TransformerTemperatureStatutoryLimit,
	UpperVoltageStatutoryLimit,
} from "../../enums/DatapointTypes";
import { Phase } from "../../enums/Phase";
import { getPhaseAssetsByTransformerOrFeeder } from "../../utils/getPhaseAssetsByTransformerOrFeeder";
import { ExtendedSubstationHierarchy } from "../../data/Substation/interfaces/ExtendedSubstationHierarchy";
import { getAssetIdsOfSubstation } from "../../utils/getSubstationAssetIds";
import {
	getBusbarAssetPhaseByBusbarId,
	getPhaseByIdFromSubstationHierarchy,
} from "../../utils/getPhaseByIdFromSubstationHierarchy";

interface Params {
	categorisedData: CategorisedData[] | undefined;
	parentAsset:
		| TransformerAsset
		| FeederAsset
		| GroupedBusbarAsset
		| undefined;
	dataPointTypes: DataPointType[];
	busbarAssets?: BusbarAsset[];
	substation: ExtendedSubstationHierarchy;
	compareSubstationHierarchy?: ExtendedSubstationHierarchy;
	showStatutoryLimit: boolean;
}

export interface DataPointTypeSeries {
	dataPointTypeId: number;
	series: Partial<SeriesOptionsType>[];
}

export function useCategorisedDataAsHighchartsSeries(params: Params) {
	const { t } = useTranslation();
	const { getSeriesColour, plotStatutoryLimit } = useHighchartsTheme();
	const {
		categorisedData,
		parentAsset,
		dataPointTypes,
		busbarAssets,
		substation,
		compareSubstationHierarchy,
		showStatutoryLimit,
	} = params;

	const compareSubstationAssetIds = useMemo(
		() => getAssetIdsOfSubstation(compareSubstationHierarchy),
		[compareSubstationHierarchy]
	);

	const highchartsSeriesData = useMemo(() => {
		const getYAxisSettings = (dataPointType: DataPointType) => {
			return {
				unit: DataPointTypeUnits[dataPointType],
				yAxisSettings: {
					title: {
						text: t(`units.${DataPointTypeUnits[dataPointType]}`),
					},

					opposite: false,
					tickPosition: "inside",
					plotLines: plotStatutoryLimits(dataPointType),
					max: showStatutoryLimit
						? getMaxPlotLine(dataPointType)
						: null,
					min: showStatutoryLimit
						? getMinPlotLine(dataPointType)
						: null,
				} as Highcharts.YAxisOptions,
			};
		};

		const plotStatutoryLimits = (dataPointType: DataPointType) => {
			switch (dataPointType) {
				case DataPointType.VoltageMean30minutes: {
					return [
						plotStatutoryLimit(
							LowerVoltageStatutoryLimit,
							t("statutoryLimits.lowerVoltage", {
								value: LowerVoltageStatutoryLimit,
							})
						),
						plotStatutoryLimit(
							UpperVoltageStatutoryLimit,
							t("statutoryLimits.upperVoltage", {
								value: UpperVoltageStatutoryLimit,
							})
						),
					];
				}
				case DataPointType.TransformerTemperature: {
					return [
						plotStatutoryLimit(
							TransformerTemperatureStatutoryLimit,
							t("statutoryLimits.transformerTemperature", {
								value: TransformerTemperatureStatutoryLimit,
							})
						),
					];
				}
				case DataPointType.ActivePower: {
					return substation.substationRating == null
						? null
						: [
								plotStatutoryLimit(
									substation.substationRating,
									t("statutoryLimits.activePower", {
										value: substation.substationRating,
									})
								),
						  ];
				}
			}
		};

		const getMaxPlotLine = (dataPointType: DataPointType) => {
			switch (dataPointType) {
				case DataPointType.VoltageMean30minutes:
					return UpperVoltageStatutoryLimit;

				case DataPointType.TransformerTemperature: {
					return TransformerTemperatureStatutoryLimit;
				}
				case DataPointType.ActivePower: {
					return substation.substationRating == null
						? null
						: substation.substationRating;
				}
			}
			return null;
		};
		const getMinPlotLine = (dataPointType: DataPointType) => {
			switch (dataPointType) {
				case DataPointType.VoltageMean30minutes:
					return LowerVoltageStatutoryLimit;
			}
			return null;
		};
		if (categorisedData) {
			const nonEmptyData = categorisedData.filter(
				(data) => Object.keys(data.series.categorisedData).length > 0
			);

			const phaseAssets =
				getPhaseAssetsByTransformerOrFeeder(parentAsset);

			return dataPointTypes.map((dataPointType) => {
				const dataPointTypeUnit = DataPointTypeUnits[dataPointType];
				const categorisedDataByDataPointType = nonEmptyData.filter(
					(data) =>
						Object.keys(data.series.categorisedData).includes(
							dataPointType.toString()
						)
				);

				const highchartsSeries = categorisedDataByDataPointType
					.map((data) => {
						//Attempt to find phase asset from Transformer / Feeder / Busbar phases. If not found, check if the data is against a busbar asset, and if so, get the phase from there.
						let phaseAsset = phaseAssets?.find(
							(phase) => data.assetId === phase.id
						);

						if (!phaseAsset) {
							const busbarPhases = busbarAssets?.find(
								(busbar) => busbar.id === data.assetId
							)?.phases;
							if (busbarPhases && busbarPhases.length > 0)
								phaseAsset = busbarPhases[0];
						}

						let dataPointTypeLabel = `${
							compareSubstationHierarchy ? substation.name : ""
						} ${t(`dataPointTypes.${dataPointType}`)}`;

						let seriesLineStyle = { dashStyle: "Solid" };

						if (
							compareSubstationHierarchy &&
							compareSubstationAssetIds.includes(data.assetId)
						) {
							seriesLineStyle = { dashStyle: "Dash" };
							phaseAsset = getPhaseByIdFromSubstationHierarchy(
								compareSubstationHierarchy,
								data.assetId
							);
							if (!phaseAsset) {
								phaseAsset = getBusbarAssetPhaseByBusbarId(
									compareSubstationHierarchy,
									data.assetId
								);
							}

							dataPointTypeLabel = `${
								compareSubstationHierarchy.name
							} ${t(`dataPointTypes.${dataPointType}`)}`;
						}

						const phaseLabel = t(
							`phases.L1_L2_L3.${phaseAsset?.phase}`
						);

						const chartTitle = `${t(
							`dataPointTypes.${dataPointType}`
						)}`;

						const seriesData = formatNumericData(
							data,
							dataPointType
						);

						const unitsLabel = t(
							`units.short.${dataPointTypeUnit}`
						);

						return {
							data: seriesData,
							color: getSeriesColour(
								phaseAsset?.phase,
								dataPointType
							),
							name: `${
								phaseAsset ? `${phaseLabel} -` : ""
							} ${dataPointTypeLabel} - [${unitsLabel}]`,
							type: "spline",
							yAxis: 0,
							custom: {
								chartTitle: chartTitle,
								yAxisProperties:
									getYAxisSettings(dataPointType),
							},
							...seriesLineStyle,
						} as Partial<SeriesOptionsType>;
					})
					.filter((dataItem) => dataItem !== undefined);

				return {
					dataPointTypeId: dataPointType,
					series: sortHighchartsSeriesByPhase(highchartsSeries),
				} as DataPointTypeSeries;
			});
		}
	}, [
		categorisedData,
		parentAsset,
		dataPointTypes,
		busbarAssets,
		getSeriesColour,
		plotStatutoryLimit,
		substation.substationRating,
		t,
		showStatutoryLimit,
		compareSubstationAssetIds,
		compareSubstationHierarchy,
		substation.name,
	]);
	return { highchartsSeriesData };
}

function formatNumericData(
	categorisedData: CategorisedData,
	dataPointType: DataPointType
) {
	const dataPointTypeMultiplier =
		DataPointTypeValueMultiplier[dataPointType] || 1;
	const categorisedDataEntry =
		categorisedData.series.categorisedData[dataPointType];
	const dataPointIDs = Object.keys(categorisedDataEntry.values);

	let seriesdata: [number, number][] = [];

	categorisedDataEntry.times.forEach((time, index) => {
		const unixTimestamp = DateTime.fromJSDate(
			convertUtcToLocalDateAndTime(time)
		).toMillis();

		dataPointIDs.forEach((dataPoint) => {
			const dataValue = categorisedDataEntry.values[dataPoint][index];

			if (dataValue != null) {
				seriesdata.push([
					unixTimestamp,
					round(Number(dataValue) * dataPointTypeMultiplier, 2),
				]);
			}
		});
	});

	return seriesdata;
}

function sortHighchartsSeriesByPhase(series: Partial<SeriesOptionsType>[]) {
	const PHASE_SORT_ORDER = [
		Phase.L1.toString(),
		Phase.L2.toString(),
		Phase.L3.toString(),
		Phase.N.toString(),
	];

	return series.sort((a, b) => {
		if (a.name && b.name) {
			if (
				PHASE_SORT_ORDER.includes(a.name) &&
				PHASE_SORT_ORDER.includes(b.name)
			) {
				return (
					PHASE_SORT_ORDER.indexOf(a.name) -
					PHASE_SORT_ORDER.indexOf(b.name)
				);
			}
			return a.name.localeCompare(b.name);
		} else return 0;
	});
}
