import { useContext } from "react";
import { ApiContext } from "../../../contexts/ApiContext";
import { useQuery } from "react-query";
import { QueryCallbacks } from "../../../utils/interfaces/ReactQuery";
import { NGEDSubstationAsset } from "../interfaces/NGEDSubstationAsset";

export default function useLvSubstationsByPrimaryNumber(
	primaryNumber?: number,
	callbacks?: QueryCallbacks<NGEDSubstationAsset[] | undefined | null>
) {
	const apiContext = useContext(ApiContext);

	return useQuery<NGEDSubstationAsset[] | undefined | null, Response>(
		[`substationsByPrimaryNumber-${primaryNumber}`],
		async () => {
			if (primaryNumber) {
				return await apiContext.substationApis.getSubstationsByPrimaryNumber(
					primaryNumber
				);
			} else return undefined;
		},
		{
			onSuccess: callbacks?.onSuccess,
			onError: callbacks?.onError,
			refetchOnWindowFocus: false,
		}
	);
}
