import { PhaseAsset } from "../data/Substation/interfaces/SubstationHierarchyReport";
import { Phase } from "../enums/Phase";

/**
 * Determine whether the given phase asset is a transformer phase.
 * @param asset Phase asset to check
 * @param phase Expected phase type
 */
export const isTransformerPhase = (asset: PhaseAsset, phase: Phase): boolean =>
  asset.assetTypeKey?.localeCompare(`Tx${phase}Phase`) === 0;
