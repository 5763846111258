import Typography, { TypographyProps } from '@mui/material/Typography';

interface ValueTextProps extends TypographyProps {
	text: string | number;
}

export default function ValueText(props: ValueTextProps) {
	const {text, ...rest} = props;
	return <Typography variant="subtitle1" {...rest}>{props.text}</Typography>
}
