import {
	Divider,
	IconButton,
	ListItemIcon,
	ListItemText,
	Tooltip,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
	ViewList as ViewChartListIcon,
	ViewModule as ViewChartGridIcon,
	ShowChart as ShowStatutoryLimitsIcon,
} from "@mui/icons-material";
import { Settings } from "@mui/icons-material";
import { useState } from "react";
import { ChartViewType } from "./Measurements";
import { useTranslation } from "react-i18next";

interface ChartOptionsMenuProps {
	chartView: ChartViewType;
	toggleChartView: (chartView: ChartViewType) => void;
	showStatutoryLimit: boolean;
	setAlwaysShowStatutoryLimit: () => void;
}

export function ChartOptionsMenu(props: ChartOptionsMenuProps) {
	const { t } = useTranslation();
	const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] =
		useState<null | Element>(null);
	const settingsMenuopen = Boolean(settingsMenuAnchorEl);
	const handleSettingClick = (event: React.MouseEvent<Element>) => {
		setSettingsMenuAnchorEl(event.currentTarget);
	};
	const handleSettingsClose = () => {
		setSettingsMenuAnchorEl(null);
	};
	return (
		<>
			<Tooltip title={t("common.chartDisplaySettings.tooltip")}>
				<IconButton
					color="primary"
					aria-label="Open settings menu"
					component="label"
					onClick={handleSettingClick}
				>
					<Settings />
				</IconButton>
			</Tooltip>
			<Menu
				id="basic-menu"
				anchorEl={settingsMenuAnchorEl}
				open={settingsMenuopen}
				onClose={handleSettingsClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				<MenuItem
					selected={props.chartView === ChartViewType.list}
					onClick={() => {
						props.toggleChartView(ChartViewType.list);
					}}
				>
					<ListItemIcon>
						<ViewChartListIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>
						{t("common.chartDisplaySettings.listViewMenuText")}
					</ListItemText>
				</MenuItem>
				<MenuItem
					selected={props.chartView === ChartViewType.grid}
					onClick={() => {
						props.toggleChartView(ChartViewType.grid);
					}}
				>
					<ListItemIcon>
						<ViewChartGridIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>
						{t("common.chartDisplaySettings.gridViewMenuText")}
					</ListItemText>
				</MenuItem>
				<Divider />
				<MenuItem
					onClick={() => {
						handleSettingsClose();
						props.setAlwaysShowStatutoryLimit();
					}}
					selected={props.showStatutoryLimit}
				>
					<ListItemIcon>
						<ShowStatutoryLimitsIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>
						{t(
							"common.chartDisplaySettings.alwaysShowStatutoryLimitsMenuText"
						)}
					</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
}
