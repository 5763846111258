import { useTranslation } from "react-i18next";
import LabelValueRow from "../../Shared/LabelValueFormatters/LabelValueRow";
import Typography from "@mui/material/Typography";
import { ExtendedSubstationHierarchy } from "../../data/Substation/interfaces/ExtendedSubstationHierarchy";
import { Paper, Grid, styled, Tooltip, Box } from "@mui/material";
import EmptyValuePlaceholder from "../../Shared/LabelValueFormatters/EmptyValuePlaceholder";
import energyIcon from "../../../images/Energy.svg";
import evChargerIcon from "../../../images/Ev_Charger.svg";
import windIcon from "../../../images/Wind_Energy.svg";
import totalLctCapacityIcon from "../../../images/Total_LCT_Capacity.svg";
import { concatSubNameAndNumber } from "./SubstationDetailUtils";
import { getAggregateData } from "../../utils/helpers/AggregateDataHelper";
import { SUBSTATION_STATIC_DATA_AGGREGATES } from "../SubstationCompareGridCols";

interface Props {
	substation: ExtendedSubstationHierarchy;
}

export default function SubstationDetailCard(props: Props) {
	const { t } = useTranslation();

	const generatorCount = getAggregateData(
		props.substation,
		SUBSTATION_STATIC_DATA_AGGREGATES.generatorCount.fields
	).toString();

	const lctCount = getAggregateData(
		props.substation,
		SUBSTATION_STATIC_DATA_AGGREGATES.countOfLcts.fields
	).toString();

	const Icon = styled("img")({
		height: "25px",
		width: "25px",
		marginLeft: "2px",
		marginBottom: "-6px",
	});

	return (
		<>
			<Box maxWidth="md">
				<Paper sx={{ p: 2, mb: 2 }}>
					<div>
						<Typography marginBottom={2}>
							<Icon src={energyIcon} />{" "}
							{t("substation.substationDetails")}
						</Typography>
					</div>
					<div>
						<LabelValueRow
							label={t("substation.substation")}
							labelGridSize={{ xs: 12, sm: 9, md: 6 }}
							value={
								concatSubNameAndNumber(
									props.substation.name,
									props.substation.serialNo
								) ?? <EmptyValuePlaceholder />
							}
							message={t("definitions.substation")}
						/>
						<LabelValueRow
							label={t("substation.licenceArea")}
							labelGridSize={{ xs: 12, sm: 9, md: 6 }}
							value={
								<Typography variant="subtitle1">
									{props.substation.dno ? (
										props.substation.dno
									) : (
										<EmptyValuePlaceholder />
									)}
								</Typography>
							}
							message={t("definitions.licenceArea")}
						/>
						<LabelValueRow
							label={t("commonAsset.customerCount")}
							labelGridSize={{ xs: 12, sm: 9, md: 6 }}
							value={
								<Typography>
									{props.substation.customerCount ?? 0}{" "}
								</Typography>
							}
							message={t("definitions.connectedCustomers")}
						/>
						<LabelValueRow
							label={t("substation.primarySubstation")}
							labelGridSize={{ xs: 12, sm: 9, md: 6 }}
							value={
								concatSubNameAndNumber(
									props.substation.primaryName,
									props.substation.primaryNumber?.toString()
								) ?? <EmptyValuePlaceholder />
							}
							message={t("definitions.primarySubstation")}
						/>
						<LabelValueRow
							label={t("substation.bulkSupplyPoint")}
							labelGridSize={{ xs: 12, sm: 9, md: 6 }}
							value={
								concatSubNameAndNumber(
									props.substation.bspName,
									props.substation.bspNumber?.toString()
								) ?? <EmptyValuePlaceholder />
							}
							message={t("definitions.bulkSupplyPoint")}
						/>
						<LabelValueRow
							label={t("substation.gridSupplyPoint")}
							labelGridSize={{ xs: 12, sm: 9, md: 6 }}
							value={
								concatSubNameAndNumber(
									props.substation.gspName,
									props.substation.gspNumber?.toString()
								) ?? <EmptyValuePlaceholder />
							}
							message={t("definitions.gridSupplyPoint")}
						/>
						<LabelValueRow
							label={t("substation.type")}
							labelGridSize={{ xs: 12, sm: 9, md: 6 }}
							value={
								props.substation.description ? (
									props.substation.description
								) : (
									<EmptyValuePlaceholder />
								)
							}
							message={t("definitions.type")}
						/>
					</div>
				</Paper>
			</Box>
			<Box maxWidth="md">
				<Grid container spacing={2}>
					<Grid item container spacing={2}>
						<Grid item xs={12} sm={6} md={6}>
							<Paper sx={{ p: 2, mb: 2 }}>
								<div>
									<Tooltip
										title="tooltip message"
										placement="right-start"
										arrow
										enterTouchDelay={0}
									>
										<Typography marginBottom={2}>
											<Icon src={totalLctCapacityIcon} />{" "}
											{t("substation.networkCapacity")}
										</Typography>
									</Tooltip>
								</div>
								<div>
									<LabelValueRow
										label={t("substation.dayMaxDemand")}
										value={
											<Typography variant="subtitle1">
												{props.substation
													.dayMaxDemand ? (
													`${
														props.substation
															.dayMaxDemand
													} ${t("units.short.kw")}`
												) : (
													<EmptyValuePlaceholder />
												)}
											</Typography>
										}
										message={t("definitions.dayMaxDemand")}
									/>
									<LabelValueRow
										label={t("substation.nightMaxDemand")}
										value={
											<Typography variant="subtitle1">
												{props.substation
													.nightMaxDemand ? (
													`${
														props.substation
															.nightMaxDemand
													} ${t("units.short.kw")}`
												) : (
													<EmptyValuePlaceholder />
												)}
											</Typography>
										}
										message={t(
											"definitions.nightMaxDemand"
										)}
									/>
									<LabelValueRow
										label={t("substation.substationRating")}
										value={
											<Typography>
												{props.substation
													.substationRating ? (
													`${
														props.substation
															.substationRating
													} ${t("units.short.kw")}`
												) : (
													<EmptyValuePlaceholder />
												)}
											</Typography>
										}
										message={t(
											"definitions.substationRating"
										)}
									/>
									<Typography variant="subtitle1">
										&nbsp;
									</Typography>
								</div>
							</Paper>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<Paper sx={{ p: 2, mb: 2 }}>
								<div>
									<Typography marginBottom={2}>
										<Icon src={evChargerIcon} />{" "}
										{t("substation.lowCarbonTech")}
									</Typography>
								</div>
								<div>
									<LabelValueRow
										label={t("substation.countOfLcts")}
										value={lctCount ? lctCount : 0}
										message={t("definitions.lctCount")}
									/>
									<LabelValueRow
										label={t("substation.energyStorage")}
										value={
											<Typography variant="subtitle1">
												{props.substation
													.energyStorageCount ?? 0}
											</Typography>
										}
										message={t("definitions.energyStorage")}
									/>
									<LabelValueRow
										label={t("substation.heatPumps")}
										value={
											<Typography variant="subtitle1">
												{props.substation
													.heatPumpCount ?? 0}
											</Typography>
										}
										message={t("definitions.heatPumps")}
									/>
									<LabelValueRow
										label={t("substation.evChargers")}
										value={
											<Typography variant="subtitle1">
												{props.substation
													.evChargerCount ?? 0}
											</Typography>
										}
										message={t("definitions.evChargers")}
									/>
								</div>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Box>
			<Box maxWidth="md">
				<Paper sx={{ p: 2, mb: 2 }}>
					<div>
						<Typography marginBottom={2}>
							<Icon src={windIcon} /> {t("substation.generation")}
						</Typography>
					</div>
					<div>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={6}>
								<Typography marginBottom={2}>
									{t("substation.counts")}
								</Typography>
								<div>
									<LabelValueRow
										label={t("substation.generatorCount")}
										value={
											generatorCount ? generatorCount : 0
										}
										message={t(
											"definitions.generatorCount"
										)}
									/>
									<LabelValueRow
										label={t("substation.solar")}
										value={
											<Typography>
												{props.substation
													.solarGeneratorCount ?? 0}
											</Typography>
										}
										message={t("definitions.solar")}
									/>
									<LabelValueRow
										label={t("substation.wind")}
										value={
											<Typography>
												{props.substation
													.windGeneratorCount ?? 0}
											</Typography>
										}
										message={t("definitions.wind")}
									/>
									<LabelValueRow
										label={t("substation.bioFuels")}
										value={
											<Typography>
												{props.substation
													.bioFuelGeneratorCount ?? 0}
											</Typography>
										}
										message={t("definitions.bioFuels")}
									/>
									<LabelValueRow
										label={t("substation.storage")}
										value={
											<Typography>
												{props.substation
													.storageGeneratorCount ?? 0}
											</Typography>
										}
										message={t("definitions.storage")}
									/>
									<LabelValueRow
										label={t("substation.hydro")}
										value={
											<Typography>
												{props.substation
													.waterGeneratorCount ?? 0}
											</Typography>
										}
										message={t("definitions.hydro")}
									/>
									<LabelValueRow
										label={t("substation.waste")}
										value={
											props.substation
												.wasteGeneratorCount ?? 0
										}
										message={t("definitions.waste")}
									/>
									<LabelValueRow
										label={t("substation.fossilFuel")}
										value={
											props.substation
												.fossilFuelGeneratorCount ?? 0
										}
										message={t("definitions.fossilFuel")}
									/>
									<LabelValueRow
										label={t("substation.other")}
										value={
											<Typography>
												{props.substation
													.otherGenerationCount ?? 0}
											</Typography>
										}
										message={t("definitions.other")}
									/>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<Tooltip
									title="tooltip message"
									placement="right-start"
									arrow
									enterTouchDelay={0}
								>
									<Typography marginBottom={2}>
										{t("substation.generatorAndStorage")}{" "}
									</Typography>
								</Tooltip>
								<div>
									<LabelValueRow
										label={t("substation.totalLctCapacity")}
										value={
											<Typography>
												{`${
													props.substation
														.totalLCTCapacity ?? 0
												} ${t("units.short.kw")}`}
											</Typography>
										}
										message={t(
											"definitions.totalLctCapacity"
										)}
									/>
									<LabelValueRow
										label={t(
											"substation.totalGenerationCapacity"
										)}
										value={
											<Typography>
												{`${
													props.substation
														.totalGenerationCapacity ??
													0
												} ${t("units.short.kw")}`}
											</Typography>
										}
										message={t(
											"definitions.totalGenerationCapacity"
										)}
									/>
									<LabelValueRow
										label={t(
											"substation.otherGenerationCapacity"
										)}
										value={
											<Typography>
												{`${
													props.substation
														.otherGenerationCapacity ??
													0
												} ${t("units.short.kw")}`}
											</Typography>
										}
										message={t(
											"definitions.otherGenerationCapacity"
										)}
									/>
									<LabelValueRow
										label={t("substation.storageCapacity")}
										value={
											<Typography>
												{`${
													props.substation
														.storageCapacity ?? 0
												} ${t("units.short.kw")}`}
											</Typography>
										}
										message={t(
											"definitions.storageCapacity"
										)}
									/>
								</div>
							</Grid>
						</Grid>
					</div>
				</Paper>
			</Box>
		</>
	);
}
