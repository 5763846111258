import { useContext } from "react";
import { useQuery } from "react-query";
import { ApiContext } from "../../../contexts/ApiContext";
import { QueryCallbacks } from "../../../utils/interfaces/ReactQuery";
import { GeoFence } from "../interfaces/GeoFence";

export function useGeoFenceDataBySubstationNumber(
	substationNumber: number | string | undefined,
	showBoundary: boolean | undefined,
	callBacks?: QueryCallbacks<GeoFence | null | undefined>
) {
	const apiContext = useContext(ApiContext);
	return useQuery(
		[`geoFenceDataBySubstationNumber`, substationNumber, showBoundary],
		async () => {
			if (substationNumber && showBoundary)
				return await apiContext.geoFenceApis.getGeoFenceDataBySubstationNumber(
					substationNumber
				);
			return undefined;
		},
		{
			onSuccess: callBacks?.onSuccess,
			onError: callBacks?.onError,
			refetchOnWindowFocus: false,
		}
	);
}
