import { ThemeOptions } from "@mui/material/styles";
import { PaletteMode, PaletteOptions } from "@mui/material";
import { grey } from "@mui/material/colors";

interface PhaseColors {
	L1: string;
	L2: string;
	L3: string;
	N: string;
}

export interface DataTypePhaseColors {
	voltagePhaseColors: PhaseColors;
	currentPhaseColors: PhaseColors;
	defaultPhaseColors: PhaseColors;
}

/* Todo: mainInstance to be replaced by NGED Brandng in 13652*/
declare module "@mui/material/styles/createPalette" {
	interface CommonColors {
		L1_L2_L3: DataTypePhaseColors;
	}

	interface Palette {
		mainInstance: Palette["primary"];
		bluePalette: Palette["primary"];
		MidBluePalette: Palette["primary"];
		ngedGreen: Palette["primary"];
		redStatus: Palette["primary"];
		amberStatus: Palette["primary"];
		greenStatus: Palette["primary"];
		noneStatus: Palette["primary"];
		L1: Palette["primary"];
		L2: Palette["primary"];
		L3: Palette["primary"];
	}

	interface PaletteOptions {
		mainInstance: PaletteOptions["primary"];
		bluePalette: PaletteOptions["primary"];
		MidBluePalette: PaletteOptions["primary"];
		ngedGreen: PaletteOptions["primary"];
		redStatus: PaletteOptions["primary"];
		amberStatus: PaletteOptions["primary"];
		greenStatus: PaletteOptions["primary"];
		noneStatus: PaletteOptions["primary"];
		L1: PaletteOptions["primary"];
		L2: PaletteOptions["primary"];
		L3: PaletteOptions["primary"];
	}
}

declare module "@mui/material/Paper" {
	interface PaperPropsVariantOverrides {
		secondary: true;
		alternate: true;
	}
}

declare module "@mui/material/Card" {
	interface CardPropsVariantOverrides {
		secondary: true;
		alternate: true;
	}
}

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => {
	const darkPalette: PaletteOptions = {
		common: {
			L1_L2_L3: {
				voltagePhaseColors: {
					L1: "#FF1C3F", //red
					L2: "#FFCC59", //yellow
					L3: "#42CFFF", //blue
					N: "#00A600", //green
				},
				currentPhaseColors: {
					L1: "#209D20", //green
					L2: "#FF8426", //orange
					L3: "#8C54C7", //purple
					N: "#A2A2A2", //grey
				},
				defaultPhaseColors: {
					L1: "#FF1C3F", //red
					L2: "#FFCC59", //yellow
					L3: "#42CFFF", //blue
					N: "#00A600", //green
				},
			},
		},
		background: {
			default: "#0F1114",
			paper: "#181C20",
		},
		text: {
			primary: grey[300],
		},
		primary: {
			main: "#008CBD",
			contrastText: "#fff",
		},
		secondary: {
			main: grey[700],
			contrastText: "#fff",
		},
		success: {
			main: "#126A3E",
		},
		warning: {
			main: "#AD7F17",
		},
		error: {
			main: "#981328",
		},
		redStatus: {
			main: "#981328",
		},
		amberStatus: {
			main: "#D39B1C",
		},
		greenStatus: {
			main: "#126A3E",
		},
		noneStatus: {
			main: grey[600],
		},
		mainInstance: {
			main: "#00148C",
			contrastText: "#fff",
		},
		bluePalette: {
			main: "#0080ff",
			contrastText: "#fff",
		},
		MidBluePalette: {
			main: "#3399ff",
			contrastText: "#fff",
		},
		ngedGreen: {
			main: "#008E87",
		},
		L1: {
			main: "#FF1C3F",
		},
		L2: {
			main: "#FFCC59",
		},
		L3: {
			main: "#42CFFF",
		},
	};

	const lightPalette: PaletteOptions = {
		common: {
			L1_L2_L3: {
				voltagePhaseColors: {
					L1: "#DA1E3A", //red
					L2: "#FFBE2B", //yellow
					L3: "#206EB3", //blue
					N: "#00A600", //green
				},
				currentPhaseColors: {
					L1: "#1B871B", //green
					L2: "#F58A07", //orange
					L3: "#7F4FB3", //purple
					N: "#908E8E", //grey
				},
				defaultPhaseColors: {
					L1: "#DA1E3A", //red
					L2: "#FFBE2B", //yellow
					L3: "#206EB3", //blue
					N: "#00A600", //green
				},
			},
		},
		primary: {
			main: "#00148C",
			contrastText: "#fff",
		},
		secondary: {
			main: grey[300],
			contrastText: "#000",
		},
		success: {
			main: "#1C9D5C",
		},
		warning: {
			main: "#FAB722",
		},
		error: {
			main: "#E51E3D",
		},
		redStatus: {
			main: "#E51E3D",
		},
		amberStatus: {
			main: "#FAB722",
		},
		greenStatus: {
			main: "#1C9D5C",
		},
		noneStatus: {
			main: grey[500],
		},
		mainInstance: {
			main: "#00148C",
			contrastText: "#fff",
		},
		bluePalette: {
			main: "#0080ff",
			contrastText: "#fff",
		},
		MidBluePalette: {
			main: "#3399ff",
			contrastText: "#fff",
		},
		ngedGreen: {
			main: "#008E87",
		},
		L1: {
			main: "#d11532",
		},
		L2: {
			main: "#ffbe2b",
		},
		L3: {
			main: "#34A5CC",
		},
	};

	return {
		breakpoints: {
			values: {
				xs: 300, // phone
				sm: 600, // tablets
				md: 900, // small laptop
				lg: 1200, // desktop
				xl: 1920, // large screens
			},
		},
		palette: {
			mode,
			...(mode === "dark" ? darkPalette : lightPalette),
		},
		typography: {
			fontFamily: [
				"Roboto",
				'"Helvetica Neue"',
				"Arial",
				"sans-serif",
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(","),
			h6: {
				fontWeight: 400,
			},
		},
		zIndex: {
			drawer: 900,
		},
		components: {
			MuiButton: {
				defaultProps: {
					disableElevation: true,
				},
				styleOverrides: {
					root: {
						textTransform: "capitalize",
						fontWeight: 400,
					},
				},
			},
			MuiCard: {
				defaultProps: {
					variant: "outlined",
				},
				variants: [
					{
						props: { variant: "outlined" },
						style: {
							...(mode === "dark" && {
								borderWidth: 0,
							}),
						},
					},
					{
						props: { variant: "secondary" },
						style: {
							...(mode === "light" && {
								backgroundColor: "#fff",
								borderWidth: "1px",
								borderStyle: "solid",
								borderColor: grey[300],
							}),
							...(mode === "dark" && {
								borderWidth: 0,
								backgroundColor: "#23272B",
							}),
						},
					},
					{
						props: { variant: "alternate" },
						style: {
							...(mode === "light" && {
								backgroundColor: "#FAFAFA",
								borderWidth: "1px",
								borderStyle: "solid",
								borderColor: grey[300],
							}),
							...(mode === "dark" && {
								borderWidth: 0,
								backgroundColor: "#23272B",
							}),
						},
					},
				],
			},
			MuiCardHeader: {
				defaultProps: {
					titleTypographyProps: { variant: "h6" },
				},
			},
			MuiCircularProgress: {
				styleOverrides: {
					colorPrimary: {
						...(mode === "light" && {
							color: "#00aae4 !important", //using !important because MUI is trying to override the colour with default "indigo" after navigating away from the page and back again
						}),
						...(mode === "dark" && {
							color: "#008CBD !important",
						}),
					},
				},
			},
			MuiList: {
				styleOverrides: {
					root: {
						paddingTop: "2px",
					},
				},
			},
			MuiPaper: {
				variants: [
					{
						props: { variant: "secondary" },
						style: {
							...(mode === "light" && {
								backgroundColor: grey[100],
							}),
							...(mode === "dark" && {
								backgroundColor: "#121518",
							}),
						},
					},
				],
			},
			MuiMenuItem: {
				styleOverrides: {
					root: {
						background: "#fff",
						"&$selected": {
							backgroundColor: "#E3F2FD",
						},
					},
				},
			},
			MuiDrawer: {
				styleOverrides: {
					paper: {
						"& .MuiListItemText-root, .MuiListItemIcon-root, & .MuiButtonBase-root":
							{
								color: grey[300],
							},
						...(mode === "light" && {
							"& .MuiListItem-root:hover": {
								backgroundColor: "#363636",
							},
							backgroundColor: "#303030",
						}),
						...(mode === "dark" && {
							backgroundColor: "#181C20",
						}),
					},
				},
			},
		},
	};
};
