import { useContext } from "react";
import { useQuery } from "react-query";
import { ApiContext } from "../../../contexts/ApiContext";
import { QueryCallbacks } from "../../../utils/interfaces/ReactQuery";
import { PostcodeLookupResult } from "../PostcodeLookupResult";

export default function usePostcodeLookup(
	postcode?: string,
	callbacks?: QueryCallbacks<PostcodeLookupResult | undefined>
) {
	const apiContext = useContext(ApiContext);

	return useQuery<PostcodeLookupResult | undefined, Response>(
		[`postcodeLookup-${postcode}`],
		async () => {
			if (postcode) {
				return await apiContext.postcodesApis.lookup(postcode);
			} else return undefined;
		},
		{
			onSuccess: callbacks?.onSuccess,
			onError: callbacks?.onError,
		}
	);
}
