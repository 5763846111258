import { PathOptions, StyleFunction } from 'leaflet';
import { GeoJSON } from 'react-leaflet';

export interface GeoFenceProps {
	geoFence?: string;
	geoFenceName?: string;
	style?: PathOptions | StyleFunction | undefined;
}

export function GeoFence(props: GeoFenceProps) {
	const { geoFence, geoFenceName } = props;
	if (geoFence && geoFenceName) {
		const geojson = JSON.parse(geoFence);
		return <GeoJSON key={geoFenceName} data={geojson} style={props.style}/>;
	}

	return <></>;
}