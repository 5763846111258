/*
 * Enum of units available on substation360 data points / measurements. The string represents the translation key for the unit.
 */
export enum Unit {
	Degrees = "degrees",
	Celsius = "degreesCelsius",
	PC = "pc",
	PPC = "ppc",
	Count = "count",
	DBUV = "dbuv",
	DB = "db",
	DBM = "dbm",
	Microseconds = "microseconds",
	MA = "ma",
	MHZ = "mhz",
	KV = "kv",
	KA = "kA",
	Amps = "amps",
	Volts = "volts",
	KW = "kw",
	VAR = "var",
	KVA = "kva",
	Percent = "percent",
	NotApplicable = "",
	kVar = "kVar",
}
