interface CarbonIntensityPeriod {
	from: string;
	to: string;
	intensity: CarbonIntensity;
}

interface CarbonIntensity {
	forecast: number;
	index: string;
}

export interface GenerationMix {
	fuel: string;
	perc: number;
}

export interface CarbonIntensityAndGenerationMixData {
	data: CarbonIntensityAndGenerationMix[];
}

export interface HistoricCarbonIntensityAndGenerationMixData {
	data: CarbonIntensityAndGenerationMix;
}

export interface CarbonIntensityAndGenerationMix {
	regionid: number;
	dnoregion: string;
	shortname: string;
	postcode?: string;
	data: CarbonIntensityAndGenerationMixPeriod[];
}

interface CarbonIntensityAndGenerationMixPeriod extends CarbonIntensityPeriod {
	generationmix: GenerationMix[];
}

export enum CarbonIntensityAPIRegionId {
	"SouthWales" = 7,
	"WestMidlands" = 8,
	"EastMidlands" = 9,
	"SouthWestEngland" = 11,
}
