import { Typography } from '@mui/material';

interface EmptyValuePlaceholderProps {
	placeholderText?: string;
}

const DEFAULT_PLACEHOLDER = "--";

export default function EmptyValuePlaceholder(props: EmptyValuePlaceholderProps) {
	return <Typography variant="caption" color="textSecondary">{props.placeholderText || DEFAULT_PLACEHOLDER}</Typography>
}