import { NonPrivateRoutes } from "../../routes";

export function onCallToActionPage(path: string){
    return path.toLowerCase() === "/";
}

export function onPageRequiringAuthentication(path: string){
    for(let nonPrivateRoute of NonPrivateRoutes){
        if(path.toLowerCase().includes(nonPrivateRoute)){
            return false;
        }
        if(path === "/"){
            return false;
        }
    }
    return true;
}