import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FormGroup, FormHelperText, Box,
	AlertColor, Grid, Typography, Checkbox, 
    Tooltip} from '@mui/material';
import config from '../../../configuration';
import MessageToast from '../Messages/MessageToast';
import ProgressButton from '../Inputs/ProgressButton';
import { UserConfirmationRequest } from './UserConfirmationRequest';
import { LoginStyledTextInput } from '../Login/LoginStyledTextInput';
import useAccountConfirmation from '../../data/Login/hooks/useAccountConfirmation';
import { passwordDoesNotContainDigits, passwordDoesNotContainLowercase, passwordDoesNotContainSymbols, passwordDoesNotContainUppercase } from '../../utils/helpers/PasswordStrengthHelper';

export default function EmailConfirmation() {
	const { t } = useTranslation();
    const [password, setPassword] = useState("");
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const [tokenVerified, setTokenVerified] = useState<boolean | undefined>(undefined);
    const [agreedToTermsAndConditions, setAgreedToTermsAndConditions] = useState<boolean>(false);
	const [messageText, setMessageText] = useState("");
	const [messageSeverity, setMessageSeverity] = useState<AlertColor | undefined>();
    const {email, token} = useParams();
    const navigate = useNavigate();

    const navigateToLogin = () => {
        navigate('/');
    };

    const { mutate: confirmAccount, 
        isError: errorConfirmingAccount, 
        isSuccess: confirmedAccount,
        isLoading: loadingAccountConfirmation } = useAccountConfirmation();
    
    useEffect(() => {
        if(email && token){
            let confirmTokenEndpointAddress = `${config.apiGateway.NGED_AUTH_API}/api/Account/verifyEmailConfirmationToken?email=${encodeURIComponent(email)}&token=${encodeURIComponent(token)}`;
            fetch(confirmTokenEndpointAddress,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                .then((resp) => {
                    setTokenVerified(resp.status === 200);
                    if(resp.status === 400){
                        setMessageText("Error confirming token, please try signing up again");
                        setMessageSeverity('error');
                    }
                })
                .catch(_ => {
                    setTokenVerified(false);
                    setMessageText("Error confirming token");
                    setMessageSeverity('error');
                });
        }
      }, []);

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
		resetMessage();
	}

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setConfirmedPassword(event.target.value);
		resetMessage();
	}

    const handleAgreeToTermsAndConditions = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
		setAgreedToTermsAndConditions(checked);
		resetMessage();
	}

    const resetMessage = () => {
        setMessageText("");
		setMessageSeverity(undefined);
    }
 
	function handleSubmit(e: React.SyntheticEvent) {
        if (password === "") {
            setMessageText(t('login.pleaseEnterPassword'));
            setMessageSeverity("error");
            return;
		}
        if (password !== confirmedPassword) {
            setMessageText(t('login.passwordConfirmationError'));
            setMessageSeverity("error");
            return;
		}

        if (password.length < 10) {
            setMessageText(t('login.passwordLength'));
            setMessageSeverity("error");
            return;
        }
		
        if (passwordDoesNotContainUppercase(password)) {
            setMessageText(t('login.passwordUppercase'));
            setMessageSeverity("error");
            return;
        }
		
        if (passwordDoesNotContainLowercase(password)) {
            setMessageText(t('login.passwordLowercase'));
            setMessageSeverity("error");
            return;
        }

        if (passwordDoesNotContainDigits(password)) {
            setMessageText(t('login.passwordNumeric'));
            setMessageSeverity("error");
            return;
        }

        if (passwordDoesNotContainSymbols(password)) {
            setMessageText(t('login.passwordNonAlphanumeric'));
            setMessageSeverity("error");
            return;
        }

        if (!agreedToTermsAndConditions) {
            setMessageText(t('login.pleaseAgreeToTheTermsAndConditions'));
            setMessageSeverity("error");
            return;
		}

        const userConfirmation: UserConfirmationRequest = {
            email: email ?? "",
            password: password,
            emailValidationToken: token ?? ""
        }

        e.preventDefault();
        confirmAccount(userConfirmation);
	}

    useEffect(() => {
      if(errorConfirmingAccount){
        setMessageText(t('login.errorRequestingAccess'));
        setMessageSeverity("error");
      }
    }, [errorConfirmingAccount]);

    useEffect(() => {
        if(confirmedAccount){
            setPassword("");
            setConfirmedPassword("");
            setMessageText(t('login.accountConfirmed'));
            setMessageSeverity("success");
        }
    }, [confirmedAccount]);

    return (
		<Box margin={5}>
            {!confirmedAccount && tokenVerified === true && <FormGroup>
                <Grid container spacing={2} justifyContent="center">
                    <Box>
                            <FormHelperText sx={{ mt: 2 }}>
                                {t('login.createAPassword')}
                                </FormHelperText>
                                <Tooltip
                                    title={t('login.passwordStrength')}
                                    placement="right-start"
                                    arrow
                                    enterTouchDelay={0}
                                >
                                    <LoginStyledTextInput
                                    name="password"
                                    required={true}
                                    value={password}
                                    onChange={handlePasswordChange}
                                    type="password"
                                    placeholder={t('login.password')}
                                />
                                </Tooltip>   
                            <FormHelperText sx={{ mt: 2 }}>
                                {t('login.pleaseConfirmPassword')}
                            </FormHelperText>
                            <LoginStyledTextInput
                                    name="confirmPassword"
                                    required={true}
                                    value={confirmedPassword}
                                    onChange={handleConfirmPasswordChange}
                                    type="password"
                                    placeholder={t('login.confirmPassword')}
                                />
                    </Box>
                </Grid>
                <Box display="flex" justifyContent="center" pt={2}>
                    <Typography
                        color="textSecondary"
                        variant="subtitle2"
                        pt={2}
                        justifyContent="center"
                    >
                        {t('login.privacyPolicyStatement')}
                        <a href="https://www.nationalgrid.co.uk/privacy-policy" target="_blank" rel="noopener noreferrer">
                            {t('login.privacyPolicy')}.
                        </a>
                    </Typography>
                </Box>

                <Box display="flex" justifyContent="center" pt={2} pb={2}>
                    <Box>
                        <FormHelperText sx={{ mt: 2 }}>
                            {t('login.agreeToTermsAndConditions')}
                        </FormHelperText>
                    </Box>
                    <Box>
                        <Checkbox onChange={handleAgreeToTermsAndConditions} sx={{paddingTop:1.5}}/>
                    </Box>
                </Box>
            
                <Box display="flex" justifyContent="center" >
                    <ProgressButton onClick={handleSubmit} loading={loadingAccountConfirmation} >
                        {t('login.submit')}
                    </ProgressButton>
                </Box>
            </FormGroup>}
            {confirmedAccount && 
                <Box display="flex" justifyContent="center" pt={2}>
                    <ProgressButton onClick={navigateToLogin} loading={false}>
                        {t('login.login')}
                    </ProgressButton>
                </Box>}
            <Box display="flex" justifyContent="center" pt={2}>
                {messageText && messageSeverity && <MessageToast text={messageText} severity={messageSeverity} />}
            </Box>
		</Box>
	);
}