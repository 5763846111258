import { Unit } from "./Units";

export enum DataPointType {
	CurrentMean30minutes = 100047,
	VoltageMean30minutes = 100049,
	TransformerTemperature = 100037,
	VoltageTHD = 100059,
	ActivePower = 100110,
	ReactivePower = 100111,
}

export const UpperVoltageStatutoryLimit = 253.0;
export const LowerVoltageStatutoryLimit = 216.2;
export const TransformerTemperatureStatutoryLimit = 105;

export const DataPointTypeUnits: Record<DataPointType, Unit> = {
	[DataPointType.CurrentMean30minutes]: Unit.Amps,
	[DataPointType.VoltageMean30minutes]: Unit.Volts,
	[DataPointType.VoltageTHD]: Unit.Percent,
	[DataPointType.ActivePower]: Unit.KW,
	[DataPointType.ReactivePower]: Unit.kVar,
	[DataPointType.TransformerTemperature]: Unit.Celsius,
};

export const DataPointTypeValueMultiplier: Partial<
	Record<DataPointType, number>
> = {
	[DataPointType.ActivePower]: 1,
};
