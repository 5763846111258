import { point, DivIcon } from 'leaflet';

export function getSubstationLeafletIcon(color: string): L.DivIcon {
	const marker = `<svg
			width="50px"
			height="50px"
			viewBox="0 0 210 297"
			version="1.1"
			id="svg40185"
		>
			<g>
				<path
					style="fill: #fff; fill-opacity: 1; stroke: #000; stroke-width: 1"
					d="m 104.84773,4.44528 c 24.28903,-0.094341 48.61213,7.968018 68.20433,24.212968 39.1844,32.489887 47.47434,87.737522 19.44673,129.544612 l 0.20233,0.14458 -0.20519,-0.14065 -89.31847,139.01875 -84.681789,-138.3352 -0.188027,0.12294 0.184207,-0.13384 C -9.8864812,117.29115 -2.0602711,61.983402 36.849563,29.190324 56.304486,12.79378 80.558691,4.5396161 104.84773,4.44528 Z"
				/>
				<ellipse
					style="fill: ${color}; fill-opacity: 1; stroke: #fff; stroke-width: 0"
					id="path44739"
					cx="105.20599"
					cy="105.94608"
					rx="83.837296"
					ry="81.846474" />
				<path
					style="fill: #fff; fill-opacity: 1; stroke: #fff; stroke-width: 0"
					d="m 76.766974,162.52716 c 5.331979,-13.79124 18.215242,-48.32923 18.215242,-48.83222 0,-0.39866 -6.936925,-4.65854 -16.494525,-10.12902 L 61.993159,94.124956 93.101927,67.885445 C 110.21176,53.453744 124.29806,41.732656 124.40483,41.838621 c 0.1068,0.10592 -3.43588,10.181042 -7.87259,22.389087 -4.4367,12.208062 -7.99453,22.255856 -7.90629,22.328473 0.0882,0.0726 8.4032,4.841273 18.47771,10.597067 10.07449,5.755812 18.13904,10.626882 17.92118,10.824672 -0.44177,0.40105 -66.834458,54.12903 -67.933405,54.97483 -0.570711,0.4392 -0.629172,0.36257 -0.324461,-0.42559 z"
				/>
			</g>
		</svg>`;

	return new DivIcon({
		html: marker,
		className: 'svg-marker',
		iconSize: point(50, 50),
		iconAnchor: point(25, 50),
	});
}