import React, { useState } from 'react';
import { PaletteMode } from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { getDesignTokens } from '../../themes/MuiTheme';

const THEME_STORAGE_KEY = "THEME";

export interface MainThemeProps {
	getPaletteMode: () => PaletteMode;
	handlePaletteModeChange: (mode: PaletteMode) => void;
}

interface Props {
	children?: React.ReactNode;
}

//This function sets the primereact theme
const changeTheme = (mode: PaletteMode) => {
	document.documentElement.className = '';
	document.documentElement.classList.add(`theme-${mode}`);
}

export const MainTheme = React.createContext({} as MainThemeProps);

export function MainThemeProvider(props: Props) {
	const [paletteMode, setPaletteMode] = useState(localStorage.getItem(THEME_STORAGE_KEY) as PaletteMode || "light");

	//This function sets the MUI theme
	const selectedTheme = createTheme(getDesignTokens(paletteMode));

	changeTheme(paletteMode);

	function handlePaletteModeChange(mode: PaletteMode) {
		localStorage.setItem(THEME_STORAGE_KEY, mode);
		setPaletteMode(mode);
	}

	function getPaletteMode() {
		return paletteMode;
	}
	
	return (
		<MainTheme.Provider
			value={{
				handlePaletteModeChange: handlePaletteModeChange,
				getPaletteMode: getPaletteMode
			}}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={selectedTheme}>
					{props.children}
				</ThemeProvider>
			</StyledEngineProvider>
		</MainTheme.Provider>
	);
}