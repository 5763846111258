/**
 * Filter a collection by searching for a term against one or more properties.
 * Note that comparisons are case-insensitive.
 * @param data - Data to search
 * @param selector - Callback to select properties to search against
 * @param searchTerm - Search term to compare against properties.
 */
 export function searchByProperties<T>(data: T[], selector: (item: T) => string[], searchTerm: string) {
    const lowercaseSearchTerm = searchTerm.toLowerCase();
    return data.filter(item => {
      const properties = selector(item).map(p => p.toLowerCase());
      return properties.some(property => property.includes(lowercaseSearchTerm));
    });
  }
  