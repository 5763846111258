import { Button } from "@mui/material";
import { t } from "i18next";
import { useRef, useMemo } from "react";
import { CSVLink } from "react-csv";
import {
	NGEDSubstationAsset,
	NGEDSubstationForExport,
} from "../data/Substation/interfaces/NGEDSubstationAsset";

interface ExportMonitoredSubstationsProps {
	substationData: NGEDSubstationAsset[];
}

export function ExportMonitoredSubstations(
	props: ExportMonitoredSubstationsProps
) {
	const csvLinkRef = useRef<
		CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
	>(null);

	const handleExport = () => {
		csvLinkRef?.current?.link.click();
	};

	const trimmedSubstationData: NGEDSubstationForExport[] = useMemo(() => {
		return props.substationData.map((substation: NGEDSubstationAsset) => {
			return {
				number: substation.serialNumber,
				name: substation.name,
				licenceArea: substation.dno,
				bspName: substation.bspName,
				bspNumber: substation.bspNumber,
				gspName: substation.gspName,
				gspNumber: substation.gspNumber,
				primaryName: substation.primaryName,
				primaryNumber: substation.primaryNumber,
				gridReference: substation.gridReference,
			};
		});
	}, [props.substationData]);

	return (
		<>
			<CSVLink
				data={trimmedSubstationData}
				filename="Monitored-Substations.csv"
				className="hidden"
				ref={csvLinkRef}
				style={{ justifyContent: "flex-end" }}
			/>
			{trimmedSubstationData.length > 0 && (
				<Button onClick={handleExport}>
					{t("substation.exportMonitoredSubstations")}
				</Button>
			)}
		</>
	);
}
