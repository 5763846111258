import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box, Grid, Paper, Tooltip } from "@mui/material";
import LabelValueRow from "../../Shared/LabelValueFormatters/LabelValueRow";
import { PrimarySubstationWithStatistics } from "../../data/Substation/interfaces/PrimarySubstationWithStatistics";
import { aggregateCounts } from "../../utils/CounterUtils";
import energyIcon from "../../../images/Energy.svg";
import evChargerIcon from "../../../images/Ev_Charger.svg";
import windIcon from "../../../images/Wind_Energy.svg";
import { Icon } from "../../Shared/Icon";
import EmptyValuePlaceholder from "../../Shared/LabelValueFormatters/EmptyValuePlaceholder";
import { concatSubNameAndNumber } from "./SubstationDetailUtils";

interface Props {
	primarySubstation: PrimarySubstationWithStatistics;
}

export default function PrimarySubstationDetailCard(props: Props) {
	const { t } = useTranslation();

	const generatorCounts = [
		props.primarySubstation.substationStatistics.totalSolarGeneratorCount,
		props.primarySubstation.substationStatistics.totalBioFuelGeneratorCount,
		props.primarySubstation.substationStatistics.totalWindGeneratorCount,
		props.primarySubstation.substationStatistics.totalStorageGeneratorCount,
		props.primarySubstation.substationStatistics.totalWasteGeneratorCount,
		props.primarySubstation.substationStatistics.totalWaterGeneratorCount,
		props.primarySubstation.substationStatistics
			.totalFossilFuelGeneratorCount,
		props.primarySubstation.substationStatistics.totalOtherGenerationCount,
	];

	return (
		<div>
			<Box maxWidth={"md"} marginBottom={2}>
				<Paper sx={{ p: 2, mb: 2 }}>
					<div>
						<Typography marginBottom={2}>
							<Icon src={energyIcon} />{" "}
							{t("substation.primarySubstationDetails")}
						</Typography>
					</div>
					<div>
						<LabelValueRow
							label={t("substation.primarySubstation")}
							labelGridSize={{ xs: 12, sm: 6 }}
							value={
								concatSubNameAndNumber(
									props.primarySubstation.name,
									props.primarySubstation.serialNumber
								) ?? <EmptyValuePlaceholder />
							}
							message={t("definitions.primarySubstation")}
						/>
						<LabelValueRow
							label={t("commonAsset.customerCount")}
							labelGridSize={{ xs: 12, sm: 6 }}
							value={
								props.primarySubstation.substationStatistics
									.totalCustomerCount ?? 0
							}
							message={t("definitions.primaryCustomers")}
						/>
					</div>
				</Paper>
			</Box>
			<Box maxWidth={"md"} marginBottom={2}>
				<Paper sx={{ p: 2, mb: 2 }}>
					<div>
						<Tooltip
							title="tooltip message"
							placement="right-start"
							arrow
							enterTouchDelay={0}
						>
							<Typography marginBottom={2}>
								<Icon src={evChargerIcon} />{" "}
								{t("substation.lowCarbonTech")}
							</Typography>
						</Tooltip>
					</div>
					<div>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<LabelValueRow
									label={t("substation.countOfLcts")}
									value={
										props.primarySubstation
											.substationStatistics
											.totalLCTCount ?? 0
									}
									message={t("substation.primaryCountOfLcts")}
								/>
								<LabelValueRow
									label={t("substation.energyStorage")}
									value={
										<Typography variant="subtitle1">
											{props.primarySubstation
												.substationStatistics
												.totalEnergyStorageCount ?? 0}
										</Typography>
									}
									message={t(
										"definitions.primaryEnergyStorage"
									)}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<LabelValueRow
									label={t("substation.heatPumps")}
									value={
										<Typography variant="subtitle1">
											{props.primarySubstation
												.substationStatistics
												.totalHeatPumpCount ?? 0}
										</Typography>
									}
									message={t("definitions.primaryHeatPumps")}
								/>
								<LabelValueRow
									label={t("substation.evChargers")}
									value={
										<Typography variant="subtitle1">
											{props.primarySubstation
												.substationStatistics
												.totalEvChargerCount ?? 0}{" "}
										</Typography>
									}
									message={t("definitions.primaryEvChargers")}
								/>
							</Grid>
						</Grid>
					</div>
				</Paper>
			</Box>
			<Box maxWidth={"md"} marginBottom={2}>
				<Paper sx={{ p: 2, mb: 2 }}>
					<div>
						<Typography marginBottom={2}>
							<Icon src={windIcon} /> {t("substation.generation")}
						</Typography>
					</div>
					<div>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={6}>
								<Typography marginBottom={2}>
									{t("substation.counts")}
								</Typography>
								<LabelValueRow
									label={t("substation.generatorCount")}
									value={
										aggregateCounts(generatorCounts)
											? aggregateCounts(
													generatorCounts
											  ).toString()
											: 0
									}
									message={t(
										"substation.primaryGeneratorCount"
									)}
								/>
								<LabelValueRow
									label={t("substation.solar")}
									value={
										<Typography variant="subtitle1">
											{props.primarySubstation
												.substationStatistics
												.totalSolarGeneratorCount ?? 0}
										</Typography>
									}
									message={t("definitions.primarySolar")}
								/>
								<LabelValueRow
									label={t("substation.wind")}
									value={
										<Typography variant="subtitle1">
											{props.primarySubstation
												.substationStatistics
												.totalWindGeneratorCount ?? 0}
										</Typography>
									}
									message={t("definitions.primaryWind")}
								/>
								<LabelValueRow
									label={t("substation.bioFuels")}
									value={
										<Typography variant="subtitle1">
											{props.primarySubstation
												.substationStatistics
												.totalBioFuelGeneratorCount ??
												0}
										</Typography>
									}
									message={t("definitions.primaryBioFuels")}
								/>
								<LabelValueRow
									label={t("substation.storage")}
									value={
										<Typography variant="subtitle1">
											{props.primarySubstation
												.substationStatistics
												.totalStorageGeneratorCount ??
												0}
										</Typography>
									}
									message={t("definitions.primaryStorage")}
								/>
								<LabelValueRow
									label={t("substation.hydro")}
									value={
										<Typography variant="subtitle1">
											{props.primarySubstation
												.substationStatistics
												.totalWaterGeneratorCount ?? 0}
										</Typography>
									}
									message={t("definitions.primaryHydro")}
								/>
								<LabelValueRow
									label={t("substation.waste")}
									value={
										<Typography variant="subtitle1">
											{props.primarySubstation
												.substationStatistics
												.totalWasteGeneratorCount ??
												0}{" "}
										</Typography>
									}
									message={t("definitions.primaryWaste")}
								/>
								<LabelValueRow
									label={t("substation.fossilFuel")}
									value={
										<Typography variant="subtitle1">
											{props.primarySubstation
												.substationStatistics
												.totalFossilFuelGeneratorCount ??
												0}{" "}
										</Typography>
									}
									message={t("definitions.primaryFossilFuel")}
								/>
								<LabelValueRow
									label={t("substation.other")}
									value={
										<Typography variant="subtitle1">
											{props.primarySubstation
												.substationStatistics
												.totalOtherGenerationCount ?? 0}
										</Typography>
									}
									message={t(
										"definitions.primaryOtherGeneration"
									)}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={6}>
								<Tooltip
									title="tooltip message"
									placement="right-start"
									arrow
									enterTouchDelay={0}
								>
									<Typography marginBottom={2}>
										{t("substation.generator")}{" "}
									</Typography>
								</Tooltip>
								<LabelValueRow
									label={t("substation.totalLctCapacity")}
									value={
										<Typography variant="subtitle1">
											{`${
												props.primarySubstation
													.substationStatistics
													.totalLCTCapacity ?? 0
											} ${t("units.short.kw")}`}
										</Typography>
									}
									message={t(
										"definitions.totalPrimaryLctCapacity"
									)}
								/>
								<LabelValueRow
									label={t(
										"substation.totalGenerationCapacity"
									)}
									value={
										<Typography variant="subtitle1">
											{`${
												props.primarySubstation
													.substationStatistics
													.totalGenerationCapacity ??
												0
											} ${t("units.short.kw")}`}
										</Typography>
									}
									message={t(
										"definitions.totalPrimaryGenerationCapacity"
									)}
								/>
							</Grid>
						</Grid>
					</div>
				</Paper>
			</Box>
		</div>
	);
}
