import { useMemo, useCallback, useEffect } from "react";
import { useImmer } from "use-immer";
import { AssetTypes } from "../../enums/AssetTypes";
import { DataPointType } from "../../enums/DatapointTypes";
import { InstrumentAssetModel } from "../../enums/Instrument";
import { notNullish } from "../../utils/notNullish";

/**
 * Record to store which data point types are only available for certain instrument types.
 * If a data point isn't included, it's assumed that the data point type is available for all instruments.
 */
export const DataPointTypeInstruments: Partial<
	Record<DataPointType, InstrumentAssetModel[]>
> = {
	[DataPointType.CurrentMean30minutes]: [
		InstrumentAssetModel.VisNetHub,
		InstrumentAssetModel.GridKey,
	],
	[DataPointType.VoltageMean30minutes]: [
		InstrumentAssetModel.VisNetHub,
		InstrumentAssetModel.GridKey,
	],
};

/**
 * Record to store which data point types are only available for certain asset types.
 * If a data point isn't included, it's assumed that the data point type is available for all asset types.
 */
export const DataPointTypeAssetTypes: Partial<
	Record<DataPointType, AssetTypes[]>
> = {
	[DataPointType.VoltageMean30minutes]: [
		AssetTypes.Busbar,
		AssetTypes.Transformer,
	],
	[DataPointType.TransformerTemperature]: [
		AssetTypes.Transformer,
		AssetTypes.Transformer,
	],
	[DataPointType.ActivePower]: [
		AssetTypes.LvWay,
		AssetTypes.Busbar,
		AssetTypes.Transformer,
	],
	[DataPointType.ReactivePower]: [
		AssetTypes.LvWay,
		AssetTypes.Busbar,
		AssetTypes.Transformer,
	],
	[DataPointType.VoltageTHD]: [AssetTypes.Busbar, AssetTypes.Transformer],
	[DataPointType.CurrentMean30minutes]: [
		AssetTypes.LvWay,
		AssetTypes.Busbar,
		AssetTypes.Transformer,
	],
};

interface DataPointTypeFilter {
	dataPointType: DataPointType;
	available: boolean;
	enabled: boolean;
	selected: boolean;
}

interface Params {
	dataPointTypes: DataPointType[];
	assetType: AssetTypes | undefined;
	defaultSelectedValues: DataPointType[] | undefined;
}

export default function useDataPointTypeFilters(params: Params) {
	const { dataPointTypes, assetType, defaultSelectedValues } = params;

	const [dataPointTypeFilters, setDataPointTypeFilters] = useImmer<
		DataPointTypeFilter[]
	>(
		assetType
			? buildDefaultDataPointTypeFilters(
					dataPointTypes,
					assetType,
					defaultSelectedValues
			  )
			: []
	);
	useEffect(() => {
		setDataPointTypeFilters((prevState) => {
			return dataPointTypes
				.map((dataPointType) => {
					const isSelected =
						prevState.find(
							(draftFilter) =>
								draftFilter.dataPointType === dataPointType
						)?.selected || false;
					return (
						assetType &&
						buildDataPointTypeFilter(
							dataPointType,
							assetType,
							isSelected
						)
					);
				})
				.filter(notNullish);
		});
	}, [dataPointTypes, assetType, setDataPointTypeFilters]);

	const handleDataPointTypeSelectionChange = useCallback(
		(dataPointType: DataPointType) => {
			setDataPointTypeFilters((writableDraftState) => {
				const dataPointTypeFilter = writableDraftState.find(
					(draftFilter) => draftFilter.dataPointType === dataPointType
				);
				if (dataPointTypeFilter)
					dataPointTypeFilter.selected =
						!dataPointTypeFilter.selected;
			});
		},
		[setDataPointTypeFilters]
	);

	const selectedDataPointTypes = useMemo(() => {
		return dataPointTypeFilters
			.filter((dataPointTypeFilter) => dataPointTypeFilter.selected)
			.map((dataPointTypeFilter) => dataPointTypeFilter.dataPointType);
	}, [dataPointTypeFilters]);

	return [
		dataPointTypeFilters,
		handleDataPointTypeSelectionChange,
		selectedDataPointTypes,
	] as const;
}

function buildDataPointTypeFilter(
	dataPointType: DataPointType,
	assetType: AssetTypes | undefined,
	selected?: boolean
): DataPointTypeFilter | null {
	const dataPointTypeAvailable =
		checkDataPointTypeAvailableByInstrumentTypes(dataPointType);
	const dataPointTypeEnabled = checkDataPointTypeAvailableByAssetType(
		dataPointType,
		assetType
	);

	return {
		dataPointType: dataPointType,
		available: dataPointTypeAvailable,
		enabled: dataPointTypeEnabled,
		selected: selected && dataPointTypeAvailable && dataPointTypeEnabled,
	} as DataPointTypeFilter;
}

function buildDefaultDataPointTypeFilters(
	dataPointTypes: DataPointType[],
	assetType: AssetTypes,
	defaultSelectedValues: DataPointType[] | undefined
): DataPointTypeFilter[] {
	return dataPointTypes
		.map((dataPointType) => {
			//If no default selected data point types have been provided, default to all selected
			const isSelected =
				defaultSelectedValues && defaultSelectedValues.length > 0
					? defaultSelectedValues?.includes(dataPointType)
					: true;

			return buildDataPointTypeFilter(
				dataPointType,
				assetType,
				isSelected
			);
		})
		.filter(notNullish);
}

function checkDataPointTypeAvailableByInstrumentTypes(
	dataPointType: DataPointType
) {
	const dataPointTypeInstruments = DataPointTypeInstruments[dataPointType];

	if (dataPointTypeInstruments) {
		return dataPointTypeInstruments;
	} else return true;
}

function checkDataPointTypeAvailableByAssetType(
	dataPointType: DataPointType,
	assetType: AssetTypes | undefined
) {
	const dataPointTypeAssetTypes = DataPointTypeAssetTypes[dataPointType];
	if (dataPointTypeAssetTypes) {
		return dataPointTypeAssetTypes.some(
			(instrument) => assetType === instrument
		);
	} else return true;
}
