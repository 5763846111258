import { FeederAsset, GroupedBusbarAsset, TransformerAsset } from "../data/Substation/interfaces/SubstationHierarchyReport";
import { AssetTypes } from "../enums/AssetTypes";
import { selectTransformerPhases } from "./SelectTransformerPhases";

export function getPhaseAssetsByTransformerOrFeeder(parentAsset: TransformerAsset | FeederAsset | GroupedBusbarAsset | undefined) {

	if (parentAsset?.assetTypeKey === AssetTypes.Transformer)
		return selectTransformerPhases(parentAsset as TransformerAsset);

	else if (parentAsset?.assetTypeKey === AssetTypes.LvWay && "phases" in parentAsset)
		return parentAsset.phases;

	else return [];
}