import { useContext } from "react";
import { useQuery } from "react-query";
import { ApiContext } from "../../../contexts/ApiContext";
import { QueryCallbacks } from "../../../utils/interfaces/ReactQuery";
import { NGEDSubstationAsset } from "../interfaces/NGEDSubstationAsset";

export function useSubstationList(
	callBacks?: QueryCallbacks<NGEDSubstationAsset[] | null | undefined>
) {
	const apiContext = useContext(ApiContext);
	return useQuery(
		[`substationList`],
		async () => {
			return await apiContext.substationApis.getSubstationList();
		},
		{
			onSuccess: callBacks?.onSuccess,
			onError: callBacks?.onError,
			refetchOnWindowFocus: false,
		}
	);
}
