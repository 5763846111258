import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { CSSObject, styled } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import ApiContextProvider from "./components/contexts/ApiContext";
import Footer from "./components/Footer";
import Header from "./components/Header";
import NavDrawer from "./components/NavigationDrawer";
import LoginDialog from "./components/Shared/Login/LoginDialog";
import { AuthContext } from "./components/contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import RequestAccessDialog from "./components/Shared/Login/RequestAccessDialog";
import { onCallToActionPage, onPageRequiringAuthentication } from "./components/utils/PageIdentification";

interface Props {
	children?: React.ReactNode;
	routes?: any;
}

const ContentContainer = styled("div")(({ theme }) => ({
	overflow: "auto",
	height: `calc(100vh - 86px)`,
	[`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
		height: `calc(100vh - 78px)`,
	},
	[theme.breakpoints.up("sm")]: {
		height: `calc(100vh - 94px)`,
	},
}));

const AppbarSpacer = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...(theme.mixins.toolbar as CSSObject),
}));

export function Layout(props: Props) {
	const authContext = useContext(AuthContext);
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	const [loginDialogVisible, setLoginDialogVisible] = useState(false);
	const [registerRequest, setRegisterRequest] = useState(false);

	useEffect(() => {
	if(authContext.userIsAuthenticated && onCallToActionPage(location.pathname)){
		navigate('/map');
	}
	}, [authContext.userIsAuthenticated])

	function onLogoutButtonClick(){
		authContext.logout();
		setLoginDialogVisible(false);
		navigate("/");
	}

	function onBackToCallToActionClick(){
		setLoginDialogVisible(false);
		navigate("/");
	}

	return (
		<ApiContextProvider onAuthError={authContext.logout}>
			<React.Fragment>
				<CssBaseline enableColorScheme />
				{(loginDialogVisible || (onPageRequiringAuthentication(location.pathname) && !authContext.userIsAuthenticated)) && <LoginDialog
					onAuthenticate={authContext.setUserIsAuthenticated}
					show={!authContext.userIsAuthenticated}
					projectTitle={t("projectTitle")}
					onBackToCallToActionClick={onBackToCallToActionClick}
				/>}
				{registerRequest && <RequestAccessDialog
                onBackClick={() => setRegisterRequest(false)}
                show={registerRequest}/>}
				{(authContext.userIsAuthenticated || !onPageRequiringAuthentication(location.pathname)) &&
					<>
						<Header onLogoutButtonClick={onLogoutButtonClick} 
						pageRequiresAuthentication={onPageRequiringAuthentication(location.pathname)} 
						onCallToActionPage={onCallToActionPage(location.pathname)}
						onSignInButtonClick={() => setLoginDialogVisible(true)}
						onRegisterButtonClick={() => setRegisterRequest(true)}/>
						<Box sx={{ display: "flex" }}>
						{onPageRequiringAuthentication(location.pathname) && <NavDrawer />}
							<Box sx={{ flexGrow: 1, minWidth: 0 }}>
								<main>
									<AppbarSpacer />
									{/* hack : div added to sort content display on smaller screens. This project supports screens of min ipad size */}
									<div className="spacerBar" />
									<ContentContainer>
										{props.children}
									</ContentContainer>
								</main>
							</Box>
						</Box>
						<Footer />
					</>
				}
			</React.Fragment>
		</ApiContextProvider>
	);
}
