import { useContext, useCallback } from "react";
import Highcharts from "highcharts";
import { useInternationalFormatters } from "../../utils/useInternationalFormatters";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import { DataTypePhaseColors } from "../../../themes/MuiTheme";
import { MainTheme } from "../../contexts/MainThemeContext";
import { Phase } from "../../enums/Phase";
import { DataPointType, DataPointTypeUnits } from "../../enums/DatapointTypes";
import { Unit } from "../../enums/Units";

export function useHighchartsTheme() {
	const { t } = useTranslation();
	const { localDateStrings } = useInternationalFormatters();
	const theme = useTheme();
	const mainThemeContext = useContext(MainTheme);

	const selectedMode = mainThemeContext.getPaletteMode();

	const chartElementsColour = theme.palette.text.secondary;

	const getSeriesColour = useCallback(
		(phase?: Phase, dataPointType?: DataPointType) => {
			//TODO (#12047): dark/light mode colours are currently the same - may need to tweak these once we start creating charts using real data
			const seriesColors =
				selectedMode === "dark"
					? [
							"#50B432",
							"#ED561B",
							"#DDDF00",
							"#24CBE5",
							"#64E572",
							"#FF9655",
							"#FFF263",
							"#6AF9C4",
							"#058DC7",
					  ]
					: [
							"#50B432",
							"#ED561B",
							"#DDDF00",
							"#24CBE5",
							"#64E572",
							"#FF9655",
							"#FFF263",
							"#6AF9C4",
							"#058DC7",
					  ];

			const defaultColor =
				selectedMode === "dark" ? "#058DC7" : "#058DC7";

			let datatypeColorKey: keyof DataTypePhaseColors =
				"defaultPhaseColors";

			const unit = dataPointType
				? DataPointTypeUnits[dataPointType]
				: undefined;

			switch (unit) {
				case Unit.Amps:
				case Unit.KA:
					datatypeColorKey = "currentPhaseColors";
					break;
				case Unit.Volts:
					datatypeColorKey = "voltagePhaseColors";
					break;
				default:
					datatypeColorKey = "defaultPhaseColors";
			}

			let color = "";

			//TODO: need to add cable naming preference stuff - task #11092
			switch (phase) {
				case Phase.L1:
					color =
						theme.palette.common["L1_L2_L3"][datatypeColorKey].L1;
					break;
				case Phase.L2:
					color =
						theme.palette.common["L1_L2_L3"][datatypeColorKey].L2;
					break;
				case Phase.L3:
					color =
						theme.palette.common["L1_L2_L3"][datatypeColorKey].L3;
					break;
				case Phase.N:
					color =
						theme.palette.common["L1_L2_L3"][datatypeColorKey].N;
					break;
				default:
					color = seriesColors?.shift() || defaultColor;
			}

			return color;
		},
		[selectedMode, theme.palette.common]
	);

	const highchartsTheme: Highcharts.Options = {
		lang: {
			months: localDateStrings.monthNames,
			shortMonths: localDateStrings.monthNamesShort,
			weekdays: [
				t("days.short.sunday"),
				t("days.short.monday"),
				t("days.short.tuesday"),
				t("days.short.wednesday"),
				t("days.short.thursday"),
				t("days.short.friday"),
				t("days.short.saturday"),
			],
			noData: t("common.noDataToDisplay"),
			resetZoom: t("common.resetZoom"),
			downloadCSV: t("common.downloadFile", { fileType: "csv" }),
			downloadPNG: t("common.downloadFile", { fileType: "png" }),
			viewFullscreen: t("common.viewFullscreen"),
		},
		chart: {
			zoomType: "xy",
			backgroundColor: theme.palette.background.paper,
			borderWidth: 0,

			style: {
				"font-family": "Roboto",
				color: chartElementsColour,
			},
		},
		title: {
			style: {
				color: chartElementsColour,
				fontSize: "14px",
			},
		},
		subtitle: {
			style: {
				color: chartElementsColour,
			},
		},
		legend: {
			itemStyle: {
				font: "9pt Trebuchet MS, Verdana, sans-serif",
				color: chartElementsColour,
				textDecoration: "none",
			},
			itemHoverStyle: {
				color: chartElementsColour,
			},
			itemHiddenStyle: {
				textDecoration: "line-through",
				color: theme.palette.text.disabled,
			},
		},
		xAxis: {
			labels: {
				style: {
					color: chartElementsColour,
				},
			},
			showLastLabel: true,
			maxPadding: 0.05,
			lineWidth: 1,
			lineColor: chartElementsColour,
		},
		yAxis: {
			labels: {
				style: {
					color: chartElementsColour,
				},
			},
			gridLineWidth: 0,
			lineWidth: 1,
			lineColor: chartElementsColour,
		},
		navigation: {
			buttonOptions: {
				theme: {
					fill: "none",
					stroke: "none",
				},
			},
		},
	};

	const plotStatutoryLimit = (limit: number, text: string) => {
		return {
			color: "red",
			value: limit,
			width: 2,
			label: {
				useHTML: true,
				text: text,
				align: "left",
				y: -10,
				style: {
					backgroundColor: "white",
					opacity: 0.8,
				},
			},
		};
	};

	return {
		highchartsTheme,
		getSeriesColour,
		plotStatutoryLimit,
	};
}
