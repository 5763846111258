import { TimePeriod } from '../Shared/DatePeriodSelector'
import { DurationLike } from 'luxon';

export const timePeriodToDuration = (key: TimePeriod): DurationLike | null => {
  switch (key) {
    case TimePeriod.TWENTY_FOUR_HOURS:
      return { hour: 24 };
    case TimePeriod.SEVEN_DAYS:
      return { day: 7 };
    case TimePeriod.THIRTY_DAYS:
      return { day: 30 };
    case TimePeriod.NINETY_DAYS:
      return { day: 90 };
	case TimePeriod.MOST_RECENT:
	case TimePeriod.ALL_TIME:
    case TimePeriod.SINGLE:
    case TimePeriod.RANGE:
      return null;
  }
};