import { useContext } from "react";
import { useQuery } from "react-query";
import { ApiContext } from "../../../contexts/ApiContext";
import { QueryCallbacks } from "../../../utils/interfaces/ReactQuery";
import { County } from "../interfaces/Region";

export function useCountyData(
    callBacks?: QueryCallbacks<County[] | undefined>)
{
    const apiContext = useContext(ApiContext);
	return useQuery(
		[`CountyData`],
		async () => {
			    return await apiContext.geoFenceApis.getCounties();
		},
		{ 
            onSuccess: callBacks?.onSuccess,
			onError: callBacks?.onError,
			refetchOnWindowFocus: false
		}
	);
}