import config from "../../../configuration";

const TokenKey = 'jwt';
const TokenExpiryKey = 'jwtExpires';
const RefreshTokenKey = 'refreshToken';

export function getToken() : string | null {
    return localStorage.getItem(TokenKey);
}

export function setToken(token:  string) {
    localStorage.setItem(TokenKey, token);
}

export function getRefreshToken(): string | null {
    return localStorage.getItem(RefreshTokenKey);
}

export function setRefreshToken(refreshToken: string) {
    localStorage.setItem(RefreshTokenKey, refreshToken);
}

export function getExpires(): Date | null {
    var expires = localStorage.getItem(TokenExpiryKey);
    if (!expires)
        return null;
    return new Date(Number(expires) * 1000);
}

export function setExpires(expires: string) {
    localStorage.setItem(TokenExpiryKey, expires);
}

export function storeAuthResponse(token: string, expires: string, refreshToken: string) {
    setToken(token);
    setExpires(expires);
    setRefreshToken(refreshToken);
}

export function removeToken() {
    localStorage.removeItem(TokenKey)
    localStorage.removeItem(TokenExpiryKey)
    localStorage.removeItem(RefreshTokenKey)
}

export function getTokenPayload() {
    const token = getToken();

    if(token != null){
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
    else{
        return null;
    }
}

export function tokenIsValid(): boolean {
    const token = getToken();
    var expiry = getExpires();
    if (token == null || expiry == null || token.length === 0 || (expiry && new Date() > expiry)) {
        return false;
    }
    return true;
}

export function tokenExpiryIsValid(): boolean {
    var expiry = getExpires();
    return expiry == null || (expiry && new Date() > expiry) ? false : true;
}

export function refreshTokenExists(): boolean {
    return getRefreshToken() != null;
}

export async function refreshToken(){
    var refreshToken = getRefreshToken();
    if (!refreshToken)
        return Promise.reject(Error("No refresh token fetched."));
    var postBody = `grant_type=refresh_token&refresh_token=${refreshToken}`;
    return fetch(`${config.apiGateway.NGED_AUTH_API}/api/token`, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        body: postBody
    }).then(resp => {
        if (resp.ok) {
            return resp.json().then(js => {
                storeAuthResponse(js.token, js.expires, js.refresh_token);
                return js.token;
            });
        } else {
            return Promise.reject(Error(`Token couldn't be refresh, HTTP code ${resp.status}: ${resp.statusText}`));
        }
    });
}