import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import MuiCard, { CardProps } from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
	CardActionArea,
	Container,
	Divider,
	Skeleton,
	Stack,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-solid-svg-icons";

import { SubstationFiltersContext } from "../SubstationOverview/SubstationListContext";
import useCarbonIntensityAndGenerationMixAtLicenceAreaLevel from "../data/CarbonIntensity/hooks/useCarbonIntensityAndGenerationMixAtLicenceAreaLevel";
import { LicenceAreaRegionId } from "../../configuration";
import { CarbonIntensityAPIRegionId } from "../data/CarbonIntensity/interfaces/CarbonIntensity";
import { GenerationStatisticsDisplay } from "./GenerationStatisticsDisplay";

interface Props {
	name?: string;
}

const CARD_WIDTH = 730;
const CARD_HEIGHT = 80;

const Card = styled(MuiCard)<CardProps>(({ theme }) => {
	return {
		width: CARD_WIDTH,
		minHeight: CARD_HEIGHT,
		borderLeftWidth: theme.spacing(1),
		borderLeftStyle: "solid",
		borderLeftColor: theme.palette.mainInstance.main,
		padding: theme.spacing(1),
		cursor: "pointer",
	};
});

export function LicenceAreaCard(props: Props) {
	const { name } = props;
	const { t } = useTranslation();

	const {
		substationFilters: { licenceArea },
	} = useContext(SubstationFiltersContext);

	const {
		data: licenceAreaCarbonIntensityAndGenerationMix,
		isLoading: licenceAreaCarbonIntensityAndGenerationMixLoading,
	} = useCarbonIntensityAndGenerationMixAtLicenceAreaLevel(
		getCarbonIntensityApiRegionID(licenceArea?.id)
	);

	function getCarbonIntensityApiRegionID(licenceAreaId?: number) {
		switch (licenceAreaId) {
			case LicenceAreaRegionId.EastMidlands: {
				return CarbonIntensityAPIRegionId.EastMidlands;
			}
			case LicenceAreaRegionId.SouthWales: {
				return CarbonIntensityAPIRegionId.SouthWales;
			}
			case LicenceAreaRegionId.SouthWestEngland: {
				return CarbonIntensityAPIRegionId.SouthWestEngland;
			}
			case LicenceAreaRegionId.WestMidlands: {
				return CarbonIntensityAPIRegionId.WestMidlands;
			}
		}
	}

	return (
		<Card variant="outlined">
			<Box
				display="flex"
				flexDirection="column"
				sx={{ width: 1, height: "100%", p: 0 }}
			>
				<CardActionArea>
					<Typography variant="h6" maxWidth={`${CARD_WIDTH}px`}>
						<FontAwesomeIcon icon={faMap} />{" "}
						{t("substation.licenceAreaDescription", {
							value: name,
						})}
					</Typography>
					<Divider />
				</CardActionArea>

				<Container>
					{licenceAreaCarbonIntensityAndGenerationMixLoading ? (
						<Skeleton variant="rectangular" height="100%" />
					) : (
						<Stack marginTop={1} alignItems="center">
							<GenerationStatisticsDisplay
								licenceAreaCarbonIntensityAndGenerationMix={
									licenceAreaCarbonIntensityAndGenerationMix
								}
								selectedLicenceAreaName={licenceArea?.name}
							/>
						</Stack>
					)}
				</Container>
			</Box>
		</Card>
	);
}
