import React from "react";
import { useTranslation } from "react-i18next";
import { Calendar, CalendarProps } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { Info } from "luxon";
import { getLocaleDateString } from "../utils/dateTimeFormatHelper";

export function InternationalDatepicker(props: CalendarProps) {
	const { t, i18n } = useTranslation();

	addLocale("current", {
		firstDayOfWeek: 0,
		dayNames: [
			t("days.sunday"),
			t("days.monday"),
			t("days.tuesday"),
			t("days.wednesday"),
			t("days.thursday"),
			t("days.friday"),
			t("days.saturday"),
		],
		dayNamesShort: [
			t("days.short.sunday"),
			t("days.short.monday"),
			t("days.short.tuesday"),
			t("days.short.wednesday"),
			t("days.short.thursday"),
			t("days.short.friday"),
			t("days.short.saturday"),
		],
		dayNamesMin: [
			t("days.short.sunday"),
			t("days.short.monday"),
			t("days.short.tuesday"),
			t("days.short.wednesday"),
			t("days.short.thursday"),
			t("days.short.friday"),
			t("days.short.saturday"),
		],
		monthNames: Info.months("long", { locale: i18n.language }),
		monthNamesShort: Info.months("short", { locale: i18n.language }),
	});

	return (
		<React.Fragment>
			<Calendar
				locale="current"
				dateFormat={getLocaleDateString()}
				readOnlyInput
				{...props}
			/>
		</React.Fragment>
	);
}
