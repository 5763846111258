import { ReactNode } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Divider,
	Typography
} from '@mui/material';

interface Props {
	open: boolean;
	titleText: string;
	content: ReactNode;
	actions: ReactNode;
}

const EA_PURPLE = '#220639';

export default function LoginStyledDialog(props: Props) {
	const { open, titleText, content, actions } = props;

	return <Dialog
		open={open}
		maxWidth="sm"
		fullWidth
		sx={{
			'& .MuiDialogContent-root': {
				padding: 2
			},
			'& .MuiDialogActions-root': {
				padding: 3
			},
			'& .MuiBackdrop-root': {
				backgroundColor: EA_PURPLE
			},
			height: "600px"
		}}
	>
		<DialogTitle
			sx={(theme) => ({
				m: 0,
				p: 2,
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.primary.contrastText
			})}
		>
			<Typography variant="h5" component="span">{titleText}</Typography>
		</DialogTitle>
		<DialogContent>
			{content}
		</DialogContent>
		<Divider />
		<DialogActions>
			{actions}
		</DialogActions>
	</Dialog>
}