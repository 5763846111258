import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { LicenceArea } from "../components/data/GeoFence/interfaces/Region";
import { useLicenceAreaData } from "../components/data/GeoFence/hooks/useLicenceAreaData";
import DropdownLayout, {
	DropdownLayoutProps,
} from "../components/Shared/DropdownLayout/DropdownLayout";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { faL } from "@fortawesome/free-solid-svg-icons";

interface Props extends DropdownLayoutProps {
	onLicenceAreaChange: (licenceArea: LicenceArea | undefined) => void;
	selectedLicenceArea?: LicenceArea;
	initialLicenceArea?: string;
	populateAttributes?: boolean;
	error?: string;
	onCloseError?: () => void;
}

export default function LicenceAreaDropdown(props: Props) {
	const { t } = useTranslation(["translation"]);
	const theme = useTheme();
	const dropDownInputRef = useRef<HTMLSelectElement>(null);

	const {
		selectedLicenceArea,
		onLicenceAreaChange,
		initialLicenceArea,
		error,
		onCloseError,
		...rest
	} = props;

	const {
		data: licenceAreasData,
		isLoading: licenceAreaDataIsLoading,
		isError: licenceAreaDataIsError,
		error: licenceAreaDataError,
	} = useLicenceAreaData();

	const value =
		initialLicenceArea && !selectedLicenceArea
			? licenceAreasData?.find(
					(licenceArea) => licenceArea.name === initialLicenceArea
			  )?.name
			: licenceAreasData?.find(
					(licenceArea) =>
						licenceArea.name === selectedLicenceArea?.name
			  )?.name;

	const preselectedLicenceArea = licenceAreasData?.find(
		(licenceArea) => licenceArea.name === initialLicenceArea
	);

	if (initialLicenceArea && !selectedLicenceArea && preselectedLicenceArea) {
		onLicenceAreaChange(preselectedLicenceArea);
	}

	function handleChange(e: DropdownChangeParams) {
		if (!e.value) {
			onLicenceAreaChange(undefined);
		}
		const licenceArea = licenceAreasData?.find(
			(licenceArea) => licenceArea.name === e.value
		);
		licenceArea && onLicenceAreaChange(licenceArea);
	}

	const handlePopoverClose = () => onCloseError && onCloseError();
	const validationOpen = Boolean(error);

	return (
		<DropdownLayout
			isLoading={licenceAreaDataIsLoading}
			isError={licenceAreaDataIsError}
			errorMessageSeverity="error"
			errorMessage={`${t("commonErrorMessages.dataFetchError", {
				ns: "translation",
				val: t("substation.licenceAreas"),
			})}: ${licenceAreaDataError}`}
			labelText={t("substation.licenceArea")}
			{...rest}
		>
			<Dropdown
				className={clsx({ "p-invalid": props.invalid || error })}
				disabled={!licenceAreasData}
				value={value}
				optionValue="name"
				options={licenceAreasData?.sort((a, b) =>
					a.name > b.name ? 1 : -1
				)}
				onChange={handleChange}
				optionLabel="name"
				emptyMessage={t("substation.noLicenceAreas")}
				showClear
				placeholder={t("common.select", {
					item: "$t(substation.licenceArea)",
				})}
				inputRef={dropDownInputRef}
				{...rest}
			/>
			<Popover
				open={validationOpen}
				disableAutoFocus
				anchorEl={dropDownInputRef.current}
				onClose={handlePopoverClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Box
					sx={{
						p: 2,
						pb: 0,
						color: theme.palette.warning.contrastText,
						background: theme.palette.warning.light,
						maxWidth: "sm",
					}}
				>
					{(error || "")?.split("\n").map((text, idx) => (
						<Typography
							key={`message-${idx}`}
							variant="body2"
							sx={{
								pb: 2,
							}}
							dangerouslySetInnerHTML={{ __html: text }}
						></Typography>
					))}
				</Box>
			</Popover>
		</DropdownLayout>
	);
}
