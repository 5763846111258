import { useEffect, useMemo, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { useHighchartsTheme } from "./useHighchartsTheme";
import { ChartViewType } from "../../Measurements/Measurements";
import { useTranslation } from "react-i18next";
import { DateTime, Interval } from "luxon";
import { getDownloadIcon } from "../../Measurements/Icons/DownloadIcon";
import { useTheme } from "@mui/material";
import { NoChartDataDisplayMessage } from "./NoChartDataDisplayMessage";

// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/boost")(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);

interface Props {
	startDate?: Date;
	endDate?: Date;
	series: Partial<Highcharts.SeriesOptionsType>[] | undefined;
	chartTitle?: string;
	chartSubtitle?: string;
	yAxisSettings?: Highcharts.YAxisOptions | Highcharts.YAxisOptions[];
	xAxisSettings?: Highcharts.XAxisOptions | Highcharts.XAxisOptions[];
	xAxisTitle?: string;
	xAxisType?: Highcharts.AxisTypeValue;
	enableLegend?: boolean;
	sharedTooltip?: boolean;
	tooltipOptions?: Highcharts.TooltipOptions;
	enablePngExporting?: boolean;
	enableExporting?: boolean;
	noDataMessage?: string;
	view?: ChartViewType;
	exportChartTitle?: string;
}

export default function LineChart(props: Props) {
	const { highchartsTheme } = useHighchartsTheme();
	const chartRef = useRef<any>(null);
	const { t } = useTranslation();
	const theme = useTheme();

	Highcharts.setOptions(highchartsTheme);

	Highcharts.SVGRenderer.prototype.symbols.download = getDownloadIcon;

	const customExportButtons = useMemo(() => {
		const csvExport = {
			text: t("common.exportCsv"),
			_titleKey: "exportCSVToolTip",
			onclick: function () {
				chartRef.current.chart.downloadCSV();
			},
		};

		const pngExport = {
			text: t("common.exportPng"),
			_titleKey: "exportPNGToolTip",
			onclick: function () {
				chartRef.current.chart.exportChart();
			},
		};

		return props.series != null &&
			props.enablePngExporting &&
			props.series.length > 0
			? [csvExport, pngExport]
			: props.series != null && props.series.length > 0
			? [csvExport]
			: [];
	}, [t, props.series, props.enablePngExporting]);

	const chartOptions = useMemo(() => {
		return {
			chart: {
				type: "spline",
			},
			time: {
				useUTC: false,
			},
			title: {
				text: props.chartTitle,
			},
			subtitle: {
				text: props.chartSubtitle,
			},
			legend: {
				enabled: props.enableLegend,
			},
			tooltip: {
				shared: props.sharedTooltip,
				...props.tooltipOptions,
			},
			xAxis: {
				type: props.xAxisType || "linear",
				title: {
					text: props.xAxisTitle,
				},
				minRange:
					props.startDate &&
					props.endDate &&
					Interval.fromDateTimes(
						DateTime.fromJSDate(props.startDate),
						DateTime.fromJSDate(props.endDate)
					),
				min:
					props.startDate &&
					DateTime.fromJSDate(props.startDate).toMillis(),
				max:
					props.endDate &&
					DateTime.fromJSDate(props.endDate).toMillis(),
				events: {
					afterSetExtremes: function (event: {
						min: number | undefined;
						max: number | undefined;
					}) {
						Highcharts.charts.forEach((chart) => {
							if (chart)
								chart.xAxis[0].setExtremes(
									event.min,
									event.max
								);
						});
					},
				},
				...props.xAxisSettings,
			},
			yAxis: props.yAxisSettings,
			plotOptions: {
				series: {
					lineWidth: 2,
				},
				spline: {
					lineWidth: 2,
					states: {
						hover: {
							lineWidth: 3,
						},
					},
					marker: {
						enabled: false,
					},
				},
			},
			credits: {
				enabled: false,
			},
			exporting: {
				filename: props.exportChartTitle,
				enabled: props.enableExporting,
				buttons: {
					contextButton: {
						menuItems: customExportButtons,
						symbol: "download",
						symbolStrokeWidth: 1,
						symbolFill: theme.palette.mainInstance.main,
						symbolStroke: theme.palette.mainInstance.main,
					},
				},
			},
			series: props.series,
			lang: {
				noData: props.noDataMessage ?? "",
				exportPNGToolTip: t("common.exportGraphAsPng"),
				exportCSVToolTip: t("common.exportGraphAsCsv"),
				contextButtonTitle: t("common.clickToSeeChartDownloadOptions"),
			},
			noData: {
				style: {
					fontSize: "15px",
				},
			},
		};
	}, [
		props.chartTitle,
		props.chartSubtitle,
		props.enableLegend,
		props.sharedTooltip,
		props.tooltipOptions,
		props.xAxisType,
		props.xAxisTitle,
		props.startDate,
		props.endDate,
		props.xAxisSettings,
		props.yAxisSettings,
		props.exportChartTitle,
		props.enableExporting,
		props.series,
		props.noDataMessage,
		customExportButtons,
		theme.palette.mainInstance.main,
		t,
	]);

	useEffect(() => {
		const chart = chartRef.current?.chart;
		if (chart) chart.reflow();
	}, [props.view]);

	return (
		<>
			{props.series && props.series.length ? (
				<HighchartsReact
					ref={chartRef}
					highcharts={Highcharts}
					options={chartOptions}
					constructorType={"chart"}
					containerProps={{ style: { height: "100%" } }}
				/>
			) : (
				<NoChartDataDisplayMessage message={props.noDataMessage} />
			)}
		</>
	);
}
