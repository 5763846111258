import React from 'react';

import { Theme } from '@mui/material/styles';

import { Marker as LeafletMarker, MarkerOptions, LatLng } from 'leaflet';
import { EventedProps, createLayerComponent } from '@react-leaflet/core'
import { getSubstationLeafletIcon } from './icons/SubstationMarkerIcon'
import { Severity } from '../../enums/Severity';
import { getSubstationColor } from '../../Shared/SubstationTypeHelper';
import { NGEDSubstationAsset } from '../../data/Substation/interfaces/NGEDSubstationAsset';

interface SubstationMarkerProps extends MarkerOptions, EventedProps {
	children?: React.ReactNode;
	theme: Theme;
	substation: NGEDSubstationAsset;
}

export interface SubstationLeafletMarker extends LeafletMarker {
	severity: Severity;
}


export const SubstationMarker = createLayerComponent<LeafletMarker, SubstationMarkerProps>(

	function createMarker({substation, theme, ...options }, ctx) {
		const instance = createLeafletMarker(theme, options, substation);
		return { instance, context: { ...ctx, overlayContainer: instance } }
	},

	function updateMarker(marker, props, prevProps) {
		const position = new LatLng(props.substation.gpsCoords.latitude, props.substation.gpsCoords.longitude);
		const prevPosition = new LatLng(props.substation.gpsCoords.latitude, props.substation.gpsCoords.longitude);		

		if (position !== prevPosition) {
			marker.setLatLng(position)
		}
		if (props.icon != null && props.icon !== prevProps.icon) {
			marker.setIcon(props.icon)
		}
		if (
			props.zIndexOffset != null &&
			props.zIndexOffset !== prevProps.zIndexOffset
		) {
			marker.setZIndexOffset(props.zIndexOffset)
		}
		if (props.opacity != null && props.opacity !== prevProps.opacity) {
			marker.setOpacity(props.opacity)
		}
		if (marker.dragging != null && props.draggable !== prevProps.draggable) {
			if (props.draggable === true) {
				marker.dragging.enable()
			} else {
				marker.dragging.disable()
			}
		}
	},
)

function createLeafletMarker(theme: Theme, options: MarkerOptions, substation: NGEDSubstationAsset) {
	const color = getSubstationColor(substation.assetTypeKey, theme);
	const position = new LatLng(substation.gpsCoords.latitude, substation.gpsCoords.longitude);
	const marker = new LeafletMarker(position, {
		...options,
		icon: getSubstationLeafletIcon(color),
	}) as LeafletMarker;

    return marker;
}
