import React from "react";
import config from "../../../configuration";
import { ApiHandlerPureComponent, handleResponseArray } from "../../utils";
import { GeoFence } from "./interfaces/GeoFence";
import { County, LicenceArea } from "./interfaces/Region";

interface Props {
	onAuthError?: () => void;
}

export default class GeoFenceAPIs extends React.PureComponent<Props> {
	private apiResponseHandler = new ApiHandlerPureComponent({ onAuthError: this.props.onAuthError });

	private static readonly BASE_URL: string = `${config.apiGateway.NGED_META_API}/api/GeoFence/`;

	getGeoFenceDataBySubstationNumber = async (
		substationNumber: number | string
	): Promise<GeoFence> => {
		return this.apiResponseHandler.get(
			`${GeoFenceAPIs.BASE_URL}GeoJsonBySubsationNumber/${substationNumber}`
		);
	};

	getLicenceAreas = async (): Promise<LicenceArea[]> => {
		return this.apiResponseHandler
			.get(`${GeoFenceAPIs.BASE_URL}LicenceAreas`)
			.then((data) => handleResponseArray(data));
	};

	getGeoFenceDataById = async (id: number): Promise<GeoFence> => {
		return this.apiResponseHandler.get(
			`${GeoFenceAPIs.BASE_URL}GeoJsonById/${id}`
		);
	};

	getCounties = async (): Promise<County[]> => {
		return this.apiResponseHandler
			.get(`${GeoFenceAPIs.BASE_URL}Counties`)
			.then((data) => handleResponseArray(data));
	};
}
