import { useEffect, useMemo } from "react";
import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import FilterChip from "../FilterChip";
import LabelText from "../LabelValueFormatters/LabelText";
import useDataPointTypeFilters from "./useDataPointTypeFilters";
import { DataPointType } from "../../enums/DatapointTypes";
import { AssetTypes } from "../../enums/AssetTypes";
import { Typography } from "@mui/material";
import { DateTime } from "luxon";
import { CategorisedData } from "../../data/Data/interfaces/Data";

interface DataPointTypeFilterProps {
	dataPointTypes: DataPointType[];
	assetType: AssetTypes | undefined;
	onSelectedDataPointTypesChange: (
		selectedDataPointTypes: DataPointType[]
	) => void;
	defaultSelectedValues?: DataPointType[];
	carbonIntensitySelected: boolean;
	handleCarbonIntensityDataPointTypeSelectionChange: () => void;
	measurementsData?: CategorisedData[];
}

export default function DataPointTypeFilter(props: DataPointTypeFilterProps) {
	const {
		dataPointTypes,
		assetType,
		onSelectedDataPointTypesChange,
		defaultSelectedValues,
		carbonIntensitySelected,
		handleCarbonIntensityDataPointTypeSelectionChange,
		measurementsData,
	} = props;
	const { t } = useTranslation();

	const [
		dataPointTypeFilters,
		handleDataPointTypeSelectionChange,
		selectedDataPointTypes,
	] = useDataPointTypeFilters({
		dataPointTypes: dataPointTypes,
		assetType: assetType,
		defaultSelectedValues: defaultSelectedValues,
	});

	useEffect(() => {
		onSelectedDataPointTypesChange(selectedDataPointTypes);
	}, [selectedDataPointTypes, onSelectedDataPointTypesChange]);

	const interval = useMemo(() => {
		const nonEmptyMeasurementData = measurementsData?.filter(
			(md) => md.series.categorisedData
		);

		const categorisedData =
			nonEmptyMeasurementData &&
			nonEmptyMeasurementData[0] &&
			nonEmptyMeasurementData[0].series.categorisedData;

		const dataPointTypeKey =
			categorisedData && Object.keys(categorisedData)[0];
		const times =
			categorisedData &&
			dataPointTypeKey &&
			categorisedData[dataPointTypeKey].times;

		if (times) {
			return (
				DateTime.fromISO(times[1]).diff(DateTime.fromISO(times[0]))
					.milliseconds / 60000
			);
		}
	}, [measurementsData]);

	return (
		<Stack
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			spacing={2}
			flexWrap="wrap"
			marginLeft={2}
			marginRight={2}
			marginTop={2}
		>
			<Box>
				<Box marginBottom={2}>
					<LabelText
						text={`${t("dataPointTypes.dataPointTypes")}`}
						style={{ whiteSpace: "nowrap" }}
					/>
				</Box>
			</Box>

			{dataPointTypeFilters
				.filter((dataPointTypeFilter) => dataPointTypeFilter.available)
				.map((dataPointTypeFilter) => (
					<Box>
						<Box marginBottom={2}>
							<FilterChip
								key={`filterChip-${dataPointTypeFilter.dataPointType}`}
								isSelected={dataPointTypeFilter.selected}
								label={t(
									`dataPointTypes.${dataPointTypeFilter?.dataPointType}`,
									{ ns: "translation" }
								)}
								onClick={() =>
									handleDataPointTypeSelectionChange(
										dataPointTypeFilter.dataPointType
									)
								}
								disabled={!dataPointTypeFilter.enabled}
								message={t(
									`dataPointTypes.messages.${dataPointTypeFilter?.dataPointType}`
								)}
							/>
						</Box>
					</Box>
				))}

			<Box>
				<Box marginBottom={2}>
					<FilterChip
						key={`filter-chip-carbon-intensity`}
						isSelected={carbonIntensitySelected}
						label={t("carbonIntensity.carbonIntensity")}
						onClick={() =>
							handleCarbonIntensityDataPointTypeSelectionChange()
						}
						message={t("carbonIntensity.definition")}
					/>
				</Box>
			</Box>

			{interval && (
				<Box style={{ marginLeft: "auto" }}>
					<Box marginBottom={2}>
						<Stack alignItems="flex-end">
							<Typography
								sx={{
									fontStyle: "italic",
									typography: "body2",
								}}
								color="textSecondary"
								align="right"
							>
								{t("measurements.measurementResolution", {
									value: interval,
								})}
							</Typography>
						</Stack>
					</Box>
				</Box>
			)}
		</Stack>
	);
}
