import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FormGroup, FormHelperText, Box,
	AlertColor, Grid, 
    Tooltip} from '@mui/material';

import usePasswordReset from '../../data/Login/hooks/usePasswordReset';
import config from '../../../configuration';
import { PasswordResetRequest } from './PasswordResetRequest';
import MessageToast from '../Messages/MessageToast';
import ProgressButton from '../Inputs/ProgressButton';
import { LoginStyledTextInput } from '../Login/LoginStyledTextInput';
import { passwordDoesNotContainUppercase, passwordDoesNotContainLowercase, passwordDoesNotContainSymbols, passwordDoesNotContainDigits } from '../../utils/helpers/PasswordStrengthHelper';

export default function PasswordReset() {
	const { t } = useTranslation();
    const [password, setPassword] = useState("");
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const [tokenVerified, setTokenVerified] = useState<boolean | undefined>(undefined);
	const [messageText, setMessageText] = useState("");
	const [messageSeverity, setMessageSeverity] = useState<AlertColor | undefined>();
    const {email, token} = useParams();
    const navigate = useNavigate();

    const navigateToLogin = () => {
        navigate('/');
    };
    const { mutate: resetPassword, 
        isError: errorResettingPassword, 
        isSuccess: passwordReset,
        isLoading: loadingPasswordReset } = usePasswordReset();
    
    useEffect(() => {
        if(email && token){
            let confirmTokenEndpointAddress = `${config.apiGateway.NGED_AUTH_API}/api/Account/verifyPasswordResetToken?email=${encodeURIComponent(email)}&token=${encodeURIComponent(token)}`;
            fetch(confirmTokenEndpointAddress,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                .then((resp) => {
                    setTokenVerified(resp.status === 200);
                    if(resp.status === 400){
                        setMessageText("Error confirming token, please try requesting a new password again.");
                        setMessageSeverity('error');
                    }
                })
                .catch(_ => {
                    setTokenVerified(false);
                    setMessageText("Error confirming token");
                    setMessageSeverity('error');
                });
        }
    }, []);

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
		resetMessage();
	}

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setConfirmedPassword(event.target.value);
		resetMessage();
	}

    const resetMessage = () => {
        setMessageText("");
		setMessageSeverity(undefined);
    }
 
	function handleSubmit(e: React.SyntheticEvent) {
        if (password === "") {
            setMessageText(t('login.pleaseEnterPassword'));
            setMessageSeverity("error");
            return;
		}
        if (password !== confirmedPassword) {
            setMessageText(t('login.passwordConfirmationError'));
            setMessageSeverity("error");
            return;
		}

        if (password.length < 10) {
            setMessageText(t('login.passwordLength'));
            setMessageSeverity("error");
            return;
        }
		
        if (passwordDoesNotContainUppercase(password)) {
            setMessageText(t('login.passwordUppercase'));
            setMessageSeverity("error");
            return;
        }
		
        if (passwordDoesNotContainLowercase(password)) {
            setMessageText(t('login.passwordLowercase'));
            setMessageSeverity("error");
            return;
        }

        if (passwordDoesNotContainDigits(password)) {
            setMessageText(t('login.passwordNumeric'));
            setMessageSeverity("error");
            return;
        }

        if (passwordDoesNotContainSymbols(password)) {
            setMessageText(t('login.passwordNonAlphanumeric'));
            setMessageSeverity("error");
            return;
        }

        const passwordResetRequest: PasswordResetRequest = {
            email: email ?? "",
            newPassword: password,
            passwordResetToken: token ?? ""
        }

        e.preventDefault();
        resetPassword(passwordResetRequest);
	}

    useEffect(() => {
      if(errorResettingPassword){
        setMessageText(t('login.errorResettingPassword'));
        setMessageSeverity("error");
      }
    }, [errorResettingPassword]);

    useEffect(() => {
        if(passwordReset){
            setPassword("");
            setConfirmedPassword("");
            setMessageText(t('login.passwordReset'));
            setMessageSeverity("success");
        }
    }, [passwordReset]);

    return (
		<Box margin={5}>
			{!passwordReset && <Grid container spacing={2} justifyContent="center">
                {tokenVerified === true && 
                <>
                    <Box>
                        <FormGroup>
                            <FormHelperText sx={{ mt: 2 }}>
                                {t('login.createAPassword')}
                                </FormHelperText>
                                <Tooltip
									title={t('login.passwordStrength')}
									placement="right-start"
									arrow
									enterTouchDelay={0}
								>
									<LoginStyledTextInput
									name="password"
									required={true}
									value={password}
									onChange={handlePasswordChange}
									type="password"
									placeholder={t('login.newPassword')}
								/>
								</Tooltip>
                            <FormHelperText sx={{ mt: 2 }}>
                                {t('login.pleaseConfirmPassword')}
                            </FormHelperText>
                            <LoginStyledTextInput
									name="confirmPassword"
									required={true}
									value={confirmedPassword}
									onChange={handleConfirmPasswordChange}
									type="password"
									placeholder={t('login.confirmPassword')}
								/>
                                  
                            <Box display="flex" justifyContent="center" pt={2}>
                                <ProgressButton onClick={handleSubmit} loading={loadingPasswordReset} >
                                    {t('login.submit')}
                                </ProgressButton>
                            </Box>
                            
                        </FormGroup>
                    </Box>
                </>
                }
            </Grid>}
            {passwordReset && 
                <Box display="flex" justifyContent="center" pt={2}>
                    <ProgressButton onClick={navigateToLogin} loading={false}>
                                        {t('login.login')}
                    </ProgressButton>
                </Box>}

            <Box display="flex" justifyContent="center" pt={2}>
                {messageText && messageSeverity && <MessageToast text={messageText} severity={messageSeverity} />}
            </Box>
		</Box>
	);
}