import React, { PropsWithChildren } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import AssetAPIs from "../data/Assets/AssetAPIs";
import DataAPIs from "../data/Data/DataAPIs";
import GeoFenceAPIs from "../data/GeoFence/GeoFenceAPIs";
import PrimarySubstationAPIs from "../data/Substation/PrimarySubstationAPIs";
import SubstationAPIs from "../data/Substation/SubstationAPIs";
import PostcodesAPIs from "../data/Postcodes/PostcodesAPIs";
import CarbonIntensityAPIs from "../data/CarbonIntensity/CarbonIntensityAPIs";
import AccountAPIs from "../data/Login/AccountAPIs";

interface ApiContextType {
	substationApis: SubstationAPIs;
	dataApis: DataAPIs;
	assetApis: AssetAPIs;
	geoFenceApis: GeoFenceAPIs;
	postcodesApis: PostcodesAPIs;
	primarySubstationApis: PrimarySubstationAPIs;
	carbonIntensityApis: CarbonIntensityAPIs;
	accountAPIs: AccountAPIs;
}

function initialiseApis(onAuthError?: () => void): ApiContextType {
	return {
		substationApis: new SubstationAPIs({onAuthError: onAuthError}),
		dataApis: new DataAPIs({onAuthError: onAuthError}),
		assetApis: new AssetAPIs({onAuthError: onAuthError}),
		geoFenceApis: new GeoFenceAPIs({onAuthError: onAuthError}),
		postcodesApis: new PostcodesAPIs({onAuthError: onAuthError}),
		primarySubstationApis: new PrimarySubstationAPIs({onAuthError: onAuthError}),
		carbonIntensityApis: new CarbonIntensityAPIs({onAuthError: onAuthError}),
		accountAPIs: new AccountAPIs({onAuthError: onAuthError})
	};
}

/**
 * Context containing initialised API handlers. Use this to get API handlers without having to initialise them in each dependent component.
 *
 * @example
 * // Use in function components
 * const MyFunctionComponent = (props) => {
 *     const apiContext = useContext(ApiContext);
 *     const asset = apiContext.assetApi.getAsset(123);
 * }
 *
 * // Use in class components
 * const MyClassComponent extends React.Component {
 *     static contextType = ApiContext; // Set the component's context to this
 *     context!: React.ContextType<typeof ApiContext>; // Set the context type to help TypeScript
 *
 *     const asset = apiContext.assetApi.getAsset(123);
 * }
 */
export const ApiContext = React.createContext<ApiContextType>(
	{} as ApiContextType
);

interface Props {
	onAuthError: () => void;
}

export const ApiContextProvider = (props: Props & PropsWithChildren<unknown>) => {
	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<ApiContext.Provider value={initialiseApis(props.onAuthError)}>
				{props.children}
			</ApiContext.Provider>
		</QueryClientProvider>
	);
};

export default ApiContextProvider;
