import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertColor, Box, FormGroup, FormHelperText, Link, Stack, Typography } from '@mui/material';

import ForgotPasswordDialog from './ForgotPasswordDialog';
import RequestAccessDialog from './RequestAccessDialog';
import config from '../../../configuration';
import MessageToast from '../Messages/MessageToast';
import ProgressButton from '../Inputs/ProgressButton';
import { LoginStyledTextInput } from './LoginStyledTextInput';
import LoginStyledDialog from './LoginStyledDialog';
import { storeAuthResponse } from '../TokenHandling/TokenManagement';
import { useNavigate } from 'react-router';

interface Props {
	onAuthenticate: (isAuthenticated: boolean) => void;
	show: boolean;
	projectTitle: string;
	onBackToCallToActionClick: () => void;
}

export default function LoginDialog(props: Props) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [messageText, setMessageText] = useState("");
	const [messageSeverity, setMessageSeverity] = useState<AlertColor | undefined>();
	const [isLoading, setIsLoading] = useState(false);

	const [showRequestAccessDialog, setShowRequestAccessDialog] = useState(false);
	const [showForgotPasswordDialog, setShowForgotPasswordDialog] = useState(false);

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
		clearMessageToast();
	}

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
		clearMessageToast();
	}

	const handleSubmit = (e: React.SyntheticEvent) => {
		setIsLoading(true);
		e?.preventDefault();

		const authEndpoint = config.apiGateway.NGED_AUTH_API + "/api/token";
		const postBody = new URLSearchParams({
			grant_type: "password",
			email: email,
			password: password,
		});
        
        fetch(authEndpoint,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: postBody
            }).then(resp => handleResponse(resp))
            .catch(_ => {
                setMessageText(t('login.loginError'));
                setMessageSeverity("error");
                setIsLoading(false);
            });
    }

	function handleResponse(response: Response) {
		setIsLoading(false);

		if (response.status === 401) {
			setMessageText(t('login.invalidCredentials'));
			setMessageSeverity("error");
		} else if (!response.ok) {
			setMessageText(t('login.loginError'));
			setMessageSeverity("error");
		} else {
			handleSuccess(response);
		}
	}

	function handleSuccess(response: Response) {
		response.json().then(resp => {
			storeAuthResponse(
				resp.token,
				resp.expires,
				resp.refresh_token
			);
			setIsLoading(false);
            props.onAuthenticate(true);
            clearMessageToast();
			navigate('/map');
		});
	}
	
	function clearMessageToast() {
		setMessageText("");
		setMessageSeverity(undefined);
	}

	return (
		<>
			<LoginStyledDialog
				open={props.show}
				titleText={`${t('login.login')} - ${props.projectTitle}`}
				content={
					<form id="login-form" onSubmit={handleSubmit}>
						<Box mt={2}>
							<Typography
								color="textSecondary"
								variant="subtitle2"
							>
								{t('login.credentials')}
							</Typography>
							<FormGroup sx={{ w: "100%" }}>
								<FormHelperText sx={{ mt: 2 }}>
									{t('login.email')}
								</FormHelperText>
								<LoginStyledTextInput
									key="loginInput"
									required={true}
									value={email}
									onChange={handleEmailChange}
									placeholder={t('login.enterEmail')}
								/>
								<FormHelperText sx={{ mt: 2 }}>
									{t('login.password')}
								</FormHelperText>
								<LoginStyledTextInput
									name="password"
									required={true}
									value={password}
									onChange={handlePasswordChange}
									type="password"
									placeholder={t('login.password')}
								/>
							</FormGroup>
						</Box>
						<Box display="flex" justifyContent="center" pt={2}>
							{messageText && messageSeverity &&
                                <MessageToast text={messageText} severity={messageSeverity} />}
						</Box>
					</form>
				}
				actions={
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						width="100%"
					>
						<Stack>
							<Link
								onClick={() => setShowRequestAccessDialog(true)}
								underline="hover"
								sx={{ cursor: "pointer" }}
							>
								{t('login.requestAccess')}
							</Link>
							<Link
								onClick={() => setShowForgotPasswordDialog(true)}
								underline="hover"
								sx={{ cursor: "pointer" }}
							>
								{t('login.forgotPassword')}
							</Link>
						</Stack>
						<Stack
							direction="row"
							spacing={2}>
								<ProgressButton loading={false}  type="submit" color='inherit' onClick={props.onBackToCallToActionClick}> 
									{t('login.back')}
								</ProgressButton>
								<ProgressButton loading={isLoading} type="submit" form="login-form">
									{t('login.login')}
								</ProgressButton>
						</Stack>
					</Stack>
				}
			/>
			{showRequestAccessDialog && <RequestAccessDialog
                onBackClick={() => setShowRequestAccessDialog(false)}
                show={showRequestAccessDialog}
            />}
			{showForgotPasswordDialog && <ForgotPasswordDialog
                onBackClick={() => setShowForgotPasswordDialog(false)}
                show={showForgotPasswordDialog}
            />}
		</>)
}
