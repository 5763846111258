import { useMemo } from "react";
import { NGEDSubstationAsset } from "../data/Substation/interfaces/NGEDSubstationAsset";

export function useSubstationsSortedByNameAsc(
	substations: NGEDSubstationAsset[]
) {
	return useMemo(() => {
		return substations?.sort((a, b) =>
			a.name && b.name ? a.name.localeCompare(b.name) : -1
		);
	}, [substations]);
}
