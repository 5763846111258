import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA4 from "react-ga4";

const GoogleAnalytics = () => {
    const location = useLocation();
    const [prevPath, setPrevPath] = useState('');

    // Send pageview event to ga4 whenever page location changes 
    useEffect(() => {
        const currentPath = location.pathname + location.search;
        if (currentPath !== prevPath) {
            ReactGA4.send({ hitType: "pageview", page: currentPath, title: document.title });
            setPrevPath(currentPath);
        }

        // If unhandled errors occur send to ga4
        const handleError = (error: ErrorEvent) => {
            ReactGA4.event({
                category: "error",
                action: "Unhandled error",
                label: error.message,
            });
        };

        window.addEventListener("error", handleError);

        return () => {
            window.removeEventListener("error", handleError);
        };
    }, [location, prevPath]);

    return null;
};

export default GoogleAnalytics;
