import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import logo from "../images/EAWhite.svg";

const FooterContainer = styled('div')(
	({ theme }) => ({
		overflow: "auto",
		height: "30px",
        backgroundColor: theme.palette.mainInstance.main,
        paddingRight: "10px",
        display: "flex",
        justifyContent: "right"
        
    }));

const Logo = styled('img')({
    height: "20px",
    width: "30px",
    borderRight: "2px solid white",
    marginRight: "5px",
    marginTop: "6px",
    
    
});

export default function Footer() {
	const { t } = useTranslation();
	const theme = useTheme();

	return (
        <FooterContainer>
            <Logo alt="EA Technology logo" src={logo} />
            <Typography sx={{
                color: theme.palette.mainInstance.contrastText,
                fontFamily: 'Roboto Light, sans-serif',
                fontSize: "1em",
                marginTop: "2px",
                bottom: "0"
            }}>
            {`${t("common.poweredBy")} ${t("poweredByCompany")}`} 
            </Typography>
        </FooterContainer>
	);
}
