import React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

import { usePages } from '../routes'
import { ListItemLink } from './ListItemLink'

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
    height: 'calc(100% - 30px)',
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(5)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(7)} + 1px)`,
	}
});

const Drawer = styled(MuiDrawer)<DrawerProps>(
	({ theme, open }) => ({
		width: drawerWidth,
        height: 'calc(100% - 30px)',
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',

		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	}),

);

const AppbarSpacer = styled('div')(
	({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar as CSSObject,
	}));

const DrawerHeader = styled('div')({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
});

const fontAwesomeIconProps: Partial<FontAwesomeIconProps> = { fixedWidth: true, size: "1x" };

const inactiveMenuItem = (theme: Theme): CSSObject => ({
	borderLeftColor: "transparent",
	borderLeftStyle: "solid",
	borderLeftWidth: "5px",
});

const ActiveListItem = styled('div')(
	({ theme }) => ({
		borderLeftColor: theme.palette.primary.main,
		borderLeftStyle: "solid",
		borderLeftWidth: "5px",
	}));

export default function NavDrawer() {
	const { t } = useTranslation();
	const pages = usePages();
	const location = useLocation();
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	return (
		<Drawer
			variant="permanent"
			open={open}
			PaperProps={{
				sx: {
					width: drawerWidth,
                    height: 'calc(100% - 30px)'
				}
			}}
		>
			<AppbarSpacer />

			{open ?
				<DrawerHeader>
					<IconButton onClick={handleDrawerClose} size="large">
						{theme.direction === 'rtl'
							? <ChevronRightIcon aria-label={t("common.close")} />
							: <ChevronLeftIcon aria-label={t("common.close")} />
						}
					</IconButton>
				</DrawerHeader>
				: <IconButton
					onClick={handleDrawerOpen}
					size="large">
					<MenuIcon />
				</IconButton>
			}

			<Divider sx={{ backgroundColor: theme.palette.grey[600] }} />
			<List>
				{pages.filter(page => page.isMenuItem)
					.map(page => page.path.toLowerCase() === location.pathname.toLowerCase()
						? <ActiveListItem key={`item-${page.path}`}>
							<ListItem disabled>
								<ListItemIcon >{page.icon(fontAwesomeIconProps)}</ListItemIcon>
								<ListItemText primary={page.title} />
							</ListItem>
						</ActiveListItem>
						: <ListItemLink key={`link-${page.path}`} to={page.path} primary={page.title} icon={page.icon(fontAwesomeIconProps)} sx={inactiveMenuItem} title={page.title} withTooltip={true} />
					)}
			</List>
		</Drawer>
	);
}
