import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Button, { ButtonProps } from '@mui/material/Button';

interface Props {
	loading: boolean;
	children?: React.ReactNode;
	submitType?: boolean;
}

export default function ProgressButton(props: Props & ButtonProps) {

	const { loading, children, ...rest } = props;

	return (
		<span>
			<Button
				variant="contained"
				type={props.submitType ? "submit" : undefined}
				disabled={props.loading}
				{...rest}
			>
				{props.loading && <CircularProgress size={24} sx={{ position: "absolute" }} />}
				{props.children}
			</Button>
		</span>
	);
}