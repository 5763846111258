import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { NgedLvmDataUrl } from "../../configuration";
import useLatestMeasurementTimeBySubstation from "../data/Data/hooks/useLatestMeasurementTimeBySubstation";

interface Props {
	substationNumber?: string;
}

export default function LatestMeasurement(props: Props) {
	const { t } = useTranslation();
	const { data: latestSubstationMeasurement } =
		useLatestMeasurementTimeBySubstation(props.substationNumber);

	if (latestSubstationMeasurement == null) return null;

	return (
		<Stack alignItems="flex-end">
			<Button href={NgedLvmDataUrl} target="_blank">
				{t("common.goToDataSource")}
			</Button>
			<Typography
				sx={{ fontStyle: "italic", typography: "body2" }}
				color="textSecondary"
			>
				{t("measurements.latestMeasurementTime", {
					date: new Date(
						latestSubstationMeasurement
					).toLocaleDateString(),
				})}
			</Typography>
		</Stack>
	);
}
