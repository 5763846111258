import { Typography, Box, Grid, useTheme, ImageListItem, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import NetworkInsightsImage from "../images/about/kf-network-insights.png";
import AssetInformationImage from "../images/about/kf-asset-information.png";
import GraphConfigImage from "../images/about/kf-graph-config.png";
import ImpactInsightsImage from "../images/about/kf-impact-insights.svg";
import SpatialViewImage from "../images/about/kf-spatial-view.png";
import { KeyFeature } from "./KeyFeature";

export default function CallToActionPage() {
	const theme = useTheme();
	const { t } = useTranslation();
	return (
		<Box margin={3}>
			<Grid container spacing={2}>
				<Stack direction={"column"} margin={1}>
					<Typography
						variant="h4"
						color={theme.palette.primary.main}
						marginBottom={2}
						marginLeft={2}
					>
						{t("callToAction.keyFeatures")}
					</Typography>

					<Typography
						variant="body1"
						marginLeft={2}
					>
						{t("callToAction.register")}
					</Typography>
				</Stack>
				<Grid item md={6} padding={1}>
					<Box>
						<KeyFeature
							title={t("callToAction.networkPerformanceInsightsTitle")}
							image={NetworkInsightsImage}
						>
							{t("callToAction.networkPerformanceInsights")}
						</KeyFeature>
						<KeyFeature
							title={t("callToAction.spatialViewOfAssetsTitle")}
							image={SpatialViewImage}
						>
							{t("callToAction.spacialView")}
						</KeyFeature>
						<KeyFeature
							title={t("callToAction.impactInsightsTitle")}
							image={ImpactInsightsImage}
						>
							{t("callToAction.impactInsights")}
						</KeyFeature>
					</Box>
				</Grid>
				<Grid item md={6} padding={1}>
					<Box  >
						<KeyFeature
							title={t("callToAction.networkAssetInformationTitle")}
							image={AssetInformationImage}
						>
							{t("callToAction.networkAssetInformation")}
						</KeyFeature>
						<KeyFeature
							title={t("callToAction.endUserGraphConfigurationTitle")}
							image={GraphConfigImage}
						>
							{t("callToAction.graphConfiguration")}
						</KeyFeature>
						<Typography variant="body1">{t("callToAction.dontMissOut")}</Typography>
						<Typography variant="body1"><b>{t("callToAction.emailDomainWarning")}</b></Typography>
					</Box>
				</Grid>
				<Grid item md={4} textAlign={'center'} >
					<ImageListItem sx={{width: 410, height: 310}}>
							<img src="/map.png"  />
					</ImageListItem>
				</Grid>
				<Grid item md={4} textAlign={'center'}>
					<ImageListItem sx={{width: 420, height: 315}}>
							<img src="/graph.png" />
					</ImageListItem>
				</Grid>
				<Grid item md={4} textAlign={'center'}>
					<ImageListItem sx={{width: 440, height: 330}}>
					<img src="/stats.png" />	
					</ImageListItem>
				</Grid>
			</Grid>
		</Box>
	);
}