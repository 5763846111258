import { useContext } from "react";
import { useQuery } from "react-query";
import { ApiContext } from "../../../contexts/ApiContext";
import { QueryCallbacks } from "../../../utils/interfaces/ReactQuery";
import { LicenceArea } from "../interfaces/Region";

export function useLicenceAreaData(
    callBacks?: QueryCallbacks<LicenceArea[] | undefined>)
{
    const apiContext = useContext(ApiContext);
	return useQuery(
		[`LicenceAreaData`],
		async () => {
			    return await apiContext.geoFenceApis.getLicenceAreas();
		},
		{ 
            onSuccess: callBacks?.onSuccess,
			onError: callBacks?.onError,
			refetchOnWindowFocus: false
		}
	);
}