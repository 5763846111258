import { useContext } from "react";
import { useQuery } from "react-query";
import { ApiContext } from "../../../contexts/ApiContext";
import { QueryCallbacks } from "../../../utils/interfaces/ReactQuery";
import {
	CarbonIntensityAndGenerationMixData,
	CarbonIntensityAPIRegionId,
} from "../interfaces/CarbonIntensity";

export default function useCarbonIntensityAndGenerationMixAtLicenceAreaLevel(
	carbonIntensityAPIRegionId?: CarbonIntensityAPIRegionId,
	callbacks?: QueryCallbacks<CarbonIntensityAndGenerationMixData | undefined>
) {
	const apiContext = useContext(ApiContext);

	return useQuery<CarbonIntensityAndGenerationMixData | undefined, Response>(
		[
			"carbonIntensityAndGenerationMixAtLicenceAreaLevel",
			carbonIntensityAPIRegionId,
		],
		async () => {
			if (carbonIntensityAPIRegionId) {
				return await apiContext.carbonIntensityApis.getCarbonIntensityAndGenerationMixAtLicenceAreaLevel(
					carbonIntensityAPIRegionId
				);
			} else return undefined;
		},
		{
			onSuccess: callbacks?.onSuccess,
			onError: callbacks?.onError,
			refetchOnWindowFocus: false,
		}
	);
}
