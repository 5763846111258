import Typography, { TypographyProps } from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Stack, { StackProps } from '@mui/material/Stack';
import { AlertColor } from '@mui/material';
import MessageToast from '../Messages/MessageToast';

export interface DropdownLayoutProps {
	isLoading?: boolean;
	isError?: boolean;
	errorMessageSeverity?: AlertColor;
	errorMessage?: string;
	invalid?: boolean;
	invalidMessage?: string;
	labelText?: string;
	displayLabel?: boolean;
	labelPosition?: "top" | "left";
	labelTypographyProps?: TypographyProps;
	children?: React.ReactNode;
}

export default function DropdownLayout(props: DropdownLayoutProps) {
	const stackProps: StackProps = props.labelPosition === "left"
		? {
			direction: "row",
			alignItems: "center",
			spacing: 1
		}
		: {
			direction: "column",
			alignItems: "flex-start",
			spacing: 0.5
		}

	if (props.isLoading) {
		return <Skeleton>
			{props.children}
		</Skeleton>
	}
	else if (props.isError && props.errorMessage) {
		return <MessageToast text={props.errorMessage} severity={ props.errorMessageSeverity || "error" } />
	}

	else return <>
		<Stack {...stackProps}>
			{props.displayLabel && <Typography {...props.labelTypographyProps}>{props.labelText}</Typography>}

			<span>
				{props.children}
				{props.invalid
					&& props.invalidMessage
					&& <Typography variant="caption" display="block" gutterBottom className="p-error pl-1">
						{props.invalidMessage}
					</Typography>
				}
			</span>
		</Stack>

	</>
}