import { useCallback, useState } from 'react';

type UseStateParam<S> = S | (() => S);

/**
 * Hook which extends a standard useState hook to also persist state to the browser's localStorage.
 * @param key - Key to persist state against
 * @param defaultState - Default state or callback to get default state if it hasn't been persisted yet
 */
export function useLocalStorage<S>(key: string, defaultState: UseStateParam<S>) {
  const [state, setState] = useState(getFromLocalStorage(key, defaultState));

  const setLocalStorageState = useCallback((updated: S) => {
    setState(updated);
    saveToLocalStorage(key, updated);
  }, [key]);

  return [state, setLocalStorageState] as const;
}

function getFromLocalStorage<S>(key: string, defaultState: UseStateParam<S>): UseStateParam<S> {
  const savedState = localStorage.getItem(key);
  return savedState ? JSON.parse(savedState) : defaultState;
}

function saveToLocalStorage<S>(key: string, state: S) {
  if (state == null) {
    localStorage.removeItem(key);
  } else {
    const serialized = JSON.stringify(state);
    localStorage.setItem(key, serialized);
  }
}