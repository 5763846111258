import { styled } from "@mui/material/styles";
import MuiCard, { CardProps } from "@mui/material/Card";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import usePrimarySubstationWithStatistics from "../data/Substation/hooks/usePrimarySubstationWithStatistics";
import { t } from "i18next";
import { PRIMARY_SUBSTATION_GEOFENCE_STYLE } from "../SubstationOverview/Map/MapContainer";
import MessageToast from "../Shared/Messages/MessageToast";
import PrimarySubstationDetailCard from "./SubstationDetails/PrimarySubstationDetailCard";

interface PrimarySubstationCardProps {
	serialNumber?: number;
}
const CARD_WIDTH = 900;
const CARD_HEIGHT = 200;

const Card = styled(MuiCard)<CardProps>(({ theme }) => {
	return {
		width: CARD_WIDTH,
		maxHeight: CARD_HEIGHT,
		borderLeftWidth: theme.spacing(1),
		borderLeftStyle: "solid",
		borderLeftColor: PRIMARY_SUBSTATION_GEOFENCE_STYLE.color,
		padding: theme.spacing(1),
		cursor: "pointer",
		overflowY: "auto",
	};
});

const Title = styled(Typography)<TypographyProps>(({ theme }) => {
	return {
		padding: theme.spacing(1),
		borderLeftWidth: theme.spacing(1),
		borderLeftStyle: "solid",
		borderLeftColor: PRIMARY_SUBSTATION_GEOFENCE_STYLE.color,
		background: "#fff",
	};
});

export function PrimarySubstationCard(props: PrimarySubstationCardProps) {
	const { data: primarySubstationData, isLoading: dataLoading } =
		usePrimarySubstationWithStatistics(props.serialNumber);

	if (dataLoading)
		return (
			<Skeleton
				variant="rectangular"
				width={CARD_WIDTH}
				height={CARD_HEIGHT}
			/>
		);
	if (primarySubstationData)
		return (
			<>
				<Title variant="h6">
					<FontAwesomeIcon
						icon={faMap}
						style={{ marginRight: "5px" }}
					/>
					{t("substation.primarySubstationBoundary")}
				</Title>
				<Card variant="outlined">
					<Box
						display="flex"
						flexDirection="column"
						sx={{ width: 1, p: 0 }}
					>
						<PrimarySubstationDetailCard
							primarySubstation={primarySubstationData}
						></PrimarySubstationDetailCard>
					</Box>
				</Card>
			</>
		);
	else {
		return (
			<MessageToast
				text={t("commonErrorMessages.genericError", {
					ns: "translation",
				})}
				severity="error"
				sx={{ m: 2 }}
			/>
		);
	}
}
