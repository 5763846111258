import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { TransformerAsset } from "../data/Substation/interfaces/SubstationHierarchyReport";
import LabelText from "../Shared/LabelValueFormatters/LabelText";

interface TransformerDropdownProps {
	transformers: TransformerAsset[];
	selectedTransformer: TransformerAsset | undefined;
	onTransformerChange: (
		selectedTransformer: TransformerAsset | undefined
	) => void;
	showClear?: boolean;
}

export function TransformerDropdown(props: TransformerDropdownProps) {
	const { t } = useTranslation();
	const {
		transformers,
		onTransformerChange,
		selectedTransformer,
		showClear,
	} = props;

	const transformerOptions = useMemo(() => {
		return transformers.sort((a, b) =>
			a.name && b.name ? a.name.localeCompare(b.name) : -1
		);
	}, [transformers]);

	useEffect(() => {
		if (
			transformerOptions.length === 1 &&
			transformerOptions[0]?.id !== selectedTransformer?.id
		) {
			onTransformerChange(transformerOptions[0]);
		}
	}, [selectedTransformer, transformerOptions, onTransformerChange]);

	function handleChange(e: DropdownChangeParams) {
		const transformer = transformers.find((t) => t.id === e.value);
		onTransformerChange(transformer);
	}

	if (transformers.length === 1) {
		return null;
	} else
		return (
			<Box>
				<Stack
					direction="row"
					spacing={1}
					margin={0}
					alignItems="center"
				>
					<LabelText text={`${t("transformers.transformer")}:`} />
				</Stack>
				<Box display="flex" alignItems="center">
					<Dropdown
						options={transformerOptions}
						optionLabel="name"
						optionValue="id"
						placeholder={t("transformers.selectTransformer")}
						onChange={handleChange}
						value={selectedTransformer?.id}
						showClear={showClear}
					/>
				</Box>
			</Box>
		);
}
