import { Box, Skeleton, useTheme } from "@mui/material";
import { MapContainer, TileLayer, Popup, Tooltip, Marker } from "react-leaflet";
import { Map } from "leaflet";
import { LatLng, LatLngBoundsLiteral } from "leaflet";
import { useState, useRef, useEffect } from "react";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { getMarkerClusterIcon } from "./icons/SubstationMarkerClusterIcon";
import { SubstationMarker } from "./SubstationMarker";
import { SubstationCard } from "../../Substation/SubstationCard";
import { useMapLevelsAndBounds } from "../../utils/useMapLevelsandBounds";
import { NGEDSubstationAsset } from "../../data/Substation/interfaces/NGEDSubstationAsset";
import { useGeoFenceDataBySubstationNumber } from "../../data/GeoFence/hooks/useGeoFenceDataBySubstationNumber";
import { GeoFence } from "./GeoFence";
import LoadingOverlay from "../../Shared/LoadingOverlay/LoadingOverlay";
import { LicenceArea, County } from "../../data/GeoFence/interfaces/Region";
import { useGeoFenceDataById } from "../../data/GeoFence/hooks/useGeoFenceDataById";
import { getPostcodePinIcon } from "./icons/PostcodePinIcon";
import { SubstationCompare } from "../../Substation/SubstationCompare";
import { LicenceAreaCard } from "../../Shared/LicenceAreaCard";
import { GeoFenceLayer } from "./GeoFenceLayer";
import { PrimarySubstationCard } from "../../Substation/PrimarySubstationCard";

export interface SavedMapLevels {
	center: LatLng | [number, number];
	zoom?: number;
}

interface Props {
	substations: NGEDSubstationAsset[];
	postcodeMarker?: SavedMapLevels | undefined;
	licenceArea?: LicenceArea;
	isLoading?: boolean;
	county?: County;
	showPrimarySubstationBoundaries?: boolean;
}

export const DEFAULT_MAP_LEVELS: SavedMapLevels = {
	zoom: 5,
	center: [51.505, -0.09],
};

export const DEFAULT_BOUNDS: LatLngBoundsLiteral = [
	[46.6, -32.6],
	[55.8, 32.3],
];

export const MAX_BOUNDS: LatLngBoundsLiteral = [
	[48, -10],
	[62, 2.5],
];

export const PRIMARY_SUBSTATION_GEOFENCE_STYLE = {
	color: "#FFA500",
};

const DISTRIBUTION_SUBSTATION_GEOFENCE_STYLE = {
	color: "#00148C",
};

const REGION_GEOFENCE_STYLE = {
	color: "#00A300",
};

const COUNTY_GEOFENCE_STYLE = {
	color: "#f44336",
};

export function MapContainerComponent(props: Props) {
	const theme = useTheme();

	const { substations, licenceArea, isLoading, county, postcodeMarker } =
		props;

	const mapRef = useRef<Map | null>(null);
	const savedMapLevels = sessionStorage.getItem("map-levels");
	const [bounds, setBounds] = useState<number[][] | undefined>(undefined);
	const [mapLevels, setMapLevels] = useState<SavedMapLevels>(
		savedMapLevels ? JSON.parse(savedMapLevels) : DEFAULT_MAP_LEVELS
	);
	const [selectedSubstation, setSelectedSubstation] = useState<
		NGEDSubstationAsset | undefined
	>(undefined);

	const [selectedSubstationsForCompare, setSelectedSubstationsForCompare] =
		useState<NGEDSubstationAsset[]>([]);

	const primarySubstationGeoFenceData = useGeoFenceDataBySubstationNumber(
		selectedSubstation?.primaryNumber,
		props.showPrimarySubstationBoundaries
	);

	const selectedSubstationGeoFenceData = useGeoFenceDataBySubstationNumber(
		selectedSubstation?.serialNumber,
		true
	);

	const laGeoFenceData = useGeoFenceDataById(licenceArea?.geofenceId);
	const countyGeoFenceData = useGeoFenceDataById(county?.geofenceId);
	const mapLevelsAndBounds = useMapLevelsAndBounds(substations, isLoading);

	useEffect(() => {
		if (mapLevelsAndBounds?.bounds) {
			setBounds(mapLevelsAndBounds.bounds);
		}

		if (mapLevelsAndBounds?.mapLevels) {
			setMapLevels(mapLevelsAndBounds.mapLevels);
		}
	}, [mapLevelsAndBounds?.bounds, mapLevelsAndBounds?.mapLevels]);

	useEffect(() => {
		if (bounds && mapRef.current) {
			mapRef.current.fitBounds(bounds as LatLngBoundsLiteral);
		}
	}, [bounds]);

	useEffect(() => {
		if (!Boolean(postcodeMarker)) return;

		mapRef.current?.setView(postcodeMarker!.center, postcodeMarker!.zoom);
	}, [postcodeMarker]);

	useEffect(() => {
		if (props.licenceArea) {
			setSelectedSubstation(undefined);
		}
	}, [props.licenceArea]);

	const onSubstationSelectForCompare = (substation: NGEDSubstationAsset) => {
		let selectedSubstations = substations.filter((sub) => {
			return (
				sub.primaryNumber !== undefined &&
				sub.primaryNumber === substation.primaryNumber &&
				sub.serialNumber !== substation.serialNumber
			);
		});
		selectedSubstations?.sort((a, b) =>
			a.name && b.name ? a.name.localeCompare(b.name) : -1
		);

		setSelectedSubstationsForCompare([substation, ...selectedSubstations]);
	};

	if (props.isLoading)
		return <Skeleton variant="rectangular" height="100%" />;
	else
		return (
			<Box display="flex" flex="1">
				{primarySubstationGeoFenceData &&
					primarySubstationGeoFenceData.isLoading && (
						<LoadingOverlay />
					)}
				<MapContainer
					whenCreated={(mapInstance) => {
						mapRef.current = mapInstance;
					}}
					center={mapLevels.center}
					zoom={mapLevels.zoom}
					bounds={
						bounds ? (bounds as LatLngBoundsLiteral) : undefined
					}
					minZoom={6}
					maxBounds={MAX_BOUNDS}
					style={{ width: "100%" }}
				>
					<TileLayer
						attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
						url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
					/>
					{postcodeMarker && (
						<Marker
							position={postcodeMarker.center}
							icon={getPostcodePinIcon()}
						/>
					)}
					<MarkerClusterGroup
						iconCreateFunction={(cluster) =>
							getMarkerClusterIcon(cluster, theme)
						}
						showCoverageOnHover={false}
					>
						{props.substations
							.filter((substation) => substation.gpsCoords)
							.map((substation) => (
								<SubstationMarker
									eventHandlers={{
										click: (e) => {
											setSelectedSubstation(substation);
										},
									}}
									key={`substation-marker-${substation.id}`}
									theme={theme}
									substation={substation}
								>
									<Tooltip>{substation.name}</Tooltip>
									<Popup>
										<SubstationCard
											substation={substation}
											onSubstationSelectForCompare={
												onSubstationSelectForCompare
											}
										/>
									</Popup>
								</SubstationMarker>
							))}
					</MarkerClusterGroup>
					{props.showPrimarySubstationBoundaries &&
						primarySubstationGeoFenceData &&
						primarySubstationGeoFenceData.data && (
							<GeoFenceLayer
								geoFence={
									primarySubstationGeoFenceData.data.geoJson
								}
								geoFenceName={selectedSubstation?.primaryName}
								style={PRIMARY_SUBSTATION_GEOFENCE_STYLE}
							>
								<Popup>
									<PrimarySubstationCard
										serialNumber={
											selectedSubstation?.primaryNumber
										}
									/>
								</Popup>
							</GeoFenceLayer>
						)}
					{selectedSubstationGeoFenceData.data && (
						<GeoFence
							geoFence={
								selectedSubstationGeoFenceData.data.geoJson
							}
							geoFenceName={selectedSubstation?.name}
							style={DISTRIBUTION_SUBSTATION_GEOFENCE_STYLE}
						/>
					)}
					{laGeoFenceData && laGeoFenceData.data && (
						<GeoFenceLayer
							geoFence={laGeoFenceData.data.geoJson}
							geoFenceName={props.licenceArea?.name}
							style={REGION_GEOFENCE_STYLE}
						>
							<Popup>
								<LicenceAreaCard
									name={props.licenceArea?.name}
								/>
							</Popup>
						</GeoFenceLayer>
					)}
					{countyGeoFenceData && countyGeoFenceData.data && (
						<GeoFence
							geoFence={countyGeoFenceData.data.geoJson}
							geoFenceName={props.county?.name}
							style={COUNTY_GEOFENCE_STYLE}
						/>
					)}
					{selectedSubstationsForCompare.length > 0 && (
						<SubstationCompare
							substations={selectedSubstationsForCompare}
						/>
					)}
				</MapContainer>
			</Box>
		);
}
