import React from "react";
import config from "../../../configuration";
import { ApiHandlerPureComponent, handleResponseArray } from "../../utils";
import { ExtendedSubstationHierarchy } from "./interfaces/ExtendedSubstationHierarchy";
import { NGEDSubstationAsset } from "./interfaces/NGEDSubstationAsset";

interface Props {
	onAuthError?: () => void;
}

export default class SubstationAPIs extends React.PureComponent<Props> {
	private apiResponseHandler = new ApiHandlerPureComponent({ onAuthError: this.props.onAuthError });

	private static readonly BASE_URL: string = `${config.apiGateway.NGED_META_API}/api/LvSubstations/`;

	getSubstationList = async (): Promise<NGEDSubstationAsset[]> => {
		return this.apiResponseHandler
			.get(`${SubstationAPIs.BASE_URL}monitored`)
			.then((data) => handleResponseArray(data));
	};

	getSubstationById = async (
		substationId: number | string
	): Promise<ExtendedSubstationHierarchy | null> => {
		const apiAddress = `${SubstationAPIs.BASE_URL}byId/${substationId}`;
		return await this.apiResponseHandler.get(apiAddress);
	};

	getSubstationsByPrimaryNumber = async (
		primaryNumber: number
	): Promise<NGEDSubstationAsset[] | null> => {
		const apiAddress = `${SubstationAPIs.BASE_URL}byPrimarySubstationNumber/${primaryNumber}`;
		return this.apiResponseHandler
			.get(apiAddress)
			.then((data) => handleResponseArray(data));
	};
}
