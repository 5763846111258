import { useContext } from "react";
import { ApiContext } from "../../../contexts/ApiContext";
import { ExtendedSubstationHierarchy } from "../../Substation/interfaces/ExtendedSubstationHierarchy";

import { useQuery } from "react-query";
import { QueryCallbacks } from "../../../utils/interfaces/ReactQuery";

export default function useExtendedSubstationHierarchyReportBySubstation(
	serialNumber?: string | number,
	callbacks?: QueryCallbacks<ExtendedSubstationHierarchy | undefined>
) {
	const apiContext = useContext(ApiContext);

	return useQuery<ExtendedSubstationHierarchy | undefined, Response>(
		[`extendedSubstationHierarchyReport-${serialNumber}`],
		async () => {
			if (serialNumber) {
				return await apiContext.assetApis.getExtendedSubstationHierarchyReportBySubstation(
					serialNumber
				);
			} else return undefined;
		},
		{
			onSuccess: callbacks?.onSuccess,
			onError: callbacks?.onError,
			refetchOnWindowFocus: false,
		}
	);
}
