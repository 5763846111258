import Grid, { GridSize } from '@mui/material/Grid';

import LabelText from './LabelText';
import ValueText from './ValueText';
import EmptyValuePlaceholder from './EmptyValuePlaceholder';

export interface SingleRowLabelValueProps {
	label: string;
	value: string | number;

	labelGridSize?: {
		xs?: GridSize;
		sm?: GridSize;
		md?: GridSize;
		lg?: GridSize;
		xl?: GridSize;
	}
}

export default  function SingleRowLabelValue(props: SingleRowLabelValueProps)
{
    return <Grid container alignItems="baseline" sx={{ display: 'flex'}}>
		<Grid item xs={props.labelGridSize?.xs || 7} >
			<LabelText text={props.label} />
		</Grid>
		<Grid item xs={props.labelGridSize?.xs ||5} >
			{!props.value && <EmptyValuePlaceholder />}
			{props.value && <ValueText style={{overflowWrap: 'break-word'}} text={props.value} />
			}
		</Grid>
	</Grid>
   
}