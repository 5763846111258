import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { InputText } from "primereact/inputtext";

interface Props {
	initalValue?: string;
	onSelected: (value: string) => void;
	error?: { title?: string; detail: string };
	onCloseError: () => void;
	fetching?: boolean;
}

export default function PostcodeLookupFilter({
	initalValue,
	onSelected,
	error,
	onCloseError,
	fetching,
}: Props) {
	const theme = useTheme();
	const formRef = useRef<HTMLFormElement>(null);
	const { t } = useTranslation();
	const popoverAnchorRef = React.useRef<HTMLInputElement>(null);

	const [inputValue, setInputValue] = useState<string>(initalValue ?? "");

	const handlePopoverClose = () => onCloseError();

	const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (
		event
	) => {
		const value = event.target.value;
		setInputValue(value);
		error && onCloseError();
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		onSelected(inputValue);
	};

	useEffect(() => {
		setInputValue(initalValue ?? "");
	}, [initalValue]);

	const open = Boolean(error);

	return (
		<div style={{ position: "relative" }}>
			<form className="" ref={formRef} onSubmit={handleSubmit}>
				<span className={"p-input-icon-right"}>
					{fetching && <i className="pi pi-spin pi-spinner" />}
					{!fetching && (
						<i
							className="pi pi-search"
							style={{ cursor: "pointer" }}
							onClick={() => {
								formRef.current?.requestSubmit();
							}}
						/>
					)}
					<InputText
						className={open ? "p-invalid" : ""}
						placeholder={t("substation.postcodeLookupPlaceholder")}
						value={inputValue || ""}
						onChange={handleInputChange}
						ref={popoverAnchorRef}
					/>
				</span>
			</form>
			<Popover
				open={open}
				disableAutoFocus
				anchorEl={popoverAnchorRef.current}
				onClose={handlePopoverClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Box
					sx={{
						p: 2,
						pb: 0,
						color: theme.palette.warning.contrastText,
						background: theme.palette.warning.light,
						maxWidth: "sm",
					}}
				>
					{error?.title && (
						<Typography
							variant="h6"
							sx={{
								pb: 2,
							}}
						>
							{error?.title}
						</Typography>
					)}
					{(error?.detail || "")?.split("\n").map((text, idx) => (
						<Typography
							key={`message-${idx}`}
							variant="body2"
							sx={{
								pb: 2,
							}}
							dangerouslySetInnerHTML={{ __html: text }}
						></Typography>
					))}
				</Box>
			</Popover>
		</div>
	);
}
