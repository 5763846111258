import { Typography, Box, Stack, Grid, useTheme, Divider } from "@mui/material";
import NetworkInsightsImage from "../images/about/kf-network-insights.png";
import AssetInformationImage from "../images/about/kf-asset-information.png";
import GraphConfigImage from "../images/about/kf-graph-config.png";
import ImpactInsightsImage from "../images/about/kf-impact-insights.svg";
import SpatialViewImage from "../images/about/kf-spatial-view.png";
import { KeyFeature } from "./KeyFeature";

export default function AboutPage() {
	const theme = useTheme();
	return (
		<Box margin={2}>
			<Grid container spacing={2}>
				<Grid item md={6}>
					<Box bgcolor={"#efefef"} padding={2}>
						<p>
							National Grid Electricity Distribution (NGED),
							formerly known as Western Power Distribution, is the
							regional electricity distribution division of
							National Grid and the UK's largest electricity
							distribution network – delivering essential power to
							8 million customers in the East and West Midlands,
							South West and Wales.
						</p>
						<p>
							The Low Voltage (LV) Network Insights Platform is an
							open data interface displaying data from monitoring
							units installed on distribution substations across
							NGED's electricity network – to provide local
							communities and the wider public with insights into
							network usage and capacity, low carbon technology
							connections and carbon intensity. The aim is to
							display data in a visual and user-friendly way, to
							inform decision making and help users understand
							their electricity usage and needs, as well as
							provide:
						</p>
						<ul>
							<li>
								<b>Transparency</b> – Everyone can access Open
								LV data to identify opportunities, access,
								evaluate energy demand and planning.
							</li>
							<li>
								<b>Convenience</b> – Single 'go-to' system and
								platform to find and retrieve the information
								users need.
							</li>
							<li>
								<b>Flexibility</b> – Enabling users to create
								their own graphs and charts to drill down into
								specific focus areas.
							</li>
						</ul>
						<Typography
							variant="h5"
							color={theme.palette.primary.main}
						>
							Data included in this platform
						</Typography>
						<p>
							This platform uses data from the following sources:
						</p>
						<ul>
							<li>
								Electricity Network Substation asset data (for{" "}
								<a href="https://connecteddata.nationalgrid.co.uk/dataset/primary-substation-location-easting-northings/resource/ea37e4d2-84e8-45fe-a21b-3921080f864d">
									primary
								</a>{" "}
								and{" "}
								<a href="https://connecteddata.nationalgrid.co.uk/dataset/distribution-substations">
									distribution
								</a>{" "}
								substations) and{" "}
								<a href="https://connecteddata.nationalgrid.co.uk/dataset/lv-load-monitor-data">
									LV network monitoring data
								</a>{" "}
								from NGED's Connected Data Portal.
							</li>
							<li>
								Carbon Intensity data publicly available{" "}
								<a href="https://carbonintensity.org.uk/">
									here
								</a>
								.
							</li>
						</ul>

						<Typography
							variant="h5"
							color={theme.palette.primary.main}
							marginTop={2}
						>
							Connected Data Portal
						</Typography>

						<p>
							The Connected Data Portal also contains the
							following resources:
						</p>
						<ul>
							<li>
								<a href="https://connecteddata.nationalgrid.co.uk/dataset/embedded-capacity-register">
									Embedded Capacity Register
								</a>
							</li>
							<li>
								<a href="https://connecteddata.nationalgrid.co.uk/dataset/generation-capacity-register">
									Generation Capacity Register
								</a>
							</li>
							<li>
								<a href="https://connecteddata.nationalgrid.co.uk/dataset/new-connections">
									Long Term Development New Connections
								</a>
							</li>

							<li>
								<a href="https://connecteddata.nationalgrid.co.uk/dataset/ev-capacity">
									EV Capacity
								</a>
							</li>
							<li>
								<a href="https://connecteddata.nationalgrid.co.uk/dataset/?q=primary+transformer&sort=score+desc%2C+metadata_modified+desc">
									Historic Primary Substation Loads
								</a>
							</li>
							<li>
								<a href="https://connecteddata.nationalgrid.co.uk/dataset/smart-meter-volumes">
									Smart Meters
								</a>
							</li>
						</ul>
						<Typography variant="subtitle1">
							For any feedback or support please email us at &#20;
							<a href="mailto:nged.data@nationalgrid.co.uk">
								nged.data@nationalgrid.co.uk
							</a>
						</Typography>
						<Typography
							variant="h5"
							color={theme.palette.primary.main}
							marginTop={2}
						>
							Disclaimer
						</Typography>
						<p>
							Open data licence information is available{" "}
							<a href="https://www.nationalgrid.co.uk/open-data-licence">
								here
							</a>
						</p>
						<p>
							Privacy Policy is available{" "}
							<a href="https://www.nationalgrid.co.uk/privacy-policy" target="_blank" rel="noopener noreferrer">
								here
							</a>
						</p>
					</Box>
				</Grid>
				<Grid item md={6}>
					<Box>
						<Typography
							variant="h4"
							color={theme.palette.primary.main}
							marginBottom={4}
						>
							Key Features of the Platform
						</Typography>
						<KeyFeature
							title="Network Performance Insights"
							image={NetworkInsightsImage}
						>
							Time series data from LV monitors, at a substation
							and feeder level. This includes half hourly voltage,
							power and current readings per phase.
						</KeyFeature>
						<KeyFeature
							title="Spatial View of Assets"
							image={SpatialViewImage}
						>
							Map and polygon view of Low Carbon Technologies
							(LCT), primary and distribution substations with
							ability to search by postcode.
						</KeyFeature>
						<KeyFeature
							title="Impact Insights"
							image={ImpactInsightsImage}
						>
							Carbon intensity data (CO2e) to provide insights
							into network environmental impact.
						</KeyFeature>
						<KeyFeature
							title="Network Asset Information"
							image={AssetInformationImage}
						>
							Static network asset information at a primary and
							distribution substation level. This includes
							location and number of customers and LCTs connected.
						</KeyFeature>
						<KeyFeature
							title="End User Graph Configuration"
							image={GraphConfigImage}
						>
							Enabling users to create their own graphs and
							charts, including for a group of substations in a
							similar locality over a specified time period.
						</KeyFeature>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}
