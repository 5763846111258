import { Stack, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StaticDataGroups } from "../enums/StaticDataGroups";
import FilterChip from "./FilterChip";

interface Props {
	onChipChange: (staticDataGroup: StaticDataGroups) => void;
	selectedGroup: StaticDataGroups;
}

export function SubstationStaticDataSelectionChips(props: Props) {
	const { t } = useTranslation();
	const { onChipChange, selectedGroup } = props;

	return (
		<>
			<Box>
				<Stack direction="row" spacing={1}>
					{Object.values(StaticDataGroups).map((staticDataGroup) => (
						<FilterChip
							key={`filterChip-${staticDataGroup}`}
							isSelected={staticDataGroup === selectedGroup}
							label={t(`substation.${staticDataGroup}`)}
							onClick={() => {
								onChipChange(staticDataGroup);
							}}
						/>
					))}
				</Stack>
			</Box>
		</>
	);
}
