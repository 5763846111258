import { useMutation } from "react-query";
import { QueryCallbacks } from "../../../utils/interfaces/ReactQuery";
import { useContext } from "react";
import { ApiContext } from "../../../contexts/ApiContext";
import { UserConfirmationRequest } from "../../../Shared/AccountConfirmation/UserConfirmationRequest";

export default function useAccountConfirmation(
	callbacks?: QueryCallbacks<void | undefined>
) {
	const apiContext = useContext(ApiContext);
	return useMutation(
        [`emailConfirmation`],
        async (request: UserConfirmationRequest) => apiContext.accountAPIs.confirmUserAccount(request),
        {
            onSuccess: callbacks?.onSuccess,
            onError: callbacks?.onError,
        }
    );
}