import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import DropdownLayout, {
	DropdownLayoutProps,
} from "../Shared/DropdownLayout/DropdownLayout";
import { County } from "../data/GeoFence/interfaces/Region";
import { useCountyData } from "../data/GeoFence/hooks/useCountyData";

interface Props extends DropdownLayoutProps {
	onCountyChange: (county: County | undefined) => void;
	selectedCounty?: County;
	initialCounty?: string;
	populateAttributes?: boolean;
	selectedParentRegionId?: number;
}

export default function CountyDropdown(props: Props) {
	const { t } = useTranslation(["translation"]);

	const {
		selectedCounty,
		onCountyChange,
		initialCounty,
		selectedParentRegionId,
		...rest
	} = props;

	const {
		data: countiesData,
		isLoading: countyDataIsLoading,
		isError: countyDataIsError,
		error: countyDataError,
	} = useCountyData();

	const value =
		initialCounty && !selectedCounty
			? countiesData?.find((county) => county.name === initialCounty)
					?.name
			: countiesData?.find(
					(county) => county.name === selectedCounty?.name
			  )?.name;

	const preselectedCounty = countiesData?.find(
		(county) => county.name === initialCounty
	);

	if (initialCounty && !selectedCounty && preselectedCounty) {
		onCountyChange(preselectedCounty);
	}

	function handleChange(e: DropdownChangeParams) {
		if (!e.value) {
			onCountyChange(undefined);
		}
		const county = countiesData?.find((county) => county.name === e.value);
		county && onCountyChange(county);
	}

	const filteredCounties = countiesData?.filter(
		(county) =>
			selectedParentRegionId === undefined ||
			county.parentRegions.some(
				(parent) => parent.id == selectedParentRegionId
			)
	);

	return (
		<DropdownLayout
			isLoading={countyDataIsLoading}
			isError={countyDataIsError}
			errorMessageSeverity="error"
			errorMessage={`${t("commonErrorMessages.dataFetchError", {
				ns: "translation",
				val: t("substation.counties"),
			})}: ${countyDataError}`}
			labelText={t("substation.County")}
			{...rest}
		>
			<Dropdown
				className={clsx({ "p-invalid": props.invalid })}
				disabled={!countiesData}
				value={value}
				optionValue="name"
				options={filteredCounties?.sort((a, b) =>
					a.name > b.name ? 1 : -1
				)}
				onChange={handleChange}
				optionLabel="name"
				filter
				filterBy="name"
				showFilterClear
				emptyMessage={t("substation.nocounties")}
				showClear
				placeholder={t("common.select", {
					item: "$t(substation.county)",
				})}
				{...rest}
			/>
		</DropdownLayout>
	);
}
