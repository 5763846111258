import { useMutation } from "react-query";
import { QueryCallbacks } from "../../../utils/interfaces/ReactQuery";
import { useContext } from "react";
import { ApiContext } from "../../../contexts/ApiContext";
import { PasswordResetRequest } from "../../../Shared/PasswordReset/PasswordResetRequest";

export default function usePasswordReset(
	callbacks?: QueryCallbacks<void | undefined>
) {
	const apiContext = useContext(ApiContext);
	return useMutation(
        [`passwordReset`],
        async (request: PasswordResetRequest) => apiContext.accountAPIs.resetPassword(request),
        {
            onSuccess: callbacks?.onSuccess,
            onError: callbacks?.onError,
        }
    );
}