export function passwordDoesNotContainUppercase(password: string){
    return !/[A-Z]/.test(password);
}

export function passwordDoesNotContainLowercase(password: string){
    return !/[a-z]/.test(password);
}

export function passwordDoesNotContainSymbols(password: string){
    return !/[^a-zA-Z0-9]/.test(password);
}

export function passwordDoesNotContainDigits(password: string){
    return !/\d/.test(password);
}
