import {
	MarkerCluster,
	DivIcon as LeafletDivIcon,
	point
} from 'leaflet';
import { Theme } from '@mui/material/styles';
import { SubstationLeafletMarker } from '../SubstationMarker';
import { Severity } from '../../../enums/Severity';

export interface MarkerCounts {
	total: number;
	red: number;
	amber: number;
	green: number;
	none: number;
}

export function getMarkerClusterIcon(cluster: MarkerCluster, theme: Theme) {
	const childMarkers = cluster.getAllChildMarkers();
	const markerCounts: MarkerCounts = getMarkerCounts(cluster, childMarkers as SubstationLeafletMarker[]); 
	const assetCluster = getIcon(markerCounts, theme);

	return new LeafletDivIcon({
		html: assetCluster.html,
		className: 'svg-marker',
		iconSize: point(assetCluster.radius * 1.2, assetCluster.radius * 1.2),
		popupAnchor: [0, -25]
	});
}

function getMarkerCounts(cluster: MarkerCluster, childMarkers: SubstationLeafletMarker[]): MarkerCounts {

	const initialValue: MarkerCounts = {
		total: 0,
		red: 0,
		amber: 0,
		green: 0,
		none: 0
	}

	return childMarkers.reduce((accumulator: MarkerCounts, marker) => {

		switch (marker.severity) {
			case Severity.Green:
				accumulator.green++;
				break;
			case Severity.Amber:
				accumulator.amber++;
				break;
			case Severity.Red:
				accumulator.red++;
				break;
			case Severity.Grey:
				accumulator.none++;
				break;
		}

		accumulator.total++;

		return accumulator;
	}, initialValue);

}

function getIcon(markerCounts: MarkerCounts, theme: Theme) {
	let circumference: number;
	let cxy: number;
	let tx: number;
	let ty: number;
	let donutWidth: number = 10;
	let viewBoxX: number;
	let viewBoxY: number;

	//set cluster marker size based on total number of assets in cluster
	if (markerCounts.total < 100) {
		circumference = 160;
	}
	else if (markerCounts.total < 1000) {
		circumference = 220;
	}
	else if (markerCounts.total < 10000) {
		circumference = 320;
	}
	else if (markerCounts.total < 100000) {
		circumference = 420;
	}
	else {
		circumference = 420;
	}

	const percentBase = circumference / markerCounts.total;
	const redPercent = Math.round(percentBase * markerCounts.red);
	const amberPercent = Math.round(percentBase * markerCounts.amber);
	const greenPercent = Math.round(percentBase * markerCounts.green);
	const nonePercent = circumference - (redPercent + amberPercent + greenPercent);

	const redRemainder = circumference - redPercent;
	const amberRemainder = circumference - amberPercent;
	const greenRemainder = circumference - greenPercent;
	const noneRemainder = circumference - nonePercent;

	const redDashOffset = 0;
	const amberDashOffset = circumference - (redPercent) + redDashOffset;
	const greenDashOffset = circumference - (redPercent + amberPercent) + redDashOffset;
	const noneDashOffset = circumference - (redPercent + amberPercent + greenPercent) + redDashOffset;

	//r=C / (pi * 2)
	const radius = circumference / (Math.PI * 2);
	const diameter = radius * 2;
	viewBoxX = diameter + donutWidth;
	viewBoxY = diameter + donutWidth;
	cxy = radius + (donutWidth / 2);
	tx = cxy;
	ty = cxy + donutWidth;

	return {
		radius: radius,
		html: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 ${viewBoxX} ${viewBoxY}" style="enable-background:new 0 0 ${viewBoxX} ${viewBoxY};" xml:space="preserve">
  <circle cx="${cxy}" cy="${cxy}" r="${radius}" fill="#fff"></circle>
  <circle cx="${cxy}" cy="${cxy}" r="${radius}" fill="transparent" stroke="${theme.palette.redStatus.main}" stroke-width="${donutWidth}" stroke-dasharray="${redPercent} ${redRemainder}" stroke-dashoffset="${redDashOffset}"></circle>
  <circle cx="${cxy}" cy="${cxy}" r="${radius}" fill="transparent" stroke="${theme.palette.amberStatus.main}" stroke-width="${donutWidth}" stroke-dasharray="${amberPercent} ${amberRemainder}" stroke-dashoffset="${amberDashOffset}"></circle>
  <circle cx="${cxy}" cy="${cxy}" r="${radius}" fill="transparent" stroke="${theme.palette.greenStatus.main}" stroke-width="${donutWidth}" stroke-dasharray="${greenPercent} ${greenRemainder}" stroke-dashoffset="${greenDashOffset}"></circle>
  <circle cx="${cxy}" cy="${cxy}" r="${radius}" fill="transparent" stroke="${theme.palette.noneStatus.main}" stroke-width="${donutWidth}" stroke-dasharray="${nonePercent} ${noneRemainder}" stroke-dashoffset="${noneDashOffset}"></circle>
	<text x="${tx}" y="${ty}" style="fill:#4B4A49; font-family:'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
		Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'; text-anchor: middle; font-size:24px; font-weight: bold">${markerCounts.total}</text>
</svg>`};
};