import { useMemo } from "react";
import { FeederAsset } from "../data/Substation/interfaces/SubstationHierarchyReport";

export function useFeedersSortedByNameDesc(asset: FeederAsset[] | undefined) {
	return useMemo(() => {
		return asset?.sort((a, b) =>
			a.name && b.name ? b.name.localeCompare(a.name) : -1
		);
	}, [asset]);
}

export function useFeedersSortedByNameAsc(asset: FeederAsset[] | undefined) {
	return useMemo(() => {
		return asset?.sort((a, b) =>
			a.name && b.name ? a.name.localeCompare(b.name) : -1
		);
	}, [asset]);
}
