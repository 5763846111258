import { DateTime, Interval } from "luxon";

/**
 * Function that can be used to convert a date string (assumed to be in UTC) to a Date with the local time.
 * @param date Input date string (assumed to be in UTC)
 * @returns The JS Date converted to the local time
 */
export function convertUtcToLocalDateAndTime(date: string): Date {
	const utcDateAndTime = DateTime.fromISO(date, { zone: "utc" });
	return utcDateAndTime.setZone(DateTime.local().zoneName).toJSDate();
}

export function convertSecondsToMilliseconds(seconds: number) {
	return seconds * 1000;
}
export const getLuxonDateObject = (
	year: number,
	month: number,
	day: number,
	time?: string
) => {
	return DateTime.fromObject({
		year,
		month,
		day,
		hour: parseInt(time?.substring(0, 2) || "0"),
		minute: parseInt(time?.substring(3, 5) || "0"),
	});
};

export function getDayRange(endDate: Date, startDate: Date) {
	const diff = Interval.fromDateTimes(
		DateTime.fromJSDate(startDate),
		DateTime.fromJSDate(endDate)
	);
	return diff.length("days");
}
