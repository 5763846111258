import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import LabelText from "../Shared/LabelValueFormatters/LabelText";
import { NGEDSubstationAsset } from "../data/Substation/interfaces/NGEDSubstationAsset";
import { FeederAsset } from "../data/Substation/interfaces/SubstationHierarchyReport";
import { useFeedersSortedByNameAsc } from "../utils/useSortedFeedersByName";
import { useSubstationsSortedByNameAsc } from "../utils/useSortedSubstationByName";

interface SubstationFeederDropdownProps {
	substations: NGEDSubstationAsset[];
	selectedSubstation: NGEDSubstationAsset | undefined;
	onSubstationChange: (
		selectedSubstation: NGEDSubstationAsset | undefined
	) => void;
	showClear?: boolean;
	feeders?: FeederAsset[];
	onFeederChange?: (selectedFeeder: FeederAsset | undefined) => void;
	selectedFeeder?: FeederAsset | undefined;
	showFeederDrodown?: boolean;
}

export function SubstationFeederDropdown(props: SubstationFeederDropdownProps) {
	const { t } = useTranslation();
	const {
		substations,
		onSubstationChange,
		selectedSubstation,
		showClear,
		feeders,
		onFeederChange,
		selectedFeeder,
		showFeederDrodown,
	} = props;

	const substationOptions: NGEDSubstationAsset[] =
		useSubstationsSortedByNameAsc(substations);

	const feederOptions = useFeedersSortedByNameAsc(feeders);

	useEffect(() => {
		if (
			feederOptions &&
			feederOptions.length === 1 &&
			feederOptions[0]?.id !== selectedFeeder?.id &&
			onFeederChange
		) {
			onFeederChange(feederOptions[0]);
		}
	}, [selectedFeeder, feederOptions, onFeederChange]);

	function handleSubstationChange(e: DropdownChangeParams) {
		const substation = substations.find((t) => t.id === e.value);
		onSubstationChange(substation);
	}

	function handleFeederChange(e: DropdownChangeParams) {
		if (feeders) {
			const feeder = feeders.find((t) => t.id === e.value);
			if (onFeederChange) onFeederChange(feeder);
		}
	}

	if (substations.length === 0) {
		return null;
	} else
		return (
			<Box>
				<Stack
					direction="row"
					spacing={1}
					margin={0}
					alignItems="center"
				>
					<LabelText
						text={`${t(
							"substation.compareWithSimilarSubstations"
						)}`}
						message={t(
							"definitions.compareSubstationsWithSimilarPrimary"
						)}
					/>
				</Stack>
				<Box display="flex" alignItems="center">
					<Dropdown
						options={substationOptions}
						optionLabel="name"
						optionValue="id"
						placeholder={t("substation.selectSubstation")}
						onChange={handleSubstationChange}
						value={selectedSubstation?.id}
						showClear={showClear}
					/>
					{selectedSubstation && showFeederDrodown && (
						<Dropdown
							style={{ marginLeft: "2px" }}
							options={feederOptions}
							optionLabel="name"
							optionValue="id"
							placeholder={t("feeders.selectFeeder")}
							onChange={handleFeederChange}
							value={selectedFeeder?.id}
							showClear={showClear}
						/>
					)}
				</Box>
			</Box>
		);
}
