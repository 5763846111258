import { styled, useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import logo from "../images/National_Grid_Logo_White.png";
import { useEffect, useState } from "react";
import { Box, Button, IconButton, Tooltip } from "@mui/material";

const Logo = styled("img")({
	height: "70px",
	width: "200px",
	borderRight: "1px solid white",
	marginRight: "10px",
});

interface Props{
	onLogoutButtonClick: () => void;
	pageRequiresAuthentication : boolean;
	onCallToActionPage: boolean;
	onSignInButtonClick: () => void;
	onRegisterButtonClick: () => void;
}

export default function Header(props: Props) {
	const { t } = useTranslation();
	const theme = useTheme();
	const { pathname } = useLocation();
	const [headerText, setHeaderText] = useState<string>("");

	useEffect(() => {
		if (pathname != "") {
			if (pathname === "/about")
				setHeaderText(t("common.aboutThePlatform"));
			else if (pathname.includes("/substation/"))
				setHeaderText(t("common.substationDetails"));
			else if (pathname === "/") setHeaderText(t("common.aboutThePlatform"));
			else if (pathname.includes("/map")) setHeaderText(t("common.mapViewTitle"));
			else if (pathname.includes("/confirmemail")) setHeaderText(t("login.userConfirmation"));
			else if (pathname.includes("/resetpassword")) setHeaderText(t("login.passwordResetHeader"));
		}
	}, [pathname]);

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar
				elevation={0}
				sx={{
					backgroundColor: theme.palette.mainInstance.main,
					zIndex: theme.zIndex.drawer + 1,
				}}
			>
				<Toolbar>
					<Logo alt="National Grid" src={logo} sx={{ mr: 2 }}/>
					<Typography
						sx={{
							color: theme.palette.mainInstance.contrastText,
							fontFamily: "Roboto Light, sans-serif",
							fontSize: "2em",
							fontWeight: 300,
							marginBottom: "8px",
							flexGrow: 1
						}}
					>
						{t("projectTitle")} - {headerText}
					</Typography>
					{props.pageRequiresAuthentication  && <Tooltip title={t("login.logout")}>
						<IconButton
							onClick={props.onLogoutButtonClick}
							size="small"
							sx={{ color: "white" }}
						>
							<LogoutIcon />
						</IconButton>
					</Tooltip>}
					{props.onCallToActionPage && <Tooltip title={t("login.register")}>
						<Button
							onClick={props.onRegisterButtonClick}
							size="small"
							sx={{ color: "white" }}
						>
							{t("login.register")}
						</Button>
					</Tooltip>}
					{props.onCallToActionPage && <Tooltip title={t("login.signIn")}>
						<Button
							onClick={props.onSignInButtonClick}
							size="small"
							sx={{ color: "white" }}
						>
							{t("login.signIn")}
						</Button>
					</Tooltip>}
				</Toolbar>
			</AppBar>
		</Box>
	);
}
