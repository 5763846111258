import { useTranslation } from "react-i18next";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTheme } from "@mui/material";

import { useHighchartsTheme } from "./charts/useHighchartsTheme";
import { GenerationMix } from "../data/CarbonIntensity/interfaces/CarbonIntensity";
import { upperFirst } from "lodash";

interface GenerationMixPieProps {
	licenceAreaName?: string;
	generationMixData: GenerationMix[];
}

export function GenerationMixPie(props: GenerationMixPieProps) {
	const { licenceAreaName, generationMixData } = props;
	const { t } = useTranslation();
	const theme = useTheme();
	const { highchartsTheme } = useHighchartsTheme();
	Highcharts.setOptions(highchartsTheme);

	const pieChartOptions: Highcharts.Options = {
		chart: {
			plotShadow: false,
			type: "pie",
			backgroundColor: theme.palette.background.paper,
			height: 250,
			style: {
				"font-family": "Roboto",
				color: theme.palette.text.secondary,
			},
		},
		title: {
			text: t("generationMix.generationMix", {
				value: licenceAreaName ?? "",
			}),
			align: "center",
			style: {
				color: theme.palette.text.secondary,
				fontSize: "16px",
			},
		},
		tooltip: {
			pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
		},
		accessibility: {
			point: {
				valueSuffix: "%",
			},
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: "pointer",
				dataLabels: {
					enabled: true,
					format: "<b>{point.name}</b>: {point.percentage:.1f} %",
				},
				size: 160,
			},
		},
		credits: {
			enabled: false,
		},
		exporting: {
			enabled: false,
		},
		series: [
			{
				name: t("generationMix.percentage"),
				colorByPoint: true,
				type: "pie",
				data: generationMixData
					.map((g) => {
						return {
							name: g.fuel && upperFirst(g.fuel),
							y: g.perc,
						};
					})
					.sort((a, b) =>
						a.name && b.name ? a.name.localeCompare(b.name) : -1
					),
			},
		],
	};

	return (
		<>
			<HighchartsReact
				highcharts={Highcharts}
				options={pieChartOptions}
				constructorType={"chart"}
				containerProps={{ style: { height: "100%" } }}
			/>
		</>
	);
}
