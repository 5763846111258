import { DataTable } from "primereact/datatable";
import { Column, ColumnBodyOptions } from "primereact/column";
import { useTranslation } from "react-i18next";
import { StaticDataGroups } from "../enums/StaticDataGroups";
import { useMemo } from "react";
import {
	SUBSTATION_STATIC_DATA_AGGREGATES,
	colProps,
	GENERAL_SUBSTATION_DETAILS_COLS,
	LCT_COLS,
	GENERATION_COLS,
	GENERATION_AND_STORAGE,
	NETWORK_CAPACITY_COLS,
} from "./SubstationCompareGridCols";
import { getAggregateData } from "../utils/helpers/AggregateDataHelper";
import { NGEDSubstationAsset } from "../data/Substation/interfaces/NGEDSubstationAsset";

interface SubstationCompareGridProps {
	substations: NGEDSubstationAsset[];
	selectedGroup: StaticDataGroups;
}
export function SubstationCompareGrid(props: SubstationCompareGridProps) {
	const { t } = useTranslation();

	const generateValuesWithUnit = (
		colData: any,
		options: ColumnBodyOptions
	) => {
		const data = colData[options.field];
		return (
			<span>{`${data != null ? `${data}` : `0 `} ${t(
				"units.short.kw"
			)}`}</span>
		);
	};

	const genearteAggregateValues = (
		colData: any,
		options: ColumnBodyOptions
	) => {
		type NameType = keyof typeof SUBSTATION_STATIC_DATA_AGGREGATES;
		const field: NameType = options.field as NameType;
		const fieldEntry = SUBSTATION_STATIC_DATA_AGGREGATES[field];
		return (
			<span>{`${getAggregateData(colData, fieldEntry.fields)} ${
				fieldEntry.unit ? t("units.short.kw") : ""
			}`}</span>
		);
	};

	const columns = useMemo(() => {
		let cols = GENERAL_SUBSTATION_DETAILS_COLS;
		switch (props.selectedGroup) {
			case StaticDataGroups.GeneralDetails:
				cols = GENERAL_SUBSTATION_DETAILS_COLS;
				break;
			case StaticDataGroups.LCT:
				cols = LCT_COLS;
				break;
			case StaticDataGroups.GenerationCount:
				cols = GENERATION_COLS;
				break;
			case StaticDataGroups.GeneratorAndStorage:
				cols = GENERATION_AND_STORAGE;
				break;
			case StaticDataGroups.NetworkCapacity:
				cols = NETWORK_CAPACITY_COLS;
				break;
		}
		cols = cols.map((col) => {
			if (col.aggregateValue) {
				col.body = genearteAggregateValues;
			} else if (col.unit) {
				col.body = generateValuesWithUnit;
			}
			return col;
		});
		return cols;
	}, [props.selectedGroup]);

	const substationColTemplate = (colData: NGEDSubstationAsset) => {
		return <span>{`${colData.name} (${colData.serialNumber})`}</span>;
	};

	return (
		<DataTable
			stripedRows
			value={props.substations}
			dataKey="id"
			id="stickyHeaderTable"
			responsiveLayout="scroll"
			scrollHeight="300px"
		>
			<Column
				header={t("substation.substation")}
				field="name"
				body={substationColTemplate}
			/>
			{columns.map((col: colProps) => {
				return (
					<Column
						header={
							col.header != null ? t(col.header.toString()) : ""
						}
						field={col.field}
						body={col.body ?? undefined}
					/>
				);
			})}
		</DataTable>
	);
}
