import { Box, Stack, Typography, useTheme } from "@mui/material";

export const KeyFeature = ({
	title,
	image,
	children,
}: {
	title: string;
	image: any;
	children: string;
}) => {
	const theme = useTheme();
	return (
		<Stack direction={"row"} spacing={2} margin={3}>
			<Box>
				<img src={image} width={40} alt={title} />
			</Box>
			<Box>
				<Typography variant="h6" color={theme.palette.ngedGreen.main}>
					{title}
				</Typography>
				<Typography variant="body1">{children}</Typography>
			</Box>
		</Stack>
	);
};