import * as React from 'react';
import { getExpires, getToken, refreshToken, tokenIsValid } from '../../Shared/TokenHandling/TokenManagement';

interface Props {
	onAuthError?: () => void;
}

export default class ApiHandlerPureComponent extends React.PureComponent<Props> {

	get(address: string): Promise<any> {
		return this.query(address, "GET", undefined, false);
	}

	post(address: string, body?: string, removeAuth?: boolean): Promise<any> {
		return this.query(address, "POST", body, removeAuth);
	}

	query(address: string, method: string, body?: string | FormData, removeAuth?:boolean): Promise<any> {
		if (!tokenIsValid() && removeAuth === false) {
			return this.handleAuthError();
		}

		let header: {};
		header = {
			"Content-Type": "application/json",
			"Authorization": `Bearer ${getToken()}`
		};

		return fetch(address,
			{
				method: method,
				headers: header,
				body: body
			})
			.then(response => {
				return this.handleResponse(response, address, method, body);
			})
			.catch(error => {
				return Promise.reject(error);
			});
	}

	handleAuthError(): Promise<any> {
		this.props.onAuthError && this.props.onAuthError();
		return Promise.resolve();
	}

	async handleResponse(response: Response, address: string, method: string, body?: string | FormData) {
		if (response.ok) {
			const contentType = response.headers.get('Content-Type');

			if (contentType && contentType.includes("application/json")) {
				const data = await response.json();
                return data || Promise.resolve(undefined);
			}
			else if (contentType && contentType.includes("application/octet-stream")) {
				const data_1 = await response.blob();
                return data_1 || Promise.resolve(undefined);
			}
			else {
				const text = await response.text();
                return text || Promise.resolve(undefined);
			}
		} else {
			if (response.status === 401 ) {
				const expiry = getExpires();
				if (expiry && new Date() > expiry) {
					// Token has expired, try a refresh
					return refreshToken()
						.then(() => {
							// Update the token and recursively try again.
							return this.query(address, method, body);
						})
						.catch(() => {
							return this.handleAuthError();
						});
				} else {
					return this.handleAuthError();
				}
			} else {
				return Promise.reject(response);
			}
		}
	}

}