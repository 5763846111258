import React from "react";
import { PathOptions, StyleFunction } from "leaflet";
import { createLayerComponent } from "@react-leaflet/core";
import L from "leaflet";

interface GeoFenceLayerProps extends L.GeoJSONOptions {
	geoFence: string;
	geoFenceName?: string;
	style?: PathOptions | StyleFunction;
	children?: React.ReactNode;
}

const Layer = createLayerComponent<L.GeoJSON, GeoFenceLayerProps>(
	function createGeoJSON({ geoFence, geoFenceName, style, ...options }, ctx) {
		const instance = createGeoJSONInstance(options, geoFence, style);
		return { instance, context: { ...ctx, overlayContainer: instance } };
	}
);

export function GeoFenceLayer(params: GeoFenceLayerProps) {
	return (
		<Layer
			geoFence={params.geoFence}
			geoFenceName={params.geoFenceName}
			style={params.style}
			key={params.geoFenceName}
		>
			{params.children}
		</Layer>
	);
}

function createGeoJSONInstance(
	options: L.GeoJSONOptions,
	geoFence: string,
	style?: PathOptions | StyleFunction
) {
	const geojson = JSON.parse(geoFence);
	return new L.GeoJSON(geojson, {
		...options,
		style: style,
	});
}
