import React from "react";
import config from "../../../configuration";
import { ApiHandlerPureComponent } from "../../utils";
import {
	CarbonIntensityAndGenerationMixData,
	HistoricCarbonIntensityAndGenerationMixData,
	CarbonIntensityAPIRegionId,
} from "./interfaces/CarbonIntensity";

export default class CarbonIntensityAPIs extends React.PureComponent {
	private apiResponseHandler = new ApiHandlerPureComponent({});

	private static readonly BASE_URL: string = `${config.apiGateway.CARBON_INTENSITY_API}`;

	getCarbonIntensityAndGenerationMixAtLicenceAreaLevel = (
		regionId: CarbonIntensityAPIRegionId
	): Promise<CarbonIntensityAndGenerationMixData> => {
		const apiAddress = `${CarbonIntensityAPIs.BASE_URL}/regional/regionid/${regionId}`;
		return this.apiResponseHandler.get(apiAddress);
	};

	getCarbonIntensityAndGenerationMixAtOutwardPostCodeLevel = (
		outwardPostcode: string
	): Promise<CarbonIntensityAndGenerationMixData> => {
		const apiAddress = `${CarbonIntensityAPIs.BASE_URL}/regional/postcode/${outwardPostcode}`;
		return this.apiResponseHandler.get(apiAddress);
	};

	getHistoricalCarbonIntensityAndGenerationMixAtLicenceAreaLevel = (
		startDate: Date,
		endDate: Date,
		regionId: CarbonIntensityAPIRegionId
	): Promise<HistoricCarbonIntensityAndGenerationMixData> => {
		const apiAddress = `${
			CarbonIntensityAPIs.BASE_URL
		}/regional/intensity/${startDate.toISOString()}/${endDate.toISOString()}/regionid/${regionId}`;
		return this.apiResponseHandler.get(apiAddress);
	};
}
