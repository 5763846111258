import { useTheme, Theme } from '@mui/material/styles';
import { AssetTypes } from '../enums/AssetTypes';
import { useTranslation } from 'react-i18next';

//Hook to get substation marker theme color in a react component
export function useSubstationColor(assetType: AssetTypes) {
	const theme = useTheme();

	return getSubstationColor(assetType, theme);
}

//Function to get color when the hook can't be used
export function getSubstationColor(assetType: AssetTypes, theme: Theme) {
	switch (assetType) {
		case AssetTypes.HvSubstation:
			return theme.palette.mainInstance.main
		case AssetTypes.Substation:
			return theme.palette.mainInstance.main
		default:
			return theme.palette.mainInstance.main
	}
}

//Function to get substation Type text
export function useSubstationTypeText(assetType: AssetTypes) {
	const { t } = useTranslation();
	switch (assetType) {
		case AssetTypes.HvSubstation:
			return t("substation.primarySubstation")
		case AssetTypes.Substation:
			return t("substation.lvSubstation")
		default:
			return t("substation.lvSubstation")
	}
}
