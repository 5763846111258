export enum AssetTypes {
	HvSubstation = "HvSubstation",
	Substation = "Substation",
	Transformer= "Transformer",
	LvBoard= "LvBoard",
	LvWay= "LvWay",
	LvCircuit= "LvCircuit",
	Busbar= "Busbar",
	BusbarL1Phase= "BusbarL1Phase",
	BusbarL2Phase= "BusbarL2Phase",
	BusbarL3Phase= "BusbarL3Phase",
	BusbarNeutralPhase = "BusbarNeutralPhase",
	Instrument= "Instrument",
	PrimaryWinding= "PrimaryWinding",
	SecondaryWinding= "SecondaryWinding",
	ShortTail= "ShortTail",
	TxL1Phase= "TxL1Phase",
	TxL2Phase= "TxL2Phase",
	TxL3Phase= "TxL3Phase",
	TxNeutralPhase= "TxNeutralPhase",
	FeederL1Phase= "FeederL1Phase",
	FeederL2Phase= "FeederL2Phase",
	FeederL3Phase= "FeederL3Phase",
	FeederNeutralPhase = "FeederNeutralPhase",
	Panel = "Panel"
}