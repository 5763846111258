import React from "react";
import config from "../../../configuration";
import { ApiHandlerPureComponent } from "../../utils";
import { PrimarySubstationWithStatistics } from "./interfaces/PrimarySubstationWithStatistics";

interface Props {
	onAuthError?: () => void;
}

export default class PrimarySubstationAPIs extends React.PureComponent<Props> {
	private apiResponseHandler = new ApiHandlerPureComponent({ onAuthError: this.props.onAuthError });

	private static readonly BASE_URL: string = `${config.apiGateway.NGED_META_API}/api/PrimarySubstations/`;

	getPrimarySubstationWithStatistics = async (
		substationNumber: number
	): Promise<PrimarySubstationWithStatistics> => {
		const apiAddress = `${PrimarySubstationAPIs.BASE_URL}${substationNumber}/statistics`;
		return await this.apiResponseHandler.get(apiAddress);
	};
}
