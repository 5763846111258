import React from "react";
import config from "../../../configuration";
import { ApiHandlerPureComponent } from "../../utils";
import { CategorisedData } from "./interfaces/Data";

interface Props {
	onAuthError?: () => void;
}

export default class DataAPIs extends React.PureComponent<Props> {
	private apiResponseHandler = new ApiHandlerPureComponent({});

	private static readonly CATEGORISED_DATA_URL = `${config.apiGateway.NGED_META_API}/api/CategorisedData/`;

	getDataByAssetIdAndDataPointTypeId(
		assetIds: number[],
		datapointTypesIDs: number[],
		startTime: string,
		endTime: string,
		instrumentId?: number
	): Promise<CategorisedData[]> {
		const apiAddress = `${DataAPIs.CATEGORISED_DATA_URL}ByAssetAndDataPointTypeId`;
		const dataPointTypes = [...datapointTypesIDs];

		const body = {
			assetIDs: assetIds,
			datapointTypeIds: dataPointTypes,
			startTime: startTime,
			endTime: endTime,
			instrumentId: instrumentId,
		};

		return this.apiResponseHandler
			.post(apiAddress, JSON.stringify(body))
			.then((data) => {
				return data;
			});
	}

	getLatestMeasurementTimeBySubstation(
		substationNumber: string
	): Promise<Date> {
		return this.apiResponseHandler.get(
			`${DataAPIs.CATEGORISED_DATA_URL}latestMeasurementTimeBySubstation/${substationNumber}`
		);
	}
}
