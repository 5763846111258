import { bbox, helpers } from "@turf/turf";
import { NGEDSubstationAsset } from "../../data/Substation/interfaces/NGEDSubstationAsset";

export function getSubstationsGeoJSON(substations: NGEDSubstationAsset[]) {
    let gpsCoords = substations.filter((substation) => substation.gpsCoords && ((substation.gpsCoords.longitude !== 0 && substation.gpsCoords.latitude !== 0)))
        .map((substation) => [substation.gpsCoords.longitude, substation.gpsCoords.latitude]);

    if (gpsCoords && gpsCoords.length > 0) {
        gpsCoords = removeDuplicateCoordinates(gpsCoords);

        let feature;
        if (gpsCoords.length === 1) {
            feature = helpers.point(gpsCoords[0]);
        } else {
            feature = helpers.multiPoint(gpsCoords);
        }

        return feature;
    }
}

export function removeDuplicateCoordinates (array: number[][]) {
    return Array.from(new Set(array.map((element) => JSON.stringify(element)))).map((element) => JSON.parse(element));
}

export function getGeoFenceBounds(geoJSON: helpers.AllGeoJSON) {
    const bboxArray = bbox(geoJSON);
    const corner1 = [bboxArray[1], bboxArray[0]];
    const corner2 = [bboxArray[3], bboxArray[2]];
    const bounds = [corner1, corner2];

    return bounds;
}
