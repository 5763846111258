import { useContext } from "react";
import { ApiContext } from "../../../contexts/ApiContext";
import { PrimarySubstationWithStatistics } from "../interfaces/PrimarySubstationWithStatistics";
import { useQuery } from "react-query";
import { QueryCallbacks } from "../../../utils/interfaces/ReactQuery";

export default function usePrimarySubstationWithStatistics(
	substationNumber?: number,
	callbacks?: QueryCallbacks<PrimarySubstationWithStatistics | undefined>
) {
	const apiContext = useContext(ApiContext);

	return useQuery<PrimarySubstationWithStatistics | undefined, Response>(
		[`primarySubstationWithStatistics-${substationNumber}`],
		async () => {
			if (substationNumber) {
				return await apiContext.primarySubstationApis.getPrimarySubstationWithStatistics(
					substationNumber
				);
			} else return undefined;
		},
		{
			onSuccess: callbacks?.onSuccess,
			onError: callbacks?.onError,
			refetchOnWindowFocus: false,
		}
	);
}
