import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Stack,
	FormGroup,
	FormHelperText,
	Box,
	Button,
	AlertColor
} from '@mui/material';

import config from '../../../configuration';
import LoginStyledDialog from './LoginStyledDialog';
import { LoginStyledTextInput } from './LoginStyledTextInput';
import MessageToast from '../Messages/MessageToast';
import ProgressButton from '../Inputs/ProgressButton';

interface RequestAccessDialogProps {
	show: boolean;
	onBackClick: () => void;
}

export default function RequestAccessDialog(props: RequestAccessDialogProps) {
	const { t } = useTranslation();

	const [email, setEmail] = useState("");

	const [messageText, setMessageText] = useState("");
	const [messageSeverity, setMessageSeverity] = useState<AlertColor | undefined>();

	const [isLoading, setIsLoading] = useState(false);

	function handleSubmit(e: any) {
		e.preventDefault();
		if (email === "") {
            setMessageText(t('login.pleaseEnterEmail'));
            setMessageSeverity("error");
            return;
		}

		setIsLoading(true);
		setMessageText("");
		setMessageSeverity(undefined);

		const requestAccessEndpoint = `${config.apiGateway.NGED_AUTH_API}/api/Account/createUser/email/${email}`;

		fetch(requestAccessEndpoint,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				}
			}).then(resp => handleResponse(resp))
			.catch(() => {
				setMessageText(t('login.errorRequestingAccess'));
				setMessageSeverity("error");
				setIsLoading(false);
			});
	}

	function handleResponse(response: Response) {
		if (response.ok) {
			setEmail("");
			setMessageText(t('login.requestSent'));
			setMessageSeverity("success");
			setIsLoading(false);
		}else if(response.status === 409){
            setMessageText(t('login.emailAlreadyRegistered'));
			setMessageSeverity("error");
            setIsLoading(false);
        } 
        else {
			setMessageText(t('login.errorRequestingAccess'));
			setMessageSeverity("error");
            setIsLoading(false);
		}
	}

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
		clearMessageToast();
	}

	function clearMessageToast() {
		setMessageText("");
		setMessageSeverity(undefined);
	}

	return (
		<LoginStyledDialog
			open={props.show}
			titleText={t('login.requestAccess')}
			content={<>
				<Box mt={2}>

					<FormGroup sx={{ w: "100%" }}>
						<FormHelperText sx={{ mt: 2 }}>
							{t('login.emailAddress')}
						</FormHelperText>
						<LoginStyledTextInput required={true} value={email} onChange={handleEmailChange} placeholder={t('login.email')} />
					</FormGroup>
				</Box>
				<Box display="flex" justifyContent="center" pt={2}>
					{messageText && messageSeverity && <MessageToast text={messageText} severity={messageSeverity} />}
				</Box>
			</>
			}
			actions={<Stack direction="row"
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
				width="100%"
			>
				<Button
					disabled={isLoading}
					variant="contained"
					color="secondary"
					type="button"
					onClick={props.onBackClick}
				>
					{t('common.back')}
				</Button>
				<ProgressButton onClick={handleSubmit} loading={isLoading}>
					{t('login.requestAccess')}
				</ProgressButton>
			</Stack>
			} />
	)
}