import { useTranslation } from 'react-i18next';
import { Info } from "luxon";
import round from 'lodash/round';

export function useInternationalFormatters() {
	const { i18n, t } = useTranslation();

	function bytesToReadableFilesize(bytes: number) {
		const i = Math.floor(Math.log(bytes) / Math.log(1024));
		const value = (bytes / Math.pow(1024, i)).toFixed(2);
		const unitKey = ['bytes', 'kb', 'mb', 'gb', 'tb'][i]
		return t("fileSize.format", { value: value, unit: t(`fileSize.${unitKey}`) });
	}

	function formatNumber(value: string | number, precision?: number) {
		value = precision ? round(Number(value), precision) : value;
		return t('common.formattedNumber', { value: value });
	}

	return {
		localDateStrings: {
			dayNames: Info.weekdays("long", { locale: i18n.language }),
			dayNamesShort: Info.weekdays("short", { locale: i18n.language }),
			dayNamesMin: Info.weekdays("short", { locale: i18n.language }),
			monthNames: Info.months("long", { locale: i18n.language }),
			monthNamesShort: Info.months("short", { locale: i18n.language }),
		},
		formatNumber: formatNumber,
		bytesToReadableFilesize: bytesToReadableFilesize
	}
}