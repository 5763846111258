import { useState, useEffect } from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import MuiCard, { CardProps } from "@mui/material/Card";
import { CardContent, Box, BoxProps, Fade, Stack, styled, Typography, AlertColor, Theme, useTheme } from "@mui/material";

interface Props extends BoxProps {
	severity: AlertColor;
	title?: string;
	primaryText?: string;
	secondaryText?: string | JSX.Element;
	cardActions?: JSX.Element;
	enableColoredBorder?: boolean;
	highlightMessage?: boolean;
	onMessageHighlightComplete?: () => void;
	cardProps?: CardProps;
	
}

function getSeverityColor(severity: AlertColor, theme: Theme) {
	switch (severity) {
		case "error":
			return theme.palette.error.main;
		case "info":
			return theme.palette.info.main
		case "success":
			return theme.palette.success.main
		case "warning":
			return theme.palette.warning.main
	}
}

function getSeverityIcon(severity: AlertColor) {
	switch (severity) {
		case "error":
			return faExclamationTriangle
		case "info":
			return faInfoCircle
		case "success":
			return faCheckCircle
		case "warning":
			return faExclamationTriangle
	}
}

const Card = styled(MuiCard)<CardProps & { severity: AlertColor, enableColoredBorder?: boolean, highlightMessage?: boolean }>(
	({ theme, severity, enableColoredBorder, highlightMessage }) => {

		return {
			width: 285,
			borderColor: enableColoredBorder || highlightMessage ? getSeverityColor(severity, theme) : undefined,
			transition: theme.transitions.create(
				["border-color", "transform"],
				{
					duration: theme.transitions.duration.complex
				}
			)
		}
	})

const IconContainer = styled("span")(({ theme }) => {
	return {
		display: "flex",
		justifyContent: "center",
		width: "100%",
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	}
});

const Icon = styled(FontAwesomeIcon)<FontAwesomeIconProps & { severity: AlertColor }>(
	({ theme, severity }) => {
		return {
			//fontSize: "3em",
			color: getSeverityColor(severity, theme)
		}
	},
);

export default function MessageCard(props: Props) {
	const theme = useTheme();

	const [fadingCard, setFadingCard] = useState(false);
	const [highlightingBorder, setHighlightingBorder] = useState(false);

	const { severity,
		title,
		primaryText,
		secondaryText,
		cardActions,
		enableColoredBorder,
		highlightMessage,
		onMessageHighlightComplete,
		cardProps,
		...boxProps } = props;

	useEffect(() => {
		if (highlightMessage) {
			setHighlightingBorder(true);
			setFadingCard(true);

			const borderHighlightTimeout = setTimeout(() => {
				setHighlightingBorder(false);
				onMessageHighlightComplete?.();
			}, theme.transitions.duration.complex);

			const cardFadeTimeout = setTimeout(() => setFadingCard(false), theme.transitions.duration.shorter);

			return () => {
				clearTimeout(borderHighlightTimeout);
				clearTimeout(cardFadeTimeout);
			}
		}

	}, [
		highlightMessage,
		onMessageHighlightComplete,
		theme.transitions.duration.complex,
		theme.transitions.duration.shorter
	]);

	return <Fade in={!fadingCard} timeout={theme.transitions.duration.shorter}>
		<Box {...boxProps}>
			<Card
				severity={severity}
				enableColoredBorder={enableColoredBorder}
				highlightMessage={highlightingBorder}
				variant="outlined"
				{...cardProps}>
				<CardContent>
					<Stack spacing={2} display="flex" justifyContent="center" alignItems="center">
						<IconContainer>
							<Icon
								icon={getSeverityIcon(severity)}
								size="3x"
								severity={severity}
								color={getSeverityColor(severity, theme)}
							/>
						</IconContainer>
						{title
							&& <Typography color="textPrimary" gutterBottom variant="h6" align='center'  >
								{title}
							</Typography>
						}
						{primaryText
							&& <Typography color="textPrimary" gutterBottom variant="subtitle2" align='center' >
								{primaryText}
							</Typography>
						}
						{secondaryText
							&& <Typography variant="caption" color="textSecondary" align='center'>
								{secondaryText}
							</Typography>
						}

						{cardActions}
					</Stack>
				</CardContent>

			</Card>
		</Box>
	</Fade>
}
