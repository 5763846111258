import { UseQueryResult } from "react-query";
import useDataByAssetIdsAndDataPointTypeIds from "../../data/Data/hooks/useDataByAssetAndDataPointTypeId";
import { CategorisedData } from "../../data/Data/interfaces/Data";
import { ExtendedSubstationHierarchy } from "../../data/Substation/interfaces/ExtendedSubstationHierarchy";
import {
	FeederAsset,
	GroupedBusbarAsset,
	TransformerAsset,
} from "../../data/Substation/interfaces/SubstationHierarchyReport";
import { DataPointType } from "../../enums/DatapointTypes";
import { Phase } from "../../enums/Phase";
import { TimePeriod } from "../DatePeriodSelector";
import { useCategorisedDataAssetIds } from "./useCategorisedDataAssetIds";

interface UseFilteredDataParams {
	substation: ExtendedSubstationHierarchy;
	timePeriod?: TimePeriod;
	dataPointTypes: DataPointType[];
	transformer?: TransformerAsset;
	feeder?: FeederAsset;
	busbar?: GroupedBusbarAsset;
	phase?: Phase;
	instrumentId?: number;
	startDate: Date;
	endDate: Date;
	compareSubstationHierarchy?: ExtendedSubstationHierarchy;
	compareFeeder?: FeederAsset;
	onSuccess?: (data: CategorisedData[] | undefined) => void;
	onError?: () => void;
}

export const useFilteredCategorisedData = (
	params: UseFilteredDataParams
): UseQueryResult<CategorisedData[] | undefined, Response> => {
	const {
		substation,
		transformer,
		feeder,
		busbar,
		phase,
		instrumentId,
		dataPointTypes,
		startDate,
		endDate,
		compareSubstationHierarchy,
		compareFeeder,
	} = params;
	const assetIds = useCategorisedDataAssetIds(
		dataPointTypes,
		substation,
		transformer,
		feeder,
		phase,
		compareSubstationHierarchy,
		compareFeeder
	);
	const { onSuccess, onError } = params;
	return useDataByAssetIdsAndDataPointTypeIds(
		assetIds,
		dataPointTypes,
		startDate.toISOString(),
		endDate.toISOString(),
		instrumentId,
		{ onSuccess, onError }
	);
};
