import { Stack, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
	FeederAsset,
	GroupedBusbarAsset,
	TransformerAsset,
} from "../data/Substation/interfaces/SubstationHierarchyReport";
import { AssetTypes } from "../enums/AssetTypes";
import { Phase } from "../enums/Phase";
import { useFeedersSortedByNameAsc } from "../utils/useSortedFeedersByName";
import FilterChip from "./FilterChip";
import LabelText from "./LabelValueFormatters/LabelText";

interface Props {
	transformer: TransformerAsset;
	selectedAssetId: number | undefined;
	selectedPhase: Phase | undefined;
	onAssetChange: (
		selectedAsset: TransformerAsset | FeederAsset | GroupedBusbarAsset
	) => void;
	onPhaseChange: (selectedPhase: Phase | undefined) => void;
	displayTransformerChip?: boolean;
}

export function AssetSelectionChips(props: Props) {
	const { t } = useTranslation();
	const {
		transformer,
		selectedAssetId,
		selectedPhase,
		onAssetChange,
		onPhaseChange,
		displayTransformerChip,
	} = props;

	const sortedFeeders = useFeedersSortedByNameAsc(transformer?.feeders);
	const busbar: GroupedBusbarAsset = {
		id: 1,
		name: t("busbars.busbar"),
		assetTypeKey: AssetTypes.Busbar,
		busbars: transformer?.busbars,
	};

	const handlePhaseChipClick = (phase: Phase) => {
		if (phase === selectedPhase) onPhaseChange(undefined);
		else onPhaseChange(phase);
	};
	return (
		<>
			<Box>
				<Box marginBottom={2}>
					<LabelText
						text={`${t("substation.substation")}`}
						message={t("definitions.substation")}
					/>
					<Stack
						direction="row"
						spacing={1}
						minHeight={"2em"}
						width="100%"
						flexWrap="wrap"
					>
						{!displayTransformerChip && (
							<FilterChip
								isSelected={selectedAssetId === transformer.id}
								label={`${t("substation.substation")}`}
								onClick={() => onAssetChange(transformer)}
							/>
						)}
						{displayTransformerChip && (
							<>
								<FilterChip
									isSelected={
										selectedAssetId === transformer.id
									}
									label={transformer.name ?? ""}
									onClick={() => onAssetChange(transformer)}
								/>

								<FilterChip
									key={`filterChip-${busbar.name}`}
									isSelected={selectedAssetId === busbar.id}
									label={busbar ? `${busbar.name}` : ""}
									onClick={() => onAssetChange(busbar)}
								/>
							</>
						)}
					</Stack>
				</Box>
			</Box>
			{sortedFeeders && (
				<Box>
					<Box marginBottom={2}>
						<LabelText
							text={`${t("feeders.feeder")}`}
							message={t("definitions.feeder")}
						/>
						<Stack direction="row" spacing={1}>
							{sortedFeeders.map((feeder) => (
								<FilterChip
									key={`filterChip-${feeder.id}`}
									isSelected={selectedAssetId === feeder.id}
									label={feeder ? `${feeder.name}` : ""}
									onClick={() => onAssetChange(feeder)}
								/>
							))}
						</Stack>
					</Box>
				</Box>
			)}

			<Box>
				<Box marginBottom={2}>
					<LabelText
						text={`${t("phases.phase")}`}
						message={t("definitions.phase")}
					/>
					<Stack direction="row" spacing={1}>
						{Object.values(Phase).map((phase) => (
							<FilterChip
								key={`filterChip-${phase}`}
								isSelected={selectedPhase === phase}
								label={t(`phases.L1_L2_L3.${phase}`)}
								onClick={() =>
									handlePhaseChipClick(Phase[phase])
								}
							/>
						))}
					</Stack>
				</Box>
			</Box>
		</>
	);
}
