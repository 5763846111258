import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import upperFirst from "lodash/upperFirst";
import { convertUtcToLocalDateAndTime } from './components/utils/DateConversions'
import { DateTime } from "luxon";
import translationEN from './locales/en/translation.en.json';

const resources = {
	en: {
		translation: translationEN
	}
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		detection: {
			// order and from where user language should be detected
			order: ['querystring', 'navigator', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],
		},
		fallbackLng: 'en',
		debug: false,

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
			format: function (value, format, lng) {
				if (format === 'intlDate' && value){
					const date = convertUtcToLocalDateAndTime(value);
					return `${date.toLocaleDateString(lng)} ${date.toLocaleTimeString(lng)}`;
				}
				if (format === 'intlDateTimeZone') {
					const date = convertUtcToLocalDateAndTime(value);
					return `${date.toLocaleDateString(lng)} ${date.toLocaleTimeString(lng)} ${DateTime.local().offsetNameShort}`
				}
				if(format === 'intlDateShort' && value){
					const date = convertUtcToLocalDateAndTime(value);
					return date.toLocaleDateString(lng);
				}
				if(format === 'intlTime' && value){
					const date = convertUtcToLocalDateAndTime(value);
					return date.toLocaleTimeString(lng);
				}
				if(format === 'intlTimeWithMilliseconds' && value){

					const formatter = new Intl.DateTimeFormat(lng, {
						hour: 'numeric',
						minute: 'numeric',
						second: 'numeric',
						fractionalSecondDigits: 3
					  });

					const date = convertUtcToLocalDateAndTime(value);
					return formatter.format(date);
				}
				if (format === 'lowercase') return value.toLowerCase();
				if (format === 'upperFirst') return upperFirst(value.toLowerCase());
				if (format === 'intlNumber') return new Intl.NumberFormat(lng).format(Number(value));
				return value;
			}
		},
		react: {
			useSuspense: false
		}
	});

i18n.changeLanguage();

export default i18n;
