import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { CalendarChangeParams } from "primereact/calendar";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { Stack, Grid, Box } from "@mui/material";
import { InternationalDatepicker } from "./InternationalDatePicker";
import { timePeriodToDuration } from "../utils/timePeriodToDuration";
import LabelText from "./LabelValueFormatters/LabelText";

export interface DatePeriodSelectorProps {
	selectedTimePeriod: TimePeriod | undefined;
	selectedStartDate: Date | undefined;
	selectedEndDate: Date | undefined;
	onDatePeriodSelectionChange: (
		startDate: Date | undefined,
		endDate: Date | undefined,
		selectedTimePeriod: TimePeriod | undefined
	) => void;
	enableMostRecentOption?: boolean;
	enableAllTimeOption?: boolean;
}

export enum TimePeriod {
	MOST_RECENT = "recent",
	TWENTY_FOUR_HOURS = "24H",
	SEVEN_DAYS = "7D",
	THIRTY_DAYS = "30D",
	NINETY_DAYS = "90D",
	ALL_TIME = "allTime",
	SINGLE = "single",
	RANGE = "range",
}

const ALL_TIME_START_DATE = DateTime.fromFormat("1970-01-01", "yyyy-MM-dd");
const defaultStartDateTime = DateTime.now().startOf("day");
const defaultEndDateTime = DateTime.now().endOf("day");
export const DEFAULT_PERIOD = TimePeriod.TWENTY_FOUR_HOURS;
export const DEFAULT_START_DATE = defaultStartDateTime.toJSDate();
export const DEFAULT_END_DATE = defaultEndDateTime.toJSDate();

export function getDatesByTimePeriod(timePeriod: TimePeriod | undefined) {
	let startDate: DateTime | undefined = undefined;
	let endDate: DateTime | undefined = undefined;

	if (timePeriod === TimePeriod.ALL_TIME) {
		startDate = ALL_TIME_START_DATE.toUTC();
		endDate = DateTime.now().toUTC();
	} else if (timePeriod !== TimePeriod.MOST_RECENT) {
		const duration = timePeriod && timePeriodToDuration(timePeriod);

		if (duration) {
			startDate = DateTime.now().toUTC().minus(duration);
			endDate = DateTime.now().toUTC();
		} else {
			startDate = defaultStartDateTime;
			endDate = defaultEndDateTime;
		}
	}

	return {
		startDate: startDate?.toJSDate(),
		endDate: endDate?.toJSDate(),
	};
}

export function DatePeriodSelector(props: DatePeriodSelectorProps) {
	const { t } = useTranslation();

	const {
		selectedTimePeriod,
		selectedStartDate,
		selectedEndDate,
		onDatePeriodSelectionChange,
		enableMostRecentOption,
		enableAllTimeOption,
	} = props;

	const [customRangeStartDate, setCustomRangeStartDate] = useState<
		Date | undefined
	>();
	const [customRangeEndDate, setCustomRangeEndDate] = useState<
		Date | undefined
	>();

	function handleTimePeriodOptionChange(e: DropdownChangeParams) {
		const period = e.target.value as TimePeriod;

		handleTimePeriodChange(period);
	}

	const handleTimePeriodChange = useCallback(
		(period: TimePeriod | undefined) => {
			const { startDate, endDate } = getDatesByTimePeriod(period);

			if (startDate && !customRangeStartDate)
				setCustomRangeStartDate(startDate);
			if (endDate && !customRangeEndDate) setCustomRangeEndDate(endDate);

			onDatePeriodSelectionChange(startDate, endDate, period);
		},
		[onDatePeriodSelectionChange, customRangeStartDate, customRangeEndDate]
	);

	function handleSingleDayChange(e: CalendarChangeParams) {
		const selectedValue = e.value as Date;

		const start = DateTime.fromJSDate(selectedValue).startOf("day");
		const end = DateTime.fromJSDate(selectedValue).endOf("day");

		onDatePeriodSelectionChange(
			start.toJSDate(),
			end.toJSDate(),
			selectedTimePeriod
		);
	}

	function handleStartDateChange(e: CalendarChangeParams) {
		const selectedValue = e.value as Date;
		setCustomRangeStartDate(selectedValue);
	}

	function handleEndDateChange(e: CalendarChangeParams) {
		const selectedValue = e.value as Date;
		setCustomRangeEndDate(selectedValue);
	}

	function handleCustomRangeDatePickerHide() {
		onDatePeriodSelectionChange(
			customRangeStartDate,
			customRangeEndDate,
			selectedTimePeriod
		);
	}

	const timePeriodOptions = [
		{
			key: TimePeriod.TWENTY_FOUR_HOURS,
			text: t("dateSelection.last24H"),
		},
		{
			key: TimePeriod.SEVEN_DAYS,
			text: t("dateSelection.last7Days"),
		},
		{
			key: TimePeriod.THIRTY_DAYS,
			text: t("dateSelection.last30Days"),
		},
		{
			key: TimePeriod.NINETY_DAYS,
			text: t("dateSelection.last90Days"),
		},
		{
			key: TimePeriod.SINGLE,
			text: t("dateSelection.customDate"),
		},
		{
			key: TimePeriod.RANGE,
			text: t("dateSelection.customDateRange"),
		},
	];

	enableAllTimeOption &&
		timePeriodOptions.unshift({
			key: TimePeriod.ALL_TIME,
			text: t("dateSelection.allTime"),
		});
	enableMostRecentOption &&
		timePeriodOptions.unshift({
			key: TimePeriod.MOST_RECENT,
			text: t("dateSelection.mostRecent"),
		});

	return (
		<Stack direction={{ xs: "column", lg: "row" }} spacing={1}>
			<Stack direction="column" margin={0} alignItems="flex-start">
				<LabelText text={`${t("dateSelection.timePeriod")}`} />

				<Dropdown
					value={selectedTimePeriod}
					options={timePeriodOptions}
					optionLabel="text"
					optionValue="key"
					onChange={handleTimePeriodOptionChange}
					placeholder={t("dateSelection.selectTimePeriod")}
				/>
			</Stack>

			{selectedTimePeriod === TimePeriod.SINGLE && (
				<Stack
					direction="column"
					margin={0}
					alignItems="flex-start"
					width="160px"
				>
					<LabelText text={`${t("dateSelection.day")}`} />
					<InternationalDatepicker
						showIcon
						value={selectedStartDate}
						onChange={handleSingleDayChange}
					/>
				</Stack>
			)}
			{selectedTimePeriod === TimePeriod.RANGE && (
				<Box>
					<Grid container spacing={1}>
						<Grid item>
							<Stack
								direction="column"
								margin={0}
								alignItems="flex-start"
								width="200px"
							>
								<LabelText
									text={`${t("dateSelection.from")}`}
								/>
								<InternationalDatepicker
									showIcon
									value={selectedStartDate}
									onChange={handleStartDateChange}
									onHide={handleCustomRangeDatePickerHide}
								/>
							</Stack>
						</Grid>
						<Grid item>
							<Stack
								direction="column"
								margin={0}
								alignItems="flex-start"
								width="200px"
							>
								<LabelText text={`${t("dateSelection.to")}`} />
								<InternationalDatepicker
									showIcon
									value={selectedEndDate}
									onChange={handleEndDateChange}
									onHide={handleCustomRangeDatePickerHide}
								/>
							</Stack>
						</Grid>
					</Grid>
				</Box>
			)}
		</Stack>
	);
}
