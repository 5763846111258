import { useContext } from "react";

import { useQuery } from "react-query";
import { ApiContext } from "../../../contexts/ApiContext";
import { QueryCallbacks } from "../../../utils/interfaces/ReactQuery";

export default function useDataByAssetIdsAndDataPointTypeIds<T>(
	assetIds: number[],
	dataPointTypeIds: number[],
	startTime: string,
	endTime: string,
	instrumentId?: number,
	callbacks?: QueryCallbacks<T | undefined>
) {
	const apiContext = useContext(ApiContext);

	return useQuery(
		[
			"dataByAssetIdsAndDataPointTypeIds",
			startTime,
			endTime,
			assetIds,
			dataPointTypeIds,
			instrumentId,
		],
		async () => {
			if (assetIds.length > 0 && dataPointTypeIds.length > 0) {
				return await apiContext.dataApis.getDataByAssetIdAndDataPointTypeId(
					assetIds,
					dataPointTypeIds,
					startTime,
					endTime,
					instrumentId
				);
			} else return [];
		},
		{
			onSuccess: callbacks?.onSuccess,
			onError: callbacks?.onError,
			refetchOnWindowFocus: false,
			retry: (failureCount, error) => {
				if (
					failureCount <= 3 &&
					error?.status !== 401 &&
					error.status !== 403
				) {
					return true;
				}
				return false;
			},
		}
	);
}
