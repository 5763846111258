import { useState, useEffect, useContext } from "react";
import {
	SubstationFiltersContextProvider,
	SubstationFiltersContext,
} from "./SubstationListContext";
import { useTranslation } from "react-i18next";
import { Box, Divider } from "@mui/material";

import SubstationFiltersUI from "./SubstationFiltersUI";

import { MapContainerComponent, SavedMapLevels } from "./Map/MapContainer";
import MessageCard from "../Shared/Messages/MessageCard";

export function SubstationsOverviewPage() {
	return (
		<SubstationFiltersContextProvider>
			<SubstationsOverview />
		</SubstationFiltersContextProvider>
	);
}

function SubstationsOverview() {
	const { t } = useTranslation();
	const { substationList, isLoading, isError, substationFilters } =
		useContext(SubstationFiltersContext);

	const [postcodeMarker, setPostcodeMarker] = useState<
		SavedMapLevels | undefined
	>(undefined);

	useEffect(() => {
		setPostcodeMarker(
			substationFilters.postcode === undefined
				? undefined
				: {
						center: [
							substationFilters.postcode.latitude,
							substationFilters.postcode.longitude,
						],
				  }
		);
	}, [substationFilters.postcode]);

	if (isError) {
		return (
			<MessageCard
				title={t("commonErrorMessages.unableToLoadError", {
					ns: "translation",
					val: t("substation.substations"),
				})}
				primaryText={t("commonErrorMessages.dataFetchError", {
					ns: "translation",
					val: t("substation.substations"),
				})}
				severity="error"
				display="flex"
				justifyContent="center"
				margin={2}
			/>
		);
	} else
		return (
			<Box display="flex" flexDirection="column" height="100%">
				<Box sx={{ pt: 1.5, pb: 2 }}>
					<SubstationFiltersUI />
				</Box>
				<Divider />
				<MapContainerComponent
					substations={substationList || []}
					isLoading={isLoading}
					licenceArea={substationFilters.licenceArea}
					county={substationFilters.county}
					postcodeMarker={postcodeMarker}
					showPrimarySubstationBoundaries={
						substationFilters.showPrimarySubstationBoundaries
					}
				/>
			</Box>
		);
}
