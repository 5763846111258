import { Alert, Box, Divider, Grid, Skeleton, Stack } from "@mui/material";

import { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { ExtendedSubstationHierarchy } from "../data/Substation/interfaces/ExtendedSubstationHierarchy";
import {
	FeederAsset,
	GroupedBusbarAsset,
	InstrumentAsset,
	TransformerAsset,
} from "../data/Substation/interfaces/SubstationHierarchyReport";
import { AssetTypes } from "../enums/AssetTypes";
import { DataPointType } from "../enums/DatapointTypes";
import { Phase } from "../enums/Phase";
import { AssetSelectionChips } from "../Shared/AssetSelectionChips";
import { useCategorisedDataAsHighchartsSeries } from "../Shared/CatgorisedData/useCategorisedDataAsHighchartsSeries";
import LineChart from "../Shared/charts/LineChart";
import DataPointTypeFilter from "../Shared/DatapointTypes/DatapointTypeFilter";
import MessageCard from "../Shared/Messages/MessageCard";
import { useFilteredCategorisedData } from "../Shared/CatgorisedData/useFilteredCategorisedData";
import {
	DatePeriodSelector,
	DEFAULT_END_DATE,
	DEFAULT_PERIOD,
	DEFAULT_START_DATE,
	TimePeriod,
} from "../Shared/DatePeriodSelector";
import { TransformerDropdown } from "../Substation/TransformerDropdown";
import LatestMeasurement from "./LatestMeasurement";
import useLvSubstationsByPrimaryNumber from "../data/Substation/hooks/useLvSubstationsByPrimaryNumber";
import { SubstationFeederDropdown } from "../Substation/SubstationFeederDropdown";
import { NGEDSubstationAsset } from "../data/Substation/interfaces/NGEDSubstationAsset";
import useExtendedSubstationHierarchyReportBySubstation from "../data/Assets/hooks/useExtendedSubstationHierarchyReportBySubstation";

import useHistoricCarbonIntensityAndGenerationMixAtLicenceAreaLevel from "../data/CarbonIntensity/hooks/useHistoricCarbonIntensityAndGenerationMixAtLicenceAreaLevel";
import { useCarbonIntensityDataAsHighchartsSeries } from "../Shared/CatgorisedData/useCarbonIntensityDataAsHighchartsSeries";
import { LicenceAreaName } from "../../configuration";
import { CarbonIntensityAPIRegionId } from "../data/CarbonIntensity/interfaces/CarbonIntensity";
import { ChartOptionsMenu } from "./ChartOptionsMenu";
import { timePeriodToDuration } from "../utils/timePeriodToDuration";

export enum ChartViewType {
	list = "list",
	grid = "grid",
}
interface MeasurementsTabProps {
	substation: ExtendedSubstationHierarchy;
	instrumentId?: string;
}

const ALL_MEASUREMENT_DATA_POINT_TYPES: DataPointType[] = [
	DataPointType.ActivePower,
	DataPointType.ReactivePower,
	DataPointType.CurrentMean30minutes,
	DataPointType.VoltageMean30minutes,
	DataPointType.VoltageTHD,
	DataPointType.TransformerTemperature,
];

const LIST_CHART_WIDTH = 12;
const GRID_CHART_WIDTH = 4;

// Some functionality is restricted for time ranges greater then ~ a month
const LONG_TIME_RANGE_THRESHOLD = 3000000; // in seconds

export function Measurements(props: MeasurementsTabProps) {
	const { t } = useTranslation();
	const { substation } = props;
	const [selectedTransformer, setSelectedTransformer] = useState<
		TransformerAsset | undefined
	>(
		substation.transformers?.length > 0
			? substation.transformers[0]
			: undefined
	);
	const [chartView, toggleChartView] = useState<ChartViewType>(
		ChartViewType.list
	);

	const [showStatutoryLimit, setAlwaysShowStatutoryLimit] =
		useState<boolean>(false);

	const [selectedAsset, setSelectedAsset] = useState<
		TransformerAsset | FeederAsset | GroupedBusbarAsset | undefined
	>(selectedTransformer);
	const [selectedPhase, setSelectedPhase] = useState<Phase | undefined>();

	const DEFAULT_SELECTED_DATAPOINTTYPES = [DataPointType.ActivePower];
	const [selectedDataPointTypes, setSelectedDataPointTypes] = useState(
		DEFAULT_SELECTED_DATAPOINTTYPES
	);

	/** Current selection for intrumnet is set to undefined. At this point we dont care about the instruments sending the data. This is just a placeholder */
	const [selectedInstrument] = useState<InstrumentAsset | undefined>(
		undefined
	);

	let defaultDurationPeriod = TimePeriod.SEVEN_DAYS;
	const duration = defaultDurationPeriod && timePeriodToDuration(defaultDurationPeriod);
	if(!duration)
	{
		defaultDurationPeriod = TimePeriod.TWENTY_FOUR_HOURS;
	}
	const [selectedTimePeriod, setSelectedTimePeriod] =
		useState<TimePeriod>(defaultDurationPeriod);
		
	const startTime = duration? DateTime.now().toUTC().minus(duration).toJSDate() : DEFAULT_START_DATE;
	const [selectedStartDate, setSelectedStartDate] =
		useState<Date>(startTime);

	const [selectedEndDate, setSelectedEndDate] =
		useState<Date>(DEFAULT_END_DATE);

	const [selectedSubstationForCompare, setSelectedSubstationForCompare] =
		useState<NGEDSubstationAsset | undefined>(undefined);

	const [
		substationsWithSamePrimaryNumber,
		setSubstationsWithSamePrimaryNumber,
	] = useState<NGEDSubstationAsset[] | null | undefined>(null);

	const [compareFeeders, setCompareFeeders] = useState<
		FeederAsset[] | undefined
	>(undefined);

	const [selectedCompareFeeder, setSelectedCompareFeeder] = useState<
		FeederAsset | undefined
	>(undefined);

	const [carbonIntensityChipSelected, setCarbonIntensityChipSelected] =
		useState<boolean>(false);

	const onLoadLvSubstationsWithPrimaryNumber = (
		data: NGEDSubstationAsset[] | null | undefined
	) => {
		setSubstationsWithSamePrimaryNumber(
			data?.filter((sub: NGEDSubstationAsset) => {
				return sub.id !== props.substation.id;
			})
		);
	};

	useLvSubstationsByPrimaryNumber(substation.primaryNumber, {
		onSuccess: onLoadLvSubstationsWithPrimaryNumber,
	});
	const { data: compareSubstationHierarchy } =
		useExtendedSubstationHierarchyReportBySubstation(
			selectedSubstationForCompare?.serialNumber
		);

	const measurementsData = useFilteredCategorisedData({
		substation: substation,
		dataPointTypes: selectedDataPointTypes,
		transformer: selectedTransformer,
		feeder:
			selectedAsset?.assetTypeKey === AssetTypes.LvWay
				? (selectedAsset as FeederAsset)
				: undefined,
		busbar:
			selectedAsset?.assetTypeKey === AssetTypes.Busbar
				? (selectedAsset as GroupedBusbarAsset)
				: undefined,
		phase: selectedPhase,
		instrumentId: selectedInstrument?.id,
		startDate: selectedStartDate,
		endDate: selectedEndDate,
		compareSubstationHierarchy: compareSubstationHierarchy,
		compareFeeder: selectedCompareFeeder,
	});

	function getLicenceArea(licenceArea?: string) {
		switch (licenceArea) {
			case LicenceAreaName.EastMidlands: {
				return CarbonIntensityAPIRegionId.EastMidlands;
			}
			case LicenceAreaName.SouthWales: {
				return CarbonIntensityAPIRegionId.SouthWales;
			}
			case LicenceAreaName.SouthWest: {
				return CarbonIntensityAPIRegionId.SouthWestEngland;
			}
			case LicenceAreaName.WestMidlands: {
				return CarbonIntensityAPIRegionId.WestMidlands;
			}
			default: {
				return undefined;
			}
		}
	}

	const { data: carbonIntensityData, isLoading: carbonIntensityLoading } =
		useHistoricCarbonIntensityAndGenerationMixAtLicenceAreaLevel(
			selectedStartDate,
			selectedEndDate,
			getLicenceArea(substation.dno)
		);

	const {
		highchartsSeriesData: carbonIntensitySeriesData,
		yAxisSettings: carbonIntensityYAxisSettings,
	} = useCarbonIntensityDataAsHighchartsSeries({
		carbonIntensityAPIResponse: carbonIntensityData,
	});

	const { highchartsSeriesData } = useCategorisedDataAsHighchartsSeries({
		categorisedData: measurementsData.data,
		parentAsset: selectedAsset,
		dataPointTypes: selectedDataPointTypes,
		busbarAssets: selectedTransformer?.busbars,
		substation: substation,
		compareSubstationHierarchy: compareSubstationHierarchy,
		showStatutoryLimit: showStatutoryLimit,
	});

	const handleTransformerChange = useCallback(
		(transformer: TransformerAsset | undefined) => {
			setSelectedTransformer(transformer);
			setSelectedAsset(transformer);
		},
		[]
	);

	const handleCompareSubstationChange = useCallback(
		(substation: NGEDSubstationAsset | undefined) => {
			setSelectedSubstationForCompare(substation);
			setSelectedCompareFeeder(undefined);
			setCompareFeeders(undefined);
		},
		[]
	);

	const handleCompareFeederChange = useCallback(
		(feeder: FeederAsset | undefined) => {
			setSelectedCompareFeeder(feeder);
		},
		[]
	);

	const handleAssetChange = useCallback(
		(
			selectedAsset: TransformerAsset | FeederAsset | GroupedBusbarAsset
		) => {
			setSelectedAsset(selectedAsset);
		},
		[]
	);

	const handleDatePeriodSelectionChange = useCallback(
		(
			startDate: Date | undefined,
			endDate: Date | undefined,
			timePeriod: TimePeriod | undefined
		) => {
			setSelectedTimePeriod(timePeriod || DEFAULT_PERIOD);
			setSelectedStartDate(startDate || DEFAULT_START_DATE);
			setSelectedEndDate(endDate || DEFAULT_END_DATE);
		},
		[]
	);

	useEffect(() => {
		if (compareSubstationHierarchy) {
			setCompareFeeders(
				// NGED substations Will have only one transformer.
				compareSubstationHierarchy.transformers[0].feeders
			);
		}
	}, [compareSubstationHierarchy]);

	const isBelowLongTimeRangeThreshold = useMemo(() => {
		return (
			DateTime.fromJSDate(selectedEndDate).diff(
				DateTime.fromJSDate(selectedStartDate)
			).milliseconds /
				1000 <
			LONG_TIME_RANGE_THRESHOLD
		);
	}, [selectedEndDate, selectedStartDate]);

	const gridWidth =
		chartView === ChartViewType.grid ? GRID_CHART_WIDTH : LIST_CHART_WIDTH;

	return (
		<>
			<Stack height="100%" divider={<Divider />}>
				<Stack
					direction={{ xs: "column", sm: "row" }}
					justifyContent="flex-start"
					spacing={{ xs: 0, sm: 4 }}
					flexWrap="wrap"
					marginLeft={2}
					marginRight={2}
					marginTop={2}
				>
					<TransformerDropdown
						transformers={substation.transformers}
						selectedTransformer={selectedTransformer}
						onTransformerChange={handleTransformerChange}
					/>

					{selectedTransformer && (
						<AssetSelectionChips
							transformer={selectedTransformer}
							selectedAssetId={selectedAsset?.id}
							selectedPhase={selectedPhase}
							onAssetChange={handleAssetChange}
							onPhaseChange={setSelectedPhase}
						/>
					)}

					<Box>
						<Box
							display="flex"
							alignItems="center"
							marginBottom={2}
						>
							<DatePeriodSelector
								selectedTimePeriod={selectedTimePeriod}
								onDatePeriodSelectionChange={
									handleDatePeriodSelectionChange
								}
								selectedStartDate={selectedStartDate}
								selectedEndDate={selectedEndDate}
							/>
						</Box>
					</Box>
					{substationsWithSamePrimaryNumber != null &&
						substationsWithSamePrimaryNumber.length > 0 &&
						isBelowLongTimeRangeThreshold && (
							<Box>
								<Box marginBottom={2}>
									<SubstationFeederDropdown
										substations={
											substationsWithSamePrimaryNumber
										}
										selectedSubstation={
											selectedSubstationForCompare
										}
										onSubstationChange={
											handleCompareSubstationChange
										}
										showClear
										showFeederDrodown={
											selectedAsset &&
											selectedAsset.assetTypeKey ===
												AssetTypes.LvWay
										}
										feeders={compareFeeders}
										onFeederChange={
											handleCompareFeederChange
										}
										selectedFeeder={selectedCompareFeeder}
									/>
								</Box>
							</Box>
						)}

					<Box display="flex" marginBottom={2}>
						<ChartOptionsMenu
							chartView={chartView}
							toggleChartView={(chartView: ChartViewType) =>
								toggleChartView(chartView)
							}
							showStatutoryLimit={showStatutoryLimit}
							setAlwaysShowStatutoryLimit={() => {
								setAlwaysShowStatutoryLimit(
									!showStatutoryLimit
								);
							}}
						/>
					</Box>

					<Box style={{ marginLeft: "auto" }}>
						<Box marginBottom={2}>
							<div>
								<LatestMeasurement
									substationNumber={substation.serialNo}
								/>
							</div>
						</Box>
					</Box>
				</Stack>

				<DataPointTypeFilter
					dataPointTypes={ALL_MEASUREMENT_DATA_POINT_TYPES}
					assetType={selectedAsset?.assetTypeKey}
					onSelectedDataPointTypesChange={setSelectedDataPointTypes}
					defaultSelectedValues={DEFAULT_SELECTED_DATAPOINTTYPES}
					carbonIntensitySelected={carbonIntensityChipSelected}
					handleCarbonIntensityDataPointTypeSelectionChange={() =>
						setCarbonIntensityChipSelected(
							!carbonIntensityChipSelected
						)
					}
					measurementsData={measurementsData.data}
				/>

				{selectedDataPointTypes.length === 0 &&
				!carbonIntensityChipSelected ? (
					<Alert severity="info" variant="outlined" sx={{ m: 2 }}>
						{t("dataPointTypes.selectDataPointType", {
							val: t("measurements.measurements"),
						})}
					</Alert>
				) : (
					<>
						{measurementsData.isError && (
							<MessageCard
								title={t(
									"commonErrorMessages.unableToLoadError",
									{
										ns: "translation",
										val: t("measurements.measurements"),
									}
								)}
								primaryText={
									measurementsData.error?.status === 403
										? t(
												"commonErrorMessages.dataForbiddenErrorShort",
												{
													ns: "translation",
													val: t("common.data"),
												}
										  )
										: t(
												"commonErrorMessages.dataFetchError",
												{
													ns: "translation",
													val: t(
														"measurements.measurements"
													),
												}
										  )
								}
								severity={
									measurementsData.error?.status === 403
										? "warning"
										: "error"
								}
								display="flex"
								justifyContent="center"
								margin={2}
							/>
						)}
						{(measurementsData.isLoading ||
							(carbonIntensityChipSelected &&
								carbonIntensityLoading)) && (
							<Skeleton variant="rectangular" height="100%" />
						)}
						<Box sx={{ flexGrow: 1 }}>
							<Grid container spacing={2}>
								{!measurementsData.isLoading &&
									measurementsData.data &&
									chartView &&
									highchartsSeriesData
										?.reverse()
										.map((dataPointData, index) => {
											return (
												<Grid
													key={index}
													item
													sm={gridWidth}
													md={gridWidth}
												>
													<LineChart
														startDate={
															selectedStartDate
														}
														endDate={
															selectedEndDate
														}
														series={
															dataPointData.series
														}
														yAxisSettings={
															dataPointData
																.series[0]
																?.custom
																?.yAxisProperties
																.yAxisSettings
														}
														xAxisType="datetime"
														noDataMessage={`${t(
															"common.chartNoData",
															{
																val: t(
																	`dataPointTypes.${dataPointData.dataPointTypeId}`
																),
															}
														)}`}
														chartTitle={
															dataPointData
																.series[0]
																?.custom
																?.chartTitle
														}
														enableLegend
														enablePngExporting={
															dataPointData
																.series[0] !=
																null &&
															isBelowLongTimeRangeThreshold
														}
														exportChartTitle={`${substation.serialNo}-${substation.name}-${selectedAsset?.name}-${dataPointData.series[0]?.custom?.chartTitle}`}
														view={chartView}
													/>
												</Grid>
											);
										})}

								{chartView && carbonIntensityChipSelected && (
									<Grid item sm={gridWidth} md={gridWidth}>
										<LineChart
											startDate={selectedStartDate}
											endDate={selectedEndDate}
											series={carbonIntensitySeriesData}
											yAxisSettings={
												carbonIntensityYAxisSettings
											}
											xAxisType="datetime"
											noDataMessage={`${t(
												"common.chartNoData",
												{
													val: t(
														`carbonIntensity.carbonIntensity`
													),
												}
											)}`}
											chartTitle={`${t(
												`carbonIntensity.regionalCarbonIntensity`,
												{
													value: substation.dno,
												}
											)}`}
											enableLegend
											enablePngExporting={
												isBelowLongTimeRangeThreshold
											}
											exportChartTitle={`${t(
												`carbonIntensity.regionalCarbonIntensity`,
												{
													value: substation.dno,
												}
											)}`}
											view={chartView}
										/>
									</Grid>
								)}
							</Grid>
						</Box>
					</>
				)}
			</Stack>
		</>
	);
}
