import React from "react";
import config from "../../../configuration";
import { ApiHandlerPureComponent } from "../../utils";
import { UserConfirmationRequest } from "../../Shared/AccountConfirmation/UserConfirmationRequest";
import { PasswordResetRequest } from "../../Shared/PasswordReset/PasswordResetRequest";

interface Props {
	onAuthError?: () => void;
}

export default class AccountAPIs extends React.PureComponent<Props> {
	private apiResponseHandler = new ApiHandlerPureComponent({ onAuthError: this.props.onAuthError });
	private static readonly BASE_URL: string = `${config.apiGateway.NGED_AUTH_API}/api/Account/`;

	confirmUserAccount = (userConfirmationRequest: UserConfirmationRequest): Promise<void> => {
		const apiAddress = `${AccountAPIs.BASE_URL}confirmUserEmail`;
		return this.apiResponseHandler.post(apiAddress, JSON.stringify(userConfirmationRequest), true);
	};

    resetPassword = (passwordResetRequest: PasswordResetRequest): Promise<void> => {
		const apiAddress = `${AccountAPIs.BASE_URL}resetPassword`;
		return this.apiResponseHandler.post(apiAddress, JSON.stringify(passwordResetRequest), true);
	};
}