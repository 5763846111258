import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import { usePage } from "../../routes";
import { SubstationsOverviewPage } from "../SubstationOverview/SubstationOverviewPage";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";

import { SubstationTab } from "./SubstationDetails/SubstationTab";
import TabPanel, { TabPanelProps } from "../Shared/TabPanel";
import AppBreadcrumbs from "../Shared/Breadcrumbs";
import MessageCard from "../Shared/Messages/MessageCard";
import MessageToast from "../Shared/Messages/MessageToast";
import { Measurements } from "../Measurements/Measurements";
import useExtendedSubstationHierarchyReportBySubstation from "../data/Assets/hooks/useExtendedSubstationHierarchyReportBySubstation";
import { PrimarySubstationTab } from "./SubstationDetails/PrimarySubstationTab";

enum SubstationTabs {
	"Substation" = 0,
	"Measurements" = 1,
	"AssociatedPrimary" = 2,
}

export function SubstationPage() {
	const { t } = useTranslation(["translation", "translation"]);
	const substationsOverviewPagePath = usePage(
		SubstationsOverviewPage.name
	)?.path;
	const { serialNumber } = useParams<{ serialNumber: string }>();

	const [activeTab, setActiveTab] = useState(SubstationTabs.Substation);

	const substationHierarchy =
		useExtendedSubstationHierarchyReportBySubstation(serialNumber);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setActiveTab(newValue);
	};

	if (substationHierarchy.isLoading) {
		return (
			<Box height="100%">
				<Stack direction="column" spacing={2} padding={2} height="100%">
					<Skeleton variant="rectangular" height="3em" />
					<Skeleton variant="rectangular" height="3em" />

					<Stack direction="row" height="20em" spacing={2}>
						<Skeleton
							variant="rectangular"
							width="50%"
							height="100%"
						/>
						<Skeleton
							variant="rectangular"
							width="50%"
							height="100%"
						/>
					</Stack>

					<Skeleton variant="rectangular" sx={{ flex: 1 }} />
				</Stack>
			</Box>
		);
	} else if (substationHierarchy.isError) {
		return (
			<MessageCard
				title={t("commonErrorMessages.notFoundError", {
					ns: "translation",
					val: t("substation.substation"),
				})}
				primaryText={t("commonErrorMessages.dataFetchError", {
					ns: "translation",
					val: t("substation.substation"),
				})}
				secondaryText={
					<Trans
						i18nKey="commonErrorMessages.returnToPage"
						ns="translation"
						values={{
							val: t("substation.substationsOverviewPage"),
						}}
						components={{
							lnk: (
								<Link
									to={substationsOverviewPagePath || ""}
								></Link>
							),
						}}
					/>
				}
				severity="error"
				display="flex"
				justifyContent="center"
				margin={2}
			/>
		);
	} else if (substationHierarchy.data)
		return (
			<>
				<Box
					display="flex"
					flexDirection="column"
					flex={1}
					height="100%"
				>
					<AppBreadcrumbs
						customPageTitle={substationHierarchy.data.name}
					/>
					<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
						<Tabs
							value={activeTab}
							onChange={handleChange}
							sx={{ mx: 2 }}
						>
							<Tab label={t("substation.substation")} />
							<Tab label={t("measurements.measurements")} />
							<Tab label={t("substation.associatedPrimary")} />
						</Tabs>
					</Box>
					<PageTabPanel
						value={activeTab}
						index={SubstationTabs.Substation}
					>
						<SubstationTab substation={substationHierarchy.data} />
					</PageTabPanel>
					<PageTabPanel
						value={activeTab}
						index={SubstationTabs.Measurements}
					>
						<Measurements substation={substationHierarchy.data} />
					</PageTabPanel>
					<PageTabPanel
						value={activeTab}
						index={SubstationTabs.AssociatedPrimary}
					>
						<PrimarySubstationTab
							substation={substationHierarchy.data}
						/>
					</PageTabPanel>
				</Box>
			</>
		);
	else {
		return (
			<MessageToast
				text={t("commonErrorMessages.genericError", {
					ns: "translation",
				})}
				severity="error"
				sx={{ m: 2 }}
			/>
		);
	}
}

function PageTabPanel(props: TabPanelProps) {
	const { children, ...rest } = props;

	return (
		<TabPanel {...rest}>
			<Box display="flex" flexDirection="column" height="100%" flex="1">
				<Paper variant="secondary" sx={{ height: "100%" }}>
					{children}
				</Paper>
			</Box>
		</TabPanel>
	);
}
