import { Chip, ChipProps, Tooltip } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

interface FilterChipProps {
	isSelected: boolean;
	label: string;
	onClick: () => void;
	disabledTooltipText?: React.ReactNode;
	message?: string;
}

export default function FilterChip(props: FilterChipProps & ChipProps) {
	const {
		isSelected,
		label,
		onClick,
		disabledTooltipText,
		message,
		...rest
	} = props;
	return (
		<Tooltip
			title={disabledTooltipText || props.message}
			disableHoverListener={
				!props.disabled && disabledTooltipText !== undefined
			}
			arrow
			enterTouchDelay={0}
		>
			<div>
				<Chip
					variant={isSelected ? "filled" : "outlined"}
					label={label}
					clickable
					color="primary"
					onClick={onClick}
					icon={isSelected ? <DoneIcon /> : undefined}
					{...rest}
				/>
			</div>
		</Tooltip>
	);
}
