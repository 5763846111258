import React from "react";
import { AppRoutes } from "./routes";
import { BrowserRouter } from "react-router-dom";
import { MainThemeProvider } from "./components/contexts/MainThemeContext";
import { I18nextProvider } from "react-i18next";
import ReactGa4 from "react-ga4";
import config from "./configuration";

import "./themes/LeafletTheme.scss";
import "./themes/PrimeReactTheme.scss";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./themes/HighChartTheme.scss";
import i18n from "./i18n";
import "./App.css";
import { AuthContextProvider } from "./components/contexts/AuthContext";

const App: React.FC = () => {
	ReactGa4.initialize(config.TRACKING_GA_ID);

	return (
		<MainThemeProvider>
			<I18nextProvider i18n={i18n}>
				<BrowserRouter>
				<AuthContextProvider>
					<AppRoutes />
				</AuthContextProvider>
				</BrowserRouter>
			</I18nextProvider>
		</MainThemeProvider>
	);
};

export default App;
