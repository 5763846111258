import React from "react";
import { Link } from "react-router-dom";
import { SxProps, Theme } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Tooltip } from "@mui/material";

interface Props {
	primary?: React.ReactNode;
	icon?: React.ReactNode;
	to: string;
	title?: string;
	withTooltip?: boolean;
	sx: SxProps<Theme> | undefined;
}

export function ListItemLink(props: Props) {
	const { icon, primary, to, sx, title, withTooltip } = props;

	const CustomLink = React.useMemo(
		() =>
			React.forwardRef((linkProps, ref: React.Ref<HTMLAnchorElement>) => (
				<Link ref={ref} to={to} {...linkProps} />
			)),
		[to]
	);

	return withTooltip && title ? (
		<Tooltip title={title} placement="right" enterTouchDelay={0}>
			<li>
				<ListItem button component={CustomLink} sx={sx}>
					<ListItemIcon>{icon}</ListItemIcon>
					<ListItemText primary={primary} />
				</ListItem>
			</li>
		</Tooltip>
	) : (
		<li>
			<ListItem button component={CustomLink} sx={sx}>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText primary={primary} />
			</ListItem>
		</li>
	);
}
