import { useMemo } from "react";
import { ExtendedSubstationHierarchy } from "../../data/Substation/interfaces/ExtendedSubstationHierarchy";
import {
	TransformerAsset,
	FeederAsset,
	BusbarAsset,
} from "../../data/Substation/interfaces/SubstationHierarchyReport";
import { DataPointType } from "../../enums/DatapointTypes";
import { Phase } from "../../enums/Phase";
import { isTransformerPhase } from "../../utils/IsTransformerPhase";
import { notNullish } from "../../utils/notNullish";
import { selectTransformerPhases } from "../../utils/SelectTransformerPhases";

// Data point types which aren't associated with phases
const EXCLUDE_PHASE_DATA_POINT_TYPES: DataPointType[] = [
	DataPointType.TransformerTemperature,
];

export const useCategorisedDataAssetIds = (
	dataPointTypes: DataPointType[],
	substation: ExtendedSubstationHierarchy,
	transformer?: TransformerAsset,
	feeder?: FeederAsset,
	phase?: Phase,
	compareSubstationHierarchy?: ExtendedSubstationHierarchy,
	compareFeeder?: FeederAsset
) =>
	useMemo<number[]>(() => {
		if (dataPointTypes.length === 0) return [];

		// No need to include phase assets for certain data point types
		const includePhaseAssets = dataPointTypes.every((dpt) =>
			EXCLUDE_PHASE_DATA_POINT_TYPES.includes(dpt)
		);
		const phaseAssetIds = includePhaseAssets
			? []
			: selectPhaseAssetIds(substation, transformer, feeder, phase);

		//For busbar asset selection we only need the busbar Id
		const busbarAssetId =
			feeder == null && transformer
				? selectBusbarAssetIds(transformer, phase)
				: [];

		// Include transformers if transformer temperature is required
		const transformerAssetIds = dataPointTypes.includes(
			DataPointType.TransformerTemperature
		)
			? selectTransformerAssetIds(substation, transformer)
			: [];

		// Include assetIds of the compare substation if selected
		const compareSubstationIds =
			compareSubstationHierarchy && !feeder
				? selectCompareSubstationAssetIds(
						compareSubstationHierarchy,
						dataPointTypes,
						includePhaseAssets,
						transformer !== undefined,
						compareFeeder,
						phase
				  )
				: [];
		const compareFeederPhaseIds =
			feeder && compareFeeder && compareSubstationHierarchy
				? selectPhaseAssetIds(
						compareSubstationHierarchy,
						undefined,
						compareFeeder,
						phase
				  )
				: [];
		return phaseAssetIds.concat(
			transformerAssetIds,
			busbarAssetId,
			compareSubstationIds,
			compareFeederPhaseIds
		);
	}, [
		dataPointTypes,
		phase,
		substation,
		transformer,
		feeder,
		compareSubstationHierarchy,
		compareFeeder,
	]);

const selectPhaseAssetIds = (
	substation: ExtendedSubstationHierarchy,
	transformer?: TransformerAsset,
	feeder?: FeederAsset,
	phase?: Phase
): number[] => {
	// Matching phases on selected feeder
	if (phase && feeder) {
		return (
			feeder.phases
				.filter((phaseAsset) => phaseAsset.phase === phase)

				.map((phase) => phase.id)
				.filter(notNullish) ?? []
		);
	}

	// All phases on selected feeder
	if (feeder) {
		return feeder.phases.map((phase) => phase.id).filter(notNullish);
	}

	// Matching phases on selected transformer
	if (phase && transformer) {
		return (
			selectTransformerPhases(transformer)
				.filter((phaseAsset) => isTransformerPhase(phaseAsset, phase))
				.map((phase) => phase.id)
				.filter(notNullish) ?? []
		);
	}

	// Matching phases on all transformers
	if (phase) {
		return selectTransformerPhases(substation.transformers)
			.filter((phaseAsset) => isTransformerPhase(phaseAsset, phase))
			.map((phase) => phase.id)
			.filter(notNullish);
	}

	//All phases on selected transformer
	if (transformer) {
		return selectTransformerPhases(transformer)
			.map((phase) => phase.id)
			.filter(notNullish);
	}

	// All phases on all transformers
	return selectTransformerPhases(substation.transformers)
		.map((phase) => phase.id)
		.filter(notNullish);
};

const selectTransformerAssetIds = (
	substation: ExtendedSubstationHierarchy,
	transformer?: TransformerAsset
): number[] =>
	transformer
		? transformer.id
			? [transformer.id]
			: []
		: substation.transformers.map((t) => t.id).filter(notNullish);

const selectBusbarAssetIds = (
	transformer: TransformerAsset,
	phase?: Phase
): number[] => {
	const busbars: BusbarAsset[] = Array.isArray(transformer)
		? transformer
				.flatMap((transformer) => transformer.busbars)
				.filter(notNullish)
		: transformer.busbars ?? [];

	if (phase && transformer)
		return busbars
			.filter((busbar) => busbar.name?.includes(phase))
			.map((t) => t.id)
			.filter(notNullish);

	return busbars.map((t) => t.id).filter(notNullish);
};

const selectCompareSubstationAssetIds = (
	substation: ExtendedSubstationHierarchy,
	dataPointTypes: DataPointType[],
	includePhaseIds: boolean,
	isTransformerSelected: boolean,
	compareFeeder?: FeederAsset,
	phase?: Phase
) => {
	const transformer = substation.transformers[0];

	// No need to include phase assets for certain data point types
	const phaseAssetIds = !includePhaseIds
		? []
		: selectPhaseAssetIds(substation, transformer, compareFeeder, phase);

	//For busbar asset selection we only need the busbar Id
	const busbarAssetId =
		compareFeeder == null && transformer
			? selectBusbarAssetIds(transformer, phase)
			: [];

	// Include transformers if transformer temperature is required
	const transformerAssetIds = dataPointTypes.includes(
		DataPointType.TransformerTemperature
	)
		? selectTransformerAssetIds(substation, transformer)
		: [];

	return phaseAssetIds.concat(transformerAssetIds, busbarAssetId);
};
