import { BoxProps } from "@mui/material";
import { Box } from "@mui/material";

export interface TabPanelProps extends BoxProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

export default function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<Box
			flex={1}
			hidden={value !== index}
			{...other}
		>
			{value === index && (
				<>
					{children}
				</>
			)}
		</Box>
	);
}
