import { styled } from "@mui/material/styles";
import MuiCard, { CardProps } from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useTranslation } from "react-i18next";
import { Button, CardActionArea, Divider, Stack } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import SingleRowLabelValue, {
	SingleRowLabelValueProps,
} from "../Shared/LabelValueFormatters/SingleRowLabelValue";
import { usePage } from "../../routes";
import { SubstationPage } from "./SubstationPage";
import { useNavigate } from "react-router-dom";
import { AssetTypes } from "../enums/AssetTypes";
import {
	getSubstationColor,
	useSubstationTypeText,
} from "../Shared/SubstationTypeHelper";
import { NGEDSubstationAsset } from "../data/Substation/interfaces/NGEDSubstationAsset";

interface Props {
	loading?: boolean;
	substation: NGEDSubstationAsset;
	onSubstationSelectForCompare: (substation: NGEDSubstationAsset) => void;
}

const labelValueRowSizingProps: Partial<SingleRowLabelValueProps> = {
	labelGridSize: {
		md: 5,
		sm: 12,
	},
};

const CARD_WIDTH = 430;
const CARD_HEIGHT = 142;

const Card = styled(MuiCard)<CardProps & { assettype: AssetTypes }>(
	({ theme, assettype }) => {
		const cardColor = getSubstationColor(assettype, theme);

		return {
			width: CARD_WIDTH,
			minHeight: CARD_HEIGHT,
			borderLeftWidth: theme.spacing(1),
			borderLeftStyle: "solid",
			borderLeftColor: cardColor,
			padding: theme.spacing(1),
			cursor: "pointer",
		};
	}
);

export function SubstationCard(props: Props) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const substationTypeText = useSubstationTypeText(
		props.substation.assetTypeKey
	);
	const substationPagePath = usePage(SubstationPage.name)?.path;

	function handleCardClick() {
		substationPagePath &&
			props.substation &&
			navigate(
				substationPagePath?.replace(
					":serialNumber",
					props.substation.serialNumber.toString()
				),
				{ state: { substationName: props.substation.name } }
			);
	}

	const getSubstationAttributeInfo = (
		label: string,
		value: string | number | undefined
	) => {
		value = value ?? "";
		return (
			<SingleRowLabelValue
				label={label}
				value={value}
				{...labelValueRowSizingProps}
			></SingleRowLabelValue>
		);
	};

	const getHVSubstationFields = (substation: NGEDSubstationAsset) => {
		return (
			<>
				{getSubstationAttributeInfo(
					t("substation.numberOfLVSubstations"),
					"2"
				)}
				{getSubstationAttributeInfo(
					t("substation.noOfTransformers"),
					"placeholder"
				)}
				{getSubstationAttributeInfo(
					t("substation.transformerRating"),
					"placeholder"
				)}
			</>
		);
	};

	const getLVSubstationFields = (substation: NGEDSubstationAsset) => {
		return (
			<>
				{getSubstationAttributeInfo(
					t("substation.primarySubstationName"),
					substation.primaryName
				)}
				{getSubstationAttributeInfo(
					t("substation.primarySubstationNumber"),
					substation.primaryNumber
				)}
			</>
		);
	};

	if (props.loading)
		return (
			<Skeleton
				variant="rectangular"
				width={CARD_WIDTH}
				height={CARD_HEIGHT}
			/>
		);

	return (
		<Card variant="outlined" assettype={props.substation.assetTypeKey}>
			<Box
				display="flex"
				flexDirection="column"
				sx={{ width: 1, height: "100%", p: 0 }}
			>
				<CardActionArea onClick={handleCardClick}>
					<Typography variant="h6" maxWidth={`${CARD_WIDTH}px`}>
						<FontAwesomeIcon icon={faBolt} />{" "}
						{props.substation.name}
					</Typography>
					<Divider />
					{getSubstationAttributeInfo(
						t("substation.substationType"),
						substationTypeText
					)}
					{getSubstationAttributeInfo(
						t("substation.substationNumber"),
						props.substation.serialNumber
					)}
					{props.substation.assetTypeKey === AssetTypes.Substation &&
						getLVSubstationFields(props.substation)}
					{props.substation.assetTypeKey ===
						AssetTypes.HvSubstation &&
						getHVSubstationFields(props.substation)}
					{getSubstationAttributeInfo(
						t("commonAsset.latitude"),
						props.substation.gpsCoords.latitude
					)}
					{getSubstationAttributeInfo(
						t("commonAsset.longitude"),
						props.substation.gpsCoords.longitude
					)}
					{getSubstationAttributeInfo(
						t("commonAsset.customerCount"),
						props.substation.customerCount
					)}
				</CardActionArea>

				<Stack alignItems="flex-start">
					<Button
						onClick={() => {
							props.onSubstationSelectForCompare(
								props.substation
							);
						}}
						variant="text"
						style={{ paddingLeft: 0 }}
					>
						{t("substation.compareWithSimilarSubstations")}
					</Button>
				</Stack>

				<Typography align="center" variant="caption">
					<FontAwesomeIcon icon={faInfoCircle} />{" "}
					{t("substation.substationInfoCard")}
				</Typography>
			</Box>
		</Card>
	);
}
