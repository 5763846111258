import { useContext } from "react";
import { useQuery } from "react-query";
import { ApiContext } from "../../../contexts/ApiContext";
import { QueryCallbacks } from "../../../utils/interfaces/ReactQuery";
import { GeoFence } from "../interfaces/GeoFence";

export function useGeoFenceDataById(
	id: number | undefined,
	callBacks?: QueryCallbacks<GeoFence | null | undefined>
) {
	const apiContext = useContext(ApiContext);
	return useQuery(
		[`geoFenceDataById`, id],
		async () => {
			if (id)
				return await apiContext.geoFenceApis.getGeoFenceDataById(id);
			return undefined;
		},
		{
			onSuccess: callBacks?.onSuccess,
			onError: callBacks?.onError,
			refetchOnWindowFocus: false,
		}
	);
}
