import { useTranslation } from "react-i18next";
import { Paper, Typography } from "@mui/material";

import LabelValueRow from "./LabelValueFormatters/LabelValueRow";
import { CarbonIntensityAndGenerationMixData } from "../data/CarbonIntensity/interfaces/CarbonIntensity";
import { GenerationMixPie } from "./GenerationMixPie";
import { upperFirst } from "lodash";

interface GenerationStatisticsDisplayProps {
	licenceAreaCarbonIntensityAndGenerationMix?: CarbonIntensityAndGenerationMixData;
	selectedLicenceAreaName?: string;
}
export function GenerationStatisticsDisplay(
	props: GenerationStatisticsDisplayProps
) {
	const { t } = useTranslation();

	const {
		licenceAreaCarbonIntensityAndGenerationMix,
		selectedLicenceAreaName,
	} = props;

	const carbonIntensityForecast =
		licenceAreaCarbonIntensityAndGenerationMix?.data[0].data[0].intensity
			.forecast;
	const carbonIntensityIndex =
		licenceAreaCarbonIntensityAndGenerationMix?.data[0].data[0].intensity
			.index;
	const generationMix =
		licenceAreaCarbonIntensityAndGenerationMix?.data[0].data[0].generationmix.filter(
			(r) => r.perc
		);

	return (
		<>
			<Paper
				sx={{
					p: 1,
					mb: 1,
					height: "100%",
					width: "100%",
				}}
			>
				{generationMix && generationMix.length > 0 && (
					<GenerationMixPie
						licenceAreaName={selectedLicenceAreaName}
						generationMixData={generationMix}
					/>
				)}
			</Paper>

			<Paper
				sx={{
					p: 1,
					mb: 1,
					height: "50%",
					width: "80%",
				}}
			>
				{carbonIntensityIndex && (
					<LabelValueRow
						label={t("carbonIntensity.carbonIntensityIndex")}
						value={
							<Typography variant="subtitle1">
								{upperFirst(carbonIntensityIndex)}
							</Typography>
						}
						labelGridSize={{ xs: 9 }}
						message={t(
							"carbonIntensity.carbonIntensityIndexDefinition"
						)}
					/>
				)}
				{carbonIntensityForecast && (
					<LabelValueRow
						label={t("carbonIntensity.carbonIntensityForecast")}
						value={
							<Typography variant="subtitle1">
								{`${carbonIntensityForecast} ${t(
									"units.gCO2/kWh"
								)}`}
							</Typography>
						}
						labelGridSize={{ xs: 9 }}
						message={t(
							"carbonIntensity.carbonIntensityForecastDefinition"
						)}
					/>
				)}
			</Paper>
		</>
	);
}
