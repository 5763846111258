import { useContext } from "react";
import { useQuery } from "react-query";
import { ApiContext } from "../../../contexts/ApiContext";
import { getDayRange } from "../../../utils/DateConversions";
import { QueryCallbacks } from "../../../utils/interfaces/ReactQuery";
import {
	HistoricCarbonIntensityAndGenerationMixData,
	CarbonIntensityAPIRegionId,
} from "../interfaces/CarbonIntensity";

export default function useHistoricCarbonIntensityAndGenerationMixAtLicenceAreaLevel(
	startDate: Date,
	endDate: Date,
	carbonIntensityAPIRegionId?: CarbonIntensityAPIRegionId,
	callbacks?: QueryCallbacks<
		HistoricCarbonIntensityAndGenerationMixData | undefined
	>
) {
	const apiContext = useContext(ApiContext);

	return useQuery<
		HistoricCarbonIntensityAndGenerationMixData | undefined,
		Response
	>(
		[
			"historicCarbonIntensityAndGenerationMixAtLicenceAreaLevel",
			startDate,
			endDate,
			carbonIntensityAPIRegionId,
		],
		async () => {
			if (carbonIntensityAPIRegionId == null) return undefined;

			// NGED's carbon intensity API cannot handle 60+ days of data
			if (getDayRange(endDate, startDate) > 59) {
				return await apiContext.carbonIntensityApis.getHistoricalCarbonIntensityAndGenerationMixAtLicenceAreaLevel(
					new Date(Date.now() - 59 * 24 * 60 * 60 * 1000),
					endDate,
					carbonIntensityAPIRegionId
				);
			}
			return await apiContext.carbonIntensityApis.getHistoricalCarbonIntensityAndGenerationMixAtLicenceAreaLevel(
				startDate,
				endDate,
				carbonIntensityAPIRegionId
			);
		},
		{
			onSuccess: callbacks?.onSuccess,
			onError: callbacks?.onError,
		}
	);
}
