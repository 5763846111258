import { ColumnProps } from "primereact/column";

export interface colProps extends ColumnProps {
	aggregateValue?: boolean;
	unit?: string;
}

export const GENERAL_SUBSTATION_DETAILS_COLS: colProps[] = [
	{ field: "dno", header: "substation.licenceArea" },
	{
		field: "customerCount",
		header: "commonAsset.customerCount",
	},
	{
		field: "primaryName",
		header: "substation.primarySubstation",
	},
	{
		field: "bspName",
		header: "substation.bulkSupplyPoint",
	},
	{
		field: "gspName",
		header: "substation.gridSupplyPoint",
	},
	{ field: "description", header: "substation.type" },
];

export const LCT_COLS: colProps[] = [
	{
		field: "countOfLcts",
		header: "substation.countOfLcts",
		aggregateValue: true,
	},
	{ field: "energyStorageCount", header: "substation.energyStorage" },
	{
		field: "heatPumpCount",
		header: "substation.heatPumps",
	},
	{
		field: "evChargerCount",
		header: "substation.evChargers",
	},
];

export const GENERATION_COLS: colProps[] = [
	{
		field: "generatorCount",
		header: "substation.generationCounts",
		aggregateValue: true,
	},
	{
		field: "solarGeneratorCount",
		header: "substation.solar",
	},
	{
		field: "windGeneratorCount",
		header: "substation.wind",
	},
	{
		field: "bioFuelGeneratorCount",
		header: "substation.bioFuels",
	},
	{
		field: "storageGeneratorCount",
		header: "substation.storage",
	},
	{ field: "waterGeneratorCount", header: "substation.hydro" },
	{ field: "wasteGeneratorCount", header: "substation.waste" },
	{ field: "fossilFuelGeneratorCount", header: "substation.fossilFuel" },
	{ field: "otherGenerationCount", header: "substation.other" },
];

export const GENERATION_AND_STORAGE: colProps[] = [
	{
		field: "totalLCTCapacity",
		header: "substation.totalLctCapacity",
		unit: "units.short.kw",
	},
	{
		field: "totalGenerationCapacity",
		header: "substation.totalGenerationCapacity",
		unit: "units.short.kw",
	},
	{
		field: "otherGenerationCapacity",
		header: "substation.otherGenerationCapacity",
		unit: "units.short.kw",
	},
	{
		field: "storageCapacity",
		header: "substation.storageCapacity",
		unit: "units.short.kw",
	},
];

export const NETWORK_CAPACITY_COLS: colProps[] = [
	{
		field: "dayMaxDemand",
		header: "substation.dayMaxDemand",
		unit: "units.short.kw",
	},
	{
		field: "nightMaxDemand",
		header: "substation.nightMaxDemand",
		unit: "units.short.kw",
	},
	{
		field: "substationRating",
		header: "substation.substationRating",
		unit: "units.short.kw",
	},
];

export const SUBSTATION_STATIC_DATA_AGGREGATES = {
	generatorCount: {
		unit: false,
		fields: [
			"solarGeneratorCount",
			"wind",
			"bioFuelGeneratorCount",
			"storageGeneratorCount",
			"waterGeneratorCount",
			"fossilFuelGeneratorCount",
			"other",
		],
	},
	countOfLcts: {
		unit: false,
		fields: ["energyStorageCount", "heatPumpCount", "evChargerCount"],
	},
};
