import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SeriesOptionsType, YAxisOptions } from "highcharts";
import { DateTime } from "luxon";
import { round } from "lodash";

import {
	CarbonIntensityAndGenerationMix,
	HistoricCarbonIntensityAndGenerationMixData,
} from "../../data/CarbonIntensity/interfaces/CarbonIntensity";

interface Params {
	carbonIntensityAPIResponse:
		| HistoricCarbonIntensityAndGenerationMixData
		| undefined;
}

export function useCarbonIntensityDataAsHighchartsSeries(params: Params) {
	const { t } = useTranslation();
	const { carbonIntensityAPIResponse: carbonIntensityResponse } = params;

	const highchartsSeriesData = useMemo(() => {
		if (carbonIntensityResponse == null) return [];
		let seriesLineStyle = { dashStyle: "Solid" };

		const seriesData = formatNumericData(carbonIntensityResponse.data);

		return [
			{
				data: seriesData,
				color: "#000000",
				name: t("carbonIntensity.forecastCarbonIntensityLabel"),
				type: "spline",
				yAxis: 0,
				...seriesLineStyle,
			},
		] as Partial<SeriesOptionsType>[];
	}, [carbonIntensityResponse, t]);

	const yAxisSettings: YAxisOptions = {
		title: {
			text: `${t("units.gCO2/kWh")}`,
		},
		offset: 0,
		opposite: false,
		tickPosition: "inside",
	};

	return { highchartsSeriesData, yAxisSettings };
}

function formatNumericData(
	carbonIntensityData?: CarbonIntensityAndGenerationMix
) {
	if (carbonIntensityData == null) return undefined;

	return carbonIntensityData.data.map((d) => {
		const unixTimestamp = DateTime.fromISO(d.from).toMillis();
		return [unixTimestamp, round(Number(d.intensity.forecast), 2)];
	});
}
