import React, { useState, useEffect } from "react";
import { searchByProperties } from "../utils/SearchByProperties";
import { useSubstationList } from "../data/Substation/hooks/useSubstationList";
import { NGEDSubstationAsset } from "../data/Substation/interfaces/NGEDSubstationAsset";
import { County, LicenceArea } from "../data/GeoFence/interfaces/Region";
import { PostcodeLookupResult } from "../data/Postcodes/PostcodeLookupResult";

export interface ISubstationFiltersContext {
	substationList: NGEDSubstationAsset[] | undefined | null;
	isLoading: boolean;
	isError: boolean;
	substationFilters: SubstationFilters;
	setSearchTerm: (value: string) => void;
	setSelectedPostcode: (postcode: PostcodeLookupResult | undefined) => void;
	setLicenceArea: (selectedLicenceArea: LicenceArea | undefined) => void;
	setCounty: (selectedCounty: County | undefined) => void;
	setShowPrimarySubstationBoundaries: (value: boolean | undefined) => void;
}

export interface SubstationFilters {
	searchTerm?: string;
	postcode?: PostcodeLookupResult | undefined;
	licenceArea?: LicenceArea | undefined;
	county?: County | undefined;
	showPrimarySubstationBoundaries?: boolean;
}

export const SubstationFiltersContext = React.createContext(
	{} as ISubstationFiltersContext
);

export function SubstationFiltersContextProvider(props: any) {
	const {
		data: fullSubstationList,
		isLoading,
		isError,
	} = useSubstationList();
	const [searchTerm, setSearchTerm] = useState("");
	const [filteredSubstationList, setFilteredSubstationList] =
		useState(fullSubstationList);

	const [licenceArea, setLicenceArea] = useState<LicenceArea | undefined>(
		undefined
	);

	const [county, setCounty] = useState<County | undefined>(undefined);

	const handleLicenceAreaChange = (
		selectedLicenceArea: LicenceArea | undefined
	) => {
		setLicenceArea(selectedLicenceArea);
	};

	const handleCountyChange = (selectedCounty: County | undefined) => {
		setCounty(selectedCounty);
	};

	useEffect(() => {
		let filteredSubstations =
			fullSubstationList && searchTerm
				? searchByProperties(
						fullSubstationList,
						(substation: NGEDSubstationAsset) => [
							substation.name,
							substation.serialNumber,
						],
						searchTerm
				  )
				: fullSubstationList;

		if (licenceArea)
			filteredSubstations = filteredSubstations?.filter(
				(substation) =>
					substation.dno != null &&
					substation.dno.toLowerCase() ===
						licenceArea.name.toLowerCase()
			);

		setFilteredSubstationList(filteredSubstations);
	}, [fullSubstationList, licenceArea, searchTerm]);

	const [postcode, setPostcodeLookup] = useState<
		PostcodeLookupResult | undefined
	>();

	const [
		showPrimarySubstationBoundaries,
		setShowPrimarySubstationBoundaries,
	] = useState<boolean | undefined>(false);

	const handlePostcodeChange = (value: PostcodeLookupResult | undefined) => {
		setPostcodeLookup(value);
	};

	const handleShowPrimarySubstationBoundariesChange = (
		value: boolean | undefined
	) => {
		setShowPrimarySubstationBoundaries(value);
	};

	return (
		<SubstationFiltersContext.Provider
			value={{
				substationList: filteredSubstationList,
				isLoading,
				isError,
				substationFilters: {
					searchTerm,
					postcode,
					licenceArea,
					county,
					showPrimarySubstationBoundaries,
				} as SubstationFilters,
				setLicenceArea: handleLicenceAreaChange,
				setSearchTerm,
				setSelectedPostcode: handlePostcodeChange,
				setCounty: handleCountyChange,
				setShowPrimarySubstationBoundaries:
					handleShowPrimarySubstationBoundariesChange,
			}}
		>
			{props.children}
		</SubstationFiltersContext.Provider>
	);
}
