import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "./Layout";
import {
	FontAwesomeIcon,
	FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { faBolt, faInfo, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { SubstationsOverviewPage } from "./components/SubstationOverview/SubstationOverviewPage";
import { SubstationPage } from "./components/Substation/SubstationPage";
import ReactGa4 from "react-ga4";
import AboutPage from "./components/AboutPage";
import GoogleAnalytics from "./components/Shared/GoogleAnalytics/GoogleAnalytics";
import EmailConfirmation from "./components/Shared/AccountConfirmation/EmailConfirmation";
import PasswordReset from "./components/Shared/PasswordReset/PasswordReset";
import CallToActionPage from "./components/CallToActionPage";

export interface Props {
	children?: React.ReactNode;
	location?: any;
}

export interface Page {
	title: string;
	path: string;
	component: any;
	routes?: any;
	icon: (iconProps?: Partial<FontAwesomeIconProps>) => JSX.Element;
	isMenuItem?: boolean;
	description?: string;
	breadcrumb?: string | null;
}

export const usePages = (): Page[] => {
	const { t } = useTranslation();

	const [pages, setPages] = useState<Page[]>([]);

	useEffect(() => {
		const ClickHandler = () => {
			ReactGa4.event({
				category: "home icon",
				action: "click",
				label: "test",
			});
		};

		const pagesArr: Page[] = [
			{
				title: t("common.mapView") || "",
				path: "/map",
				component: SubstationsOverviewPage,
				icon: (iconProps?: Partial<FontAwesomeIconProps>) => (
					<FontAwesomeIcon
						icon={faGlobe}
						{...iconProps}
						onClick={ClickHandler}
					/>
				),
				isMenuItem: true,
				breadcrumb: t("common.mapView"),
			},
			{
				title: t("common.about") || "",
				path: "/about",
				component: AboutPage,
				icon: (iconProps?: Partial<FontAwesomeIconProps>) => (
					<FontAwesomeIcon
						icon={faInfo}
						{...iconProps}
						onClick={ClickHandler}
					/>
				),
				isMenuItem: true,
				breadcrumb: t("common.about"),
			},
			{
				title: t("common.about") || "",
				path: "/",
				component: CallToActionPage,
				icon: (iconProps?: Partial<FontAwesomeIconProps>) => (
					<FontAwesomeIcon
						icon={faInfo}
						{...iconProps}
						onClick={ClickHandler}
					/>
				),
				isMenuItem: false,
				breadcrumb: t("common.about"),
			},
			{
				title: t("login.passwordResetHeader"),
				path: "/resetPassword/:email/:token",
				component: PasswordReset,
				icon: (iconProps?: Partial<FontAwesomeIconProps>) => (
					<FontAwesomeIcon
						icon={faInfo}
						{...iconProps}
						onClick={ClickHandler}
					/>
				),
				isMenuItem: false,
				breadcrumb: t("login.passwordResetHeader"),
			},
			{
				title: t("login.userConfirmation"),
				path: "/confirmEmail/:email/:token",
				component: EmailConfirmation,
				icon: (iconProps?: Partial<FontAwesomeIconProps>) => (
					<FontAwesomeIcon
						icon={faInfo}
						{...iconProps}
						onClick={ClickHandler}
					/>
				),
				isMenuItem: false,
				breadcrumb: t("login.userConfirmation"),
			},
			{
				title: t("substation.substation") || "",
				path: "/substation/:serialNumber",
				component: SubstationPage,
				icon: (iconProps?: Partial<FontAwesomeIconProps>) => (
					<FontAwesomeIcon icon={faBolt} {...iconProps} />
				),
				breadcrumb: t("substation.substation"),
			},
		];

		setPages(pagesArr);
	}, [t]);

	return pages;
};

export function PageIcon(props: {
	pagePath: string | undefined;
	iconProps?: Partial<FontAwesomeIconProps>;
}) {
	const pages = usePages();
	const page = pages.find((page) => page.path === props.pagePath);
	return page?.icon(props.iconProps) || null;
}

export const usePageIcon = (componentName: string, iconProps?: any) => {
	const pages = usePages();
	const page = pages.find((page) => page.component.name === componentName);
	return page?.icon(iconProps);
};

export const usePage = (componentName: string) => {
	const pages = usePages();
	const page = pages.find((page) => page.component.name === componentName);
	return page;
};

export function DocumentTitle(props: { title: string }) {
	const { t } = useTranslation();
	document.title = `${props.title} | ${t("projectTitle")}`;
	return null;
}

function PrivateRoute({ component: Component, title, ...rest }: Page) {
	return (
		<React.Fragment>
			<DocumentTitle title={title} />
			<Component {...rest} />
		</React.Fragment>
	);
}

export const NonPrivateRoutes = ["/confirmemail/", "/resetpassword/"];

export function AppRoutes() {
	const pages = usePages();

	return (
		<Layout routes={pages}>
			<GoogleAnalytics/>
			<Routes>
				{pages.map((page: any) => (
					<Route
						path={page.path}
						key={page.path}
						element={
							<PrivateRoute
								exact
								component={page.component as any}
								{...page}
							/>
						}
					/>
				))}
			</Routes>
		</Layout>
	);
}

export default Routes;
