import { useCallback, useContext, useEffect, useState } from "react";
import { SubstationFiltersContext } from "./SubstationListContext";
import { useTranslation } from "react-i18next";
import {
	Stack,
	Divider,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box,
	Button,
	Switch,
	Tooltip,
	useTheme,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PostcodeLookupFilter from "../Shared/Inputs/PostcodeLookupFilter";
import LabelText from "../Shared/LabelValueFormatters/LabelText";
import { useLocalStorage } from "../utils/useLocalStorage";
import LicenceAreaDropdown from "../../LicenceArea/LicenceAreaDropdown";
import CountyDropdown from "../County/CountyDropdown";

import usePostcodeLookup from "../data/Postcodes/hooks/usePostcodeLookup";
import { County, LicenceArea } from "../data/GeoFence/interfaces/Region";
import { NgedDistributionSubstationDataUrl } from "../../configuration";
import { ExportMonitoredSubstations } from "./ExportMonitoredSubstations";

function LabelFilterStack(props: { label: string; children: React.ReactNode }) {
	return (
		<Stack direction="column" alignItems="flex-start" spacing={0.5}>
			<LabelText text={props.label} />
			{props.children}
		</Stack>
	);
}

export default function SubstationFiltersUI() {
	const { t } = useTranslation();
	const {
		substationList,
		substationFilters: {
			licenceArea,
			county,
			postcode,
			showPrimarySubstationBoundaries,
		},
		setSelectedPostcode,
		setLicenceArea,
		setCounty,
		setShowPrimarySubstationBoundaries,
	} = useContext(SubstationFiltersContext);

	const theme = useTheme();

	const [accordionExpanded, setAccordionExpanded] = useLocalStorage<boolean>(
		"OVERVIEW_FILTERS_EXPANDED",
		true
	);
	const [postcodeInputValue, setPostcodeInputValue] = useState<string>(
		postcode?.postcode || ""
	);
	const [postcodeError, setPostcodeError] = useState<
		{ title?: string; detail: string } | undefined
	>();

	const {
		data: postcodeLookupData,
		isFetching: postcodeFetching,
		isError: postcodeLookupError,
	} = usePostcodeLookup(postcodeInputValue);

	function onAccordionChange(
		event: React.SyntheticEvent<Element, Event>,
		expanded: boolean
	) {
		setAccordionExpanded(expanded);
	}

	const handleSelectLicenceArea = useCallback(
		(licenceArea: LicenceArea | undefined) => {
			setLicenceArea(licenceArea);

			if (licenceArea && licenceArea?.id !== postcode?.licenceArea?.id) {
				setPostcodeInputValue("");
				setSelectedPostcode(undefined);
			}

			if (
				licenceArea &&
				county &&
				!county.parentRegions.some(
					(region) => region.id === licenceArea.id
				)
			) {
				setCounty(undefined);
			}
		},
		[
			setLicenceArea,
			postcode,
			setPostcodeInputValue,
			setSelectedPostcode,
			county,
			setCounty,
		]
	);

	const handleSelectCounty = useCallback(
		(county: County | undefined) => {
			setCounty(county);

			if (county && county?.id !== postcode?.county?.id) {
				setPostcodeInputValue("");
				setSelectedPostcode(undefined);
			}
		},
		[setCounty, postcode, setPostcodeInputValue, setSelectedPostcode]
	);

	const handlePostcodeSelected = useCallback((value) => {
		setPostcodeInputValue(value);
	}, []);

	const handleShowPrimarySubstationBoundariesChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setShowPrimarySubstationBoundaries(event.target.checked);
		},
		[]
	);

	const handleClosePostcodeError = () => setPostcodeError(undefined);

	useEffect(() => {
		if (postcodeLookupError) {
			setPostcodeError({
				detail: t("substation.postcodeLookupErrorMessage"),
			});
		}
	}, [postcodeLookupError, t]);

	useEffect(() => {
		if (!postcodeLookupData) {
			setSelectedPostcode(undefined);
			setPostcodeError(undefined);
			return;
		}

		if (!Boolean(postcodeLookupData.licenceArea)) {
			setSelectedPostcode(undefined);
			setPostcodeError({
				title: t("substation.postcodeLookupNotLicencedAreaTitle"),
				detail: t("substation.postcodeLookupNotLicencedAreaMessage"),
			});
			return;
		}

		if (licenceArea?.id !== postcodeLookupData.licenceArea?.id) {
			setLicenceArea(undefined);
		}

		if (county?.id !== postcodeLookupData.county?.id) {
			setCounty(undefined);
		}

		setSelectedPostcode(postcodeLookupData);
	}, [
		postcodeLookupData,
		county?.id,
		licenceArea?.id,
		setCounty,
		setLicenceArea,
		setSelectedPostcode,
		t,
	]);

	const [licenceAreaError, setLicenceAreaError] = useState<
		string | undefined
	>(undefined);

	const handleCloseLicenceAreaError = () => {
		setLicenceAreaError(undefined);
	};

	useEffect(() => {
		if (licenceArea && (substationList?.length ?? 0) === 0) {
			setLicenceAreaError(
				t("substation.licenceAreaNoSubstationsErrorMessage")
			);
		} else setLicenceAreaError(undefined);
	}, [licenceArea, substationList, setLicenceAreaError, t]);

	return (
		<>
			<Divider />
			<Accordion
				variant="secondary"
				disableGutters
				expanded={accordionExpanded}
				onChange={onAccordionChange}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					sx={{ flexDirection: "row-reverse" }}
				>
					<Typography variant="body1" sx={{ ml: 2 }}>
						{t("substation.filters")}
					</Typography>
					{substationList && (
						<Tooltip
							title={t("common.numberOfSubstations", {
								value: substationList.length,
							})}
							placement="right-start"
							arrow
							enterTouchDelay={0}
						>
							<Typography
								mt={-0.1}
								ml={1}
								align="right"
								variant="subtitle1"
							>
								{" "}
								<i
									className="pi pi-info-circle"
									style={{
										color: theme.palette.primary.main,
									}}
								></i>{" "}
							</Typography>
						</Tooltip>
					)}
				</AccordionSummary>
				<Divider />
				<AccordionDetails>
					<Stack
						direction={{ xs: "column", lg: "row" }}
						spacing={3}
						alignItems="flex-start"
						justifyContent={{
							xs: "flex-start",
							lg: "space-between",
						}}
					>
						<Stack
							direction={{ xs: "column", xl: "row" }}
							spacing={3}
							alignItems="flex-start"
							justifyContent={{
								xs: "flex-start",
								md: "space-between",
							}}
						>
							<Stack
								direction={{ xs: "column", sm: "row" }}
								spacing={3}
							>
								{substationList && (
									<>
										<LabelFilterStack
											label={t(
												"substation.postcodeLookupLabel"
											)}
										>
											<PostcodeLookupFilter
												initalValue={postcodeInputValue}
												onSelected={
													handlePostcodeSelected
												}
												error={postcodeError}
												onCloseError={
													handleClosePostcodeError
												}
												fetching={
													postcodeFetching || false
												}
											/>
										</LabelFilterStack>
										<LabelFilterStack
											label={t("substation.licenceArea")}
										>
											<LicenceAreaDropdown
												selectedLicenceArea={
													licenceArea
												}
												onLicenceAreaChange={
													handleSelectLicenceArea
												}
												populateAttributes={true}
												error={licenceAreaError}
												onCloseError={
													handleCloseLicenceAreaError
												}
											/>
										</LabelFilterStack>
										<LabelFilterStack
											label={t("substation.county")}
										>
											<CountyDropdown
												selectedCounty={county}
												onCountyChange={
													handleSelectCounty
												}
												populateAttributes={true}
												selectedParentRegionId={
													licenceArea?.id
												}
											/>
										</LabelFilterStack>
										<LabelFilterStack
											label={t(
												"substation.primaryBoundaryDisplay"
											)}
										>
											<Switch
												checked={
													showPrimarySubstationBoundaries
												}
												onChange={
													handleShowPrimarySubstationBoundariesChange
												}
											/>
										</LabelFilterStack>
									</>
								)}
							</Stack>
						</Stack>
						<Box sx={{ marginLeft: "auto" }}>
							<Stack>
								<Button
									href={NgedDistributionSubstationDataUrl}
									target="_blank"
									style={{ justifyContent: "flex-end" }}
								>
									{t("common.goToDataSource")}
								</Button>

								{substationList && (
									<ExportMonitoredSubstations
										substationData={substationList}
									/>
								)}
							</Stack>
						</Box>
					</Stack>
				</AccordionDetails>
			</Accordion>
			<Divider />
		</>
	);
}
