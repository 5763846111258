import React, { useState, useEffect, useCallback, PropsWithChildren } from "react";
import { getTokenPayload, refreshToken, refreshTokenExists, removeToken, tokenIsValid } from "../Shared/TokenHandling/TokenManagement";
import { useLocation, useNavigate } from "react-router";
import { onPageRequiringAuthentication } from "../utils/PageIdentification";

export interface IAuthContext {
	userIsAuthenticated: boolean;
	setUserIsAuthenticated: (isAuthenticated: boolean) => void;
	logout: () => void;
	getUserEmail: () => string;
}

export const AuthContext = React.createContext({} as IAuthContext);

const CheckTokenInterval = 60000;

export function AuthContextProvider(props: PropsWithChildren<unknown>) {
	const navigate = useNavigate();
	const location = useLocation();
	const [userIsAuthenticated, setUserIsAuthenticated] = useState(false);

	const checkTokenIsValid = useCallback(async (): Promise<boolean> => {
		if (tokenIsValid()) {
			return Promise.resolve(true);
		}
		if (!refreshTokenExists()) {
			return Promise.resolve(false);
		}
		return refreshToken()
			.then(() => tokenIsValid())
			.catch(() => false)
	}, []);

	useEffect(() => {
		function updateTokenValidity() {
			checkTokenIsValid().then(tokenIsValid => {
				if (tokenIsValid) setUserIsAuthenticated(true);
				else setUserIsAuthenticated(false);
			});
		}

		if (!tokenIsValid()){
			setUserIsAuthenticated(false);
			if(onPageRequiringAuthentication(location.pathname)){
			  navigate("/");
			}
		} 
		else setUserIsAuthenticated(true);

		const interval = setInterval(() => updateTokenValidity(), CheckTokenInterval);

		return () => {
			if (interval !== null) clearInterval(interval);
		};
	}, [checkTokenIsValid])

	function getUserEmail(): string {
		const tokenPayload = getTokenPayload();
		return tokenPayload.Email;
	}

	function logout() {
		removeToken();
		localStorage.clear();
		sessionStorage.clear();
		setUserIsAuthenticated(false);
	}

	return (
		<AuthContext.Provider
			value={{
				userIsAuthenticated: userIsAuthenticated,
				setUserIsAuthenticated: (isAuthenticated: boolean) => setUserIsAuthenticated(isAuthenticated),
				logout: logout,
				getUserEmail: getUserEmail,
			}}>
			{props.children}
		</AuthContext.Provider>
	)
}