import { useMemo } from "react";
import { GeoFence } from "../data/GeoFence/interfaces/GeoFence";
import { NGEDSubstationAsset } from "../data/Substation/interfaces/NGEDSubstationAsset";
import { DEFAULT_BOUNDS, DEFAULT_MAP_LEVELS, SavedMapLevels } from "../SubstationOverview/Map/MapContainer";
import { getGeoFenceBounds, getSubstationsGeoJSON } from "./helpers/MapHelper";

export interface MapLevelsAndBounds {
    mapLevels?: SavedMapLevels;
    bounds?: number[][];
}

export function useMapLevelsAndBounds(substations: NGEDSubstationAsset[], isLoading?: boolean, geofence?: GeoFence): MapLevelsAndBounds | undefined {
    return useMemo(() => {
        if (!isLoading) {

            if (geofence && geofence.geoJson) {
                return {
                    bounds: getGeoFenceBounds(JSON.parse(geofence.geoJson)),
                    mapLevels: undefined,
                }
            } else {

            const substationsGeoJSON = getSubstationsGeoJSON(substations);

            if (substationsGeoJSON && substationsGeoJSON.geometry && substationsGeoJSON.geometry.type === "MultiPoint") {
                const bounds = getGeoFenceBounds(substationsGeoJSON);
                return {
                    bounds,
                    mapLevels: undefined,
                }
            }

            if (substationsGeoJSON && substationsGeoJSON.geometry && substationsGeoJSON.geometry.type === "Point") {
                return {
                    mapLevels: { zoom: 14, center: [substationsGeoJSON.geometry.coordinates[1], substationsGeoJSON.geometry.coordinates[0]] },
                    bounds: undefined,
                }
            }

            if (!substationsGeoJSON || !substations || substations.length === 0) {
                return {
                    bounds: DEFAULT_BOUNDS,
                    mapLevels: DEFAULT_MAP_LEVELS,
                }
            }

            return {
                bounds: undefined,
                mapLevels: undefined,
            }
        }
        }
    }, [ isLoading, substations])
}