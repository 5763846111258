import Typography, { TypographyProps } from "@mui/material/Typography";
import { Stack, Tooltip, useTheme } from "@mui/material";

interface LabelTextProps extends TypographyProps {
	text: string;
	message?: string;
}

export default function LabelText(props: LabelTextProps) {
	const { text, ...rest } = props;
	const theme = useTheme();

	return (
		<Stack direction="row">
			<Typography
				mt={-0.5}
				mr={0.5}
				variant="subtitle1"
				color="textSecondary"
				{...rest}
			>
				{text}:{" "}
			</Typography>
			{props.message && (
				<Tooltip
					title={props.message}
					placement="right-start"
					arrow
					enterTouchDelay={0}
				>
					<Typography
						mt={-0.5}
						mr={0.5}
						align="right"
						variant="subtitle1"
					>
						{" "}
						<i
							className="pi pi-info-circle"
							style={{ color: theme.palette.primary.main }}
						></i>{" "}
					</Typography>
				</Tooltip>
			)}
		</Stack>
	);
}
