import React from "react";
import config from "../../../configuration";
import { ApiHandlerPureComponent } from "../../utils";
import { ExtendedSubstationHierarchy } from "../Substation/interfaces/ExtendedSubstationHierarchy";
import { SubstationHierarchyReport } from "../Substation/interfaces/SubstationHierarchyReport";

interface Props {
	onAuthError?: () => void;
}

export default class AssetAPIs extends React.PureComponent<Props> {
	private apiResponseHandler = new ApiHandlerPureComponent({ onAuthError: this.props.onAuthError });

	private static readonly BASE_URL: string = `${config.apiGateway.NGED_META_API}/api/lvsubstations/`;

	getSubstationHierarchyReport = (
		substationId: number | string,
		returnPhases: boolean,
		returnBusbars: boolean,
		returnWindings: boolean
	): Promise<SubstationHierarchyReport> => {
		const apiAddress = `${AssetAPIs.BASE_URL}substationHierarchyReport/substationId/${substationId}?returnPhases=${returnPhases}&returnBusbars=${returnBusbars}&returnWindings=${returnWindings}`;
		return this.apiResponseHandler.get(apiAddress);
	};

	getExtendedSubstationHierarchyReportByCustomer = (
		customerId: number | string
	): Promise<ExtendedSubstationHierarchy[]> => {
		const apiAddress = `${AssetAPIs.BASE_URL}extendedSubstationHierarchyReport/immediateChildrenOnly/customer/${customerId}?onlyConnected=true`;
		return this.apiResponseHandler.get(apiAddress);
	};

	getExtendedSubstationHierarchyReportBySubstation = (
		serialNumber: number | string
	): Promise<ExtendedSubstationHierarchy> => {
		const apiAddress = `${AssetAPIs.BASE_URL}SubstationHierarchyReport/serialNumber/${serialNumber}`;
		return this.apiResponseHandler.get(apiAddress);
	};
}
