import { ExtendedSubstationHierarchy } from "../data/Substation/interfaces/ExtendedSubstationHierarchy";
import {
	BusbarAsset,
	PhaseAsset,
	FeederAsset,
} from "../data/Substation/interfaces/SubstationHierarchyReport";
import { notNullish } from "./notNullish";

export const getAssetIdsOfSubstation = (
	substation?: ExtendedSubstationHierarchy
) => {
	if (!substation) return [];
	const transformers = substation.transformers;
	const transformerIds = transformers.map((t) => t.id).filter(notNullish);

	const busbars: BusbarAsset[] = Array.isArray(transformers)
		? transformers
				.flatMap((transformer) => transformer.busbars)
				.filter(notNullish)
		: [];

	const busbarsIds = busbars.map((t) => t.id).filter(notNullish);

	const feeders: FeederAsset[] = Array.isArray(transformers)
		? transformers
				.flatMap((transformer) => transformer.feeders)
				.filter(notNullish)
		: [];

	const feederPhases: PhaseAsset[] = Array.isArray(feeders)
		? feeders.flatMap((feeder) => feeder.phases).filter(notNullish)
		: [];
	const phaseIds = feederPhases.map((t) => t.id).filter(notNullish);
	return transformerIds.concat(busbarsIds, phaseIds);
};
