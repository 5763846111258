import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { Link } from 'react-router-dom'
import { usePages, PageIcon } from '../../routes';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import { Box, Stack, Typography, Divider } from '@mui/material';

interface BreadcrumbsProps {
    customPageTitle?: string;
};

const AppBreadcrumbs = (props: BreadcrumbsProps) => {

	const pages = usePages();
	const breadcrumbs = useBreadcrumbs(pages, { disableDefaults: true });

    if (breadcrumbs.length > 0) {
		return (
            <Box sx={{ padding: 2 }}>
			    <Breadcrumbs>
                    <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
				    {breadcrumbs.map((breadcrumb, index) => {
						    const isLast = index === breadcrumbs.length - 1;
							const breadcrumbText = isLast && props.customPageTitle ? props.customPageTitle : breadcrumb.breadcrumb;
							const breadcrumbPath = breadcrumb.match?.route?.breadcrumb?.toString();
							const displayAsLink = breadcrumb.match?.pathname && !isLast;

                            if (!displayAsLink || isLast) {
                                return <Stack key={`breadcrumbContainer-${breadcrumbPath}`} spacing={1} direction="row" alignItems="center">

                                    <PageIcon pagePath={breadcrumbPath} />
                                    <Typography color="inherit">{breadcrumbText}</Typography>
                                </Stack>
                            }
                            else {
                                return <MuiLink key={`breadcrumbContainer-${breadcrumbPath}`} color="inherit" underline="hover" to={breadcrumb.match.pathname} component={Link} >
                                            <Stack spacing={1} direction="row" alignItems="center">
                                            <PageIcon pagePath={breadcrumbPath} />

                                            {breadcrumbText}
                                        </Stack>
                                    </MuiLink>
                            }
                        })}
                    </Stack>
			    </Breadcrumbs>
            </Box>
		);
	}
	else return null;
};

export default AppBreadcrumbs;