import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Stack,
	FormGroup,
	FormHelperText,
	Box,
	Button,
	AlertColor
} from '@mui/material';

import config from '../../../configuration';
import LoginStyledDialog from './LoginStyledDialog';
import { LoginStyledTextInput } from './LoginStyledTextInput';
import MessageToast from '../Messages/MessageToast';
import ProgressButton from '../Inputs/ProgressButton';

interface ForgotPasswordDialogProps {
	show: boolean;
	onBackClick: () => void;
}

export default function ForgotPasswordDialog(props: ForgotPasswordDialogProps) {
	const { t } = useTranslation();
	const [email, setEmail] = useState("");
	
	const [messageText, setMessageText] = useState("");
	const [messageSeverity, setMessageSeverity] = useState<AlertColor | undefined>();

	const [isLoading, setIsLoading] = useState(false);

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
		setMessageText("");
		setMessageSeverity(undefined);
	}

	function handleSubmit(e: any) {
        if (email === "") {
			setMessageText(t('login.pleaseEnterEmail'));
            setMessageSeverity("error");
            return;
		}
		const authEndpoint = config.apiGateway.NGED_AUTH_API + "/api/Account/initiateForgotPassword?email=" + email;
		fetch(authEndpoint,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded"
				}
			})
			.then(resp => handleResponse(resp))
			.catch(_ => {
				setMessageText(t('login.forgotPasswordError'));
				setMessageSeverity("error");
				setIsLoading(false);
			});
	}

	function handleResponse(response: Response) {
		if (response.ok) {
			setEmail("");
			setMessageText(t('login.emailSent'));
			setMessageSeverity("success");
			setIsLoading(false);
		} else {
			setMessageText(t('login.forgotPasswordError'));
			setMessageSeverity("error");
		}
	}

    return (
        <LoginStyledDialog
            open={props.show}
            titleText={t('login.forgotPassword')}
            content={<Box mt={2}>

            <FormGroup sx={{ w: "100%" }}>
                <FormHelperText sx={{ mt: 2 }}>
                    {t('login.emailAddress')}
                </FormHelperText>
                <LoginStyledTextInput required={true} value={email} onChange={handleEmailChange} placeholder={t('login.email')} />
            </FormGroup>

            <Box display="flex" justifyContent="center" pt={2}>
                {messageText && messageSeverity && <MessageToast text={messageText} severity={messageSeverity} sx={{width: "400px"}} />}
            </Box>
        </Box>}

            actions={<Stack direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                width="100%"
            >
                <Button
                    disabled={isLoading}
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={props.onBackClick}
                >
                    {t('common.back')}
                </Button>
                <ProgressButton onClick={handleSubmit} loading={isLoading}>
                    {t('login.sendPasswordResetEmail')}
                </ProgressButton>
            </Stack>
            }
        />
    )
}
