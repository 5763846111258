
import { BusbarAsset, PhaseAsset, TransformerAsset } from '../data/Substation/interfaces/SubstationHierarchyReport';
import { Phase } from '../enums/Phase';
import { isTransformerPhase } from './IsTransformerPhase';
import { notNullish } from './notNullish';

/**
 * Find all transformer phase assets attached to one or more transformers.
 * @param transformers Single transformer or transformers to select phase assets from
 * @param phaseFilter Optional phase type filter
 */
export const selectTransformerPhases = (transformers: TransformerAsset | TransformerAsset[], phaseFilter?: Phase): PhaseAsset[] => {
  const busbars: BusbarAsset[] = Array.isArray(transformers)
    ? transformers.flatMap(transformer => transformer.busbars).filter(notNullish)
    : transformers.busbars ?? [];

  return busbars
    .flatMap(busbar => busbar?.phases)
    .filter(notNullish)
    .filter(phaseAsset => !phaseFilter || isTransformerPhase(phaseAsset, phaseFilter));
};
