export function aggregateCounts(counts: (number | undefined)[]){  
    let sum = 0;
    if (counts){
        counts.forEach(count => {  
            if (count){
                sum += count;
            }
        })
        return sum;
    }
    return 0
}