import Alert, { AlertProps } from '@mui/material/Alert';

interface Props extends AlertProps {
	text: string;
}

export default function MessageToast(props: Props) {
	const { text, ...rest } = props;

	return (
		<Alert variant="filled" {...rest}>{props.text}</Alert>
	);
}
