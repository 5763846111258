import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { NgedDistributionSubstationDataUrl } from "../../../configuration";
import { ExtendedSubstationHierarchy } from "../../data/Substation/interfaces/ExtendedSubstationHierarchy";

import SubstationDetailCard from "./SubstationDetailCard";

interface Props {
	substation: ExtendedSubstationHierarchy | undefined;
}

export function SubstationTab(props: Props) {
	const { t } = useTranslation();
	if (props.substation)
		return (
			<Grid container spacing={3} padding={2}>
				<Grid item xs={12} sm={12} md={8}>
					<SubstationDetailCard substation={props.substation} />
				</Grid>
				<Grid item xs={12} md={4}>
					<Box display="flex" justifyContent="flex-end">
						<Button
							href={NgedDistributionSubstationDataUrl}
							target="_blank"
						>
							{t("common.goToDataSource")}
						</Button>
					</Box>
				</Grid>
			</Grid>
		);
	else return null;
}
