import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

interface NoChartDataDisplayMessageProps {
	message?: string;
}
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	...theme.typography.subtitle1,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

export function NoChartDataDisplayMessage(
	props: NoChartDataDisplayMessageProps
) {
	return (
		<Box sx={{ width: "100%" }}>
			<Stack spacing={2}>
				<Item>{props.message}</Item>
			</Stack>
		</Box>
	);
}
