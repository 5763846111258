import logo from "./images/logo.svg";

const APIHostDEV = ".sub360test.co.uk";
const APIHostProd = ".substation360.co.uk";
const TRACKING_ID_Dev = "G-FE72BWFTLS";
const TRACKING_ID_Prod = "G-FM2LTYW6F4";

export const NgedLvmDataUrl =
	"https://connecteddata.nationalgrid.co.uk/dataset/lv-load-monitor-data";
export const NgedDistributionSubstationDataUrl =
	"https://connecteddata.nationalgrid.co.uk/dataset/distribution-substations";
export const NgedPrimarySubstationDataUrl =
	"https://connecteddata.nationalgrid.co.uk/dataset/primary-substation-location-easting-northings";

const NGEDCustomerId = "100025"; // Todo: Change this to statc NGED customer ID

export enum LicenceAreaRegionId {
	"WestMidlands" = 100000,
	"SouthWales" = 100001,
	"SouthWestEngland" = 100002,
	"EastMidlands" = 100003,
}

export enum LicenceAreaName {
	"WestMidlands" = "West Midlands",
	"SouthWales" = "South Wales",
	"SouthWest" = "South West",
	"EastMidlands" = "East Midlands",
}

const projectConfigs = {
	name: "NGED" /*rename this once we have the final Project name*/,
	logo: logo /*logo to be replaced*/,
};

const dev = {
	...projectConfigs,
	apiGateway: {
		NGED_META_API: `https://ngedmeta${APIHostDEV}`,
		CARBON_INTENSITY_API: `https://api.carbonintensity.org.uk`,
		NGED_AUTH_API: `https://ngedauth${APIHostDEV}`,
	},
	customerId: NGEDCustomerId,
	TRACKING_GA_ID: TRACKING_ID_Dev,
};

const prod = {
	...projectConfigs,
	apiGateway: {
		NGED_META_API: `https://meta.nged${APIHostProd}`,
		CARBON_INTENSITY_API: `https://api.carbonintensity.org.uk`,
		NGED_AUTH_API: `https://auth.nged${APIHostProd}`,
	},
	customerId: NGEDCustomerId,
	TRACKING_GA_ID: TRACKING_ID_Prod,
};

const loadEnvironmentConfig = () => {
	switch (process.env.REACT_APP_TARGET_ENV ?? process.env.NODE_ENV) {
		case "development":
			return dev;
		case "production":
			return prod;
		default:
			return dev;
	}
};
const config = loadEnvironmentConfig();

export default config;
