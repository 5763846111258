import { ExtendedSubstationHierarchy } from "../data/Substation/interfaces/ExtendedSubstationHierarchy";
import {
	BusbarAsset,
	FeederAsset,
	PhaseAsset,
} from "../data/Substation/interfaces/SubstationHierarchyReport";
import { notNullish } from "./notNullish";
import { selectTransformerPhases } from "./SelectTransformerPhases";

export function getPhaseByIdFromSubstationHierarchy(
	substation: ExtendedSubstationHierarchy,
	phaseId: number
) {
	const feeders: FeederAsset[] = Array.isArray(substation.transformers)
		? substation.transformers
				.flatMap((transformer) => transformer.feeders)
				.filter(notNullish)
		: [];
	const feederPhases: PhaseAsset[] = Array.isArray(feeders)
		? feeders.flatMap((feeder) => feeder.phases).filter(notNullish)
		: [];
	return feederPhases?.find((phase) => phaseId === phase.id);
}

// this is needed as the data for NGED is stored against the busbar rather than the busbar phases. We can return the first element as that will suffice to load the labels
export function getBusbarAssetPhaseByBusbarId(
	substation: ExtendedSubstationHierarchy,
	busbarId: number
) {
	const busbars: BusbarAsset[] = Array.isArray(substation.transformers)
		? substation.transformers
				.flatMap((transformer) => transformer.busbars)
				.filter(notNullish)
		: [];
	const busbarPhases = busbars?.find(
		(busbar) => busbar.id === busbarId
	)?.phases;
	return busbarPhases && busbarPhases.length > 0
		? busbarPhases[0]
		: undefined;
}
