import React from "react";
import Grid, { GridSize } from "@mui/material/Grid";

import LabelText from "./LabelText";
import ValueText from "./ValueText";
import { Tooltip, Typography, useTheme } from "@mui/material";

export interface LabelValueRowProps {
	label: string;
	value: React.ReactNode;
	message?: string;

	labelGridSize?: {
		xs?: GridSize;
		sm?: GridSize;
		md?: GridSize;
		lg?: GridSize;
		xl?: GridSize;
	};
}

export default function LabelValueRow(props: LabelValueRowProps) {
	const theme = useTheme();

	return (
		<Grid container alignItems="baseline">
			<Grid
				style={{ display: "flex" }}
				item
				{...props.labelGridSize}
				xs={props.labelGridSize?.xs || 9}
			>
				<LabelText mt={0} mr={0.5} text={props.label} />
				{props.message && (
					<Tooltip
						title={props.message}
						placement="right-start"
						arrow
						enterTouchDelay={0}
					>
						<Typography align="right" variant="subtitle1">
							{" "}
							<i
								className="pi pi-info-circle"
								style={{ color: theme.palette.primary.main }}
							></i>
						</Typography>
					</Tooltip>
				)}
			</Grid>
			<Grid item>
				{!props.value || (props.value && props.value === 0) ? (
					<ValueText text={0} />
				) : typeof props.value === "string" ||
				  typeof props.value === "number" ? (
					<ValueText text={props.value} />
				) : (
					props.value
				)}
			</Grid>
		</Grid>
	);
}
